

import Vue from "vue";

//import Home from "./views/Home.vue";

export default Vue.extend({
    name: "App",

    components: {
        //Home
    },

    data: () => ({
        drawer: false,
        items: [

            { title: "Ponto", to: "/days", icon: "mdi-fingerprint" },
            { title: "Mapa Assistencial", to: "/works", icon: "mdi-calendar-clock" },
            { title: "Cadastro de Pacientes", to: "/patients", icon: "mdi-account-alert" },
            { title: "Cadastro de Colaboradores", to: "/employees", icon: "mdi-account-multiple" },
            { title: "Cadastro de Convênios", to: "/insurances", icon: "mdi-security" },
            { title: "Cadastro de Doenças", to: "/diseases", icon: "mdi-lock-plus" },
            { title: "Internações", to: "/hospitalizations", icon: "mdi-hospital-marker" },
            { title: "Relatórios", to: "/reports", icon: "mdi-file-chart" },
            // { title: "Autorizações de Fornecimento", to: "/authorizations", icon: "mdi-file-sign" }
        ]
    }),

    methods: {
        open: function () {
            this.$data.drawer = !this.$data.drawer;
        }
    },

    computed: {
        hide: function () {
            return this.$route.path === "/login" || this.$route.path === "/register" || /^\/requests\/.*\/view$/.test(this.$route.path);
        },

        cssProps() {
            var themeColors = {}
            Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
                themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
            })
            return themeColors
        }
    },
    created: function () {
        //$root.user = parse(localStorage.getItem('user'));
    }
});
