<template>
  <svg width="30" :style="{ transform: `rotate(${rotation}deg)`, width: width + 'px', height: height + 'px'  }" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="07-caminhao-ok 1" clip-path="url(#clip0_849_228)">
          <g id="Camada_x0020_1">
              <path id="Vector"
                  d="M23.5715 14.4429C24.0858 14.3143 23.8286 14.1429 23.3572 13.7143L8.01431 0.51432C7.50003 0.085749 6.90009 -0.171394 5.82866 0.085749L0.814293 1.62865C-0.128564 1.88579 4.93601e-05 2.48575 4.93601e-05 3.30003V10.7143C4.93601e-05 11.1429 0.0857218 11.3144 0.385722 11.6144L4.15717 14.9144C4.67146 15.3001 4.97144 15.1286 5.52858 14.9144L6.60001 14.5286L14.1429 21.0001V23.0143C14.1429 23.4 14.3572 23.6572 14.7001 23.9144L21.1286 29.4001C21.7715 29.8287 22.4143 30.1286 23.3572 29.8715L28.7572 28.2429C29.3144 27.9429 29.2286 27.6 29.2286 26.8715V25.2429L28.7572 23.6572L27.2143 17.9572C27 17.2286 26.9144 16.7144 26.4858 16.3287L24.9001 14.9144C24.5572 14.7001 24.3858 14.5287 23.7001 14.8287L17.5286 16.7143C16.9286 16.9715 16.8858 17.1858 16.9286 18.043L17.0143 23.3572L16.2858 22.7572V16.5857L23.5715 14.4429Z"
                  :fill="color"/>
              <path id="Vector_2"
                  d="M19.9286 22.1144L18.4715 20.8714C18.1715 20.6572 18.1714 20.6572 18.1714 21C18.1714 21.2572 18.1714 21.6858 18.3429 22.1144C18.5143 22.4144 18.6428 22.7143 18.8142 22.9286L21.0429 25.9286C21.3429 26.3572 22.2857 27 23.3571 26.6572L28.2428 25.1144C28.5 24.9858 28.5857 24.9 28.6714 24.6429L27.5143 20.5286C27.3857 20.7429 27.2142 20.8715 26.9571 20.9143L21.9 22.4571C20.9572 22.7143 20.3571 22.4572 19.9286 22.1144Z"
                  fill="white" />
              <path id="Vector_3"
                  d="M22.7142 27.9429L23.0571 27.9001C23.1428 27.9001 23.1857 28.0287 23.1857 28.1144V29.3572C23.1857 29.4858 23.0999 29.5286 23.0571 29.5286H22.7142C22.6285 29.5286 22.5856 29.4001 22.5856 29.3143V28.1144C22.5428 27.9859 22.6285 27.9429 22.7142 27.9429Z"
                  fill="white" />
              <path id="Vector_4"
                  d="M28.8 26.1001L28.9715 26.0144C29.0144 26.0144 29.0572 26.143 29.0572 26.2287V27.4716C29.0572 27.6002 29.0144 27.643 28.9715 27.643L28.8 27.6859C28.7572 27.6859 28.7144 27.5573 28.7144 27.4716V26.2716C28.7144 26.1859 28.7572 26.143 28.8 26.1001Z"
                  fill="white" />
          </g>
      </g>
      <defs>
          <clipPath id="clip0_849_228">
              <rect width="29.2286" height="30" fill="white" />
          </clipPath>
      </defs>
  </svg>
</template>

<script>
export default {
name: 'TruckIcon',
props: {
  rotation: {
    type: Number,
    default: 0
  },
  color: {
    type: String,
    default: '#222D6C'
  },
  width: {
    type: Number,
    default: 30
  },
  height: {
    type: Number,
    default: 30
  }
}
}
</script>