<template>
    <v-row>
        <v-col>
            <v-data-table :headers="dataList.headers" :items="dataList.data" :server-items-length="dataList.count"
                @update:items-per-page="pageSizeChanged" @update:page="pageChanged" :disable-sort="true"
                :loading="dataList.loading" no-data-text="Nenhuma unidade foi encontrada" loading-text="Carregando dados..."
                class="app-table hover-table" :options.sync="dataList.options" :footer-props="dataList.footerOptions"
                show-select @toggle-select-all="selectAllToggle" @item-selected="select">

                <template v-slot:[`item.location`]="{ item }">
                    <span class="list-item-title">{{ item.registrationNumber | VMask($masks.cnpj) }}</span>
                    <br />
                    <span class="list-item-subtitle">{{ item.addressStreet }}</span>
                </template>

            </v-data-table>

        </v-col>
    </v-row>
</template>
  
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import status from "@/common/data/status.data";
import object from "@/common/util/object"


export default {
    name: "UnitSelection",

    props: {
        filter: {},
        selectedItems: []
    },

    data: () => ({

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },
        dataset: {
            selected: []
        },
        dataList: {
            filter: {
                type: "",
                client: "",
                status: 0
            },
            headers: [
                { text: "Unidade", value: "name" },
                { text: "Localização", value: "location" },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },

    }),

    created() {
        this.load();
    },

    methods: {

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.load();
        },

        clear() {
            this.dataset.selected = []
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.filter).filter(([_, v]) => v))

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/units', { params: { start: start, limit: limit, search: search, association: true } });


                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        select(props) {
            const verify = this.dataset.selected.findIndex((x) => x.id === props.item.id);

            if (!props.value && verify !== -1) this.dataset.selected.splice(verify, 1);

            if (props.value && verify === -1) this.dataset.selected.push(props.item)

            this.$emit("emit-result", this.dataset.selected);
        },

        selectAllToggle(props) {
            this.dataset.selected = !props.value ? [] : props.items

            this.$emit("emit-result", this.dataset.selected);
        }

    },
};
</script>
  
<style lang="scss" scoped>
.device-block {
    display: flex;
}

.device-card-label {
    font-size: 14px;
    margin-left: 12px;

    .line1 {
        font-weight: 500;
        font-size: 16px;
    }
}

.page-title {
    font-size: 24px;
    text-transform: capitalize;
}
</style>