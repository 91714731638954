<template>
    <v-card class="card-infobox" :width="350" color="white">
        <v-card-text>
            <div>
                <span class="title-label primary--text">02 - FP5T71Q</span>
                <br />
                <span class="desc-label">Placa</span>
            </div>
            <div class="mt-2">
                <v-chip class="px-6" color="gray" label text-color="primary">
                    <v-icon size="26" left>
                        mdi-map-marker-alert-outline
                    </v-icon>
                    <span class="position-chip-text">
                        Posição Imprecisa
                    </span>
                </v-chip>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.brand | enum(dataset.vehicleBrands, "id", "name")
                        }}</span>
                        <br />
                        <span class="desc-label">Marca</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.client.name }}</span>
                        <br />
                        <span class="desc-label">Cliente</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">LORA 001</span>
                        <br />
                        <span class="desc-label">Equipamento</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">2100 mV</span>
                        <br />
                        <span class="desc-label">Bateria</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">10/08/2023</span>
                        <br />
                        <span class="desc-label">Data</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">15:23:10</span>
                        <br />
                        <span class="desc-label">Hora (há 10 minutos)</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">R. Amazonas, 417
                            Santa Isabel, Viamão - RS</span>
                        <br />
                        <span class="desc-label">Endereço</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">-29.854873,
                            -50.9358308</span>
                        <br />
                        <span class="desc-label">Coordenadas</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">-106dB</span>
                        <br />
                        <span class="desc-label">RSSI</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">-2.5dB</span>
                        <br />
                        <span class="desc-label">SNR</span>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-btn class="action-button" color="primary" @click.native="unlock()">Histórico Aproximação</v-btn>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';

import vehicleBrands from "@/common/data/vehicle-brands.data";

Vue.use(VueLodash)

export default {
    name: "VehicleNearbyInfoBox",

    props: {
        vehicle: null,
        type: null
    },

    data: () => ({
        dataset: {
            unlockDialog: false,
            vehicleBrands: vehicleBrands,
        },
    }),

    created() {
    },

    methods: {
        unlock() {
            this.dataset.unlockDialog = true;
        }
    },
};
</script>

<style lang="scss">
.card-infobox {

    .title-label {
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        margin-left: 0px !important;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        /* 380% */
    }

    .action-button {
        width: 100%;
    }

    .position-chip-text {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

    .position-info-text {
        color: var(--v-important-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

}
</style>