<template>
    <v-dialog v-model="dialog.show" :max-width="500" @keydown.esc="cancel">
        <v-card>
            <v-card-title>
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Adicionar Arquivo</span>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnNew" icon type="button" color="mediumGray" small elevation="0"
                            @click="dialog.show = false">

                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field id="serial" label="Nome" v-model="dataset.data.name" hide-details
                            outlined></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-file-input accept=".txt, .png, .pdf, .doc, .docx, .jpg" messages="Doc, docx, pdf, jpg ou png até 2mb" label="Arquivo" v-model="dataset.data.file" truncate-length="15" prepend-icon="" outlined append-icon="mdi-tray-arrow-up"></v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <div class="button-group">
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
                    <v-btn color="secondary" @click.native="select">Adicionar</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
  
  
<script>

import _ from 'lodash';
import axios from 'axios';

export default {
    name: "FileSelection",

    data: () => ({

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },
        dataset: {
            data: {},
        },
    }),

    methods: {

        async load() {

            // this.dataList.loading = true;

            // var limit = this.dataList.options.itemsPerPage;
            // var start = (this.dataList.options.page - 1) * limit;

            // try {
            //     const result = await axios.get('/equipments', { params: { start: start, limit: limit } });


            //     this.dataList.data = result.data.data;
            //     this.dataList.count = result.data.count;

            //     this.dataList.selected = this.selectedEquipments;

            // } catch (error) {
            //     console.log('error ', error)
            //     this.$dialog.notify.error(this.$codes(error), { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
            // } finally {
            //     this.dataList.loading = false;
            // }

        },

        open() {
            this.dialog.show = true;

            this.load();

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        select() {
            this.resolve();

            this.dialog.show = false;
        },

        cancel() {
            this.reject();
            this.dialog.show = false;
        },

    },
};
</script>
  
<style lang="scss" scoped>
.device-block {
    display: flex;
}

.device-card-label {
    font-size: 14px;
    margin-left: 12px;

    .line1 {
        font-weight: 500;
        font-size: 16px;
    }
}

.page-title {
    font-size: 24px;
    text-transform: capitalize;
}
</style>