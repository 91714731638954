<template>
    <v-card class="card-infobox" :width="350" color="white">
        <v-card-text>
            <div>
                <span class="title-label" :class="type === 2 ? 'important--text' : 'primary--text'">02 - FP5T71Q</span>
                <br />
                <span class="desc-label">Placa</span>
            </div>
            <div class="mt-2">
                <v-chip class="px-6" color="gray" label text-color="primary">
                    <v-icon size="26" left>
                        mdi-map-marker-alert-outline
                    </v-icon>
                    <span class="position-chip-text">
                        Posição Imprecisa
                    </span>
                </v-chip>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.brand | enum(dataset.vehicleBrands, "id", "name")
                        }}</span>
                        <br />
                        <span class="desc-label">Marca</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.client.name }}</span>
                        <br />
                        <span class="desc-label">Cliente</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">SSID_012</span>
                        <br />
                        <span class="desc-label">Equipamento</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">2100 mV</span>
                        <br />
                        <span class="desc-label">Bateria</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">10/08/2023</span>
                        <br />
                        <span class="desc-label">Data</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">15:23:10</span>
                        <br />
                        <span class="desc-label">Hora (há 10 minutos)</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">R. Amazonas, 417
                            Santa Isabel, Viamão - RS</span>
                        <br />
                        <span class="desc-label">Endereço</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">-29.854873,
                            -50.9358308</span>
                        <br />
                        <span class="desc-label">Coordenadas</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2" v-if="type === 2">
                <div class="lightImportant px-2">
                    <v-row align="center">
                        <v-col class="px-4" sm="1">
                            <v-icon color="important" size="26" left>
                                mdi-information-outline
                            </v-icon>
                        </v-col>
                        <v-col class="ml-2">
                            <span color="important" class="position-info-text">
                                Antes de adormecer o dispositivo certifique-se que o veículo não esteja com um
                                sinistro!
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-card-text>
        <v-card-text v-if="type === 1">
            <v-row>
                <v-col>
                    <v-btn class="action-button" color="primary" @click.native="unlock()">Bloquear</v-btn>
                </v-col>
                <v-col>
                    <v-btn class="action-button" color="primary" outlined @click.native="select">Acordar</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text v-if="type === 2">
            <v-row>
                <v-col>
                    <v-btn class="action-button white--text" color="important" @click.native="unlock()">Bloquear</v-btn>
                </v-col>
                <v-col>
                    <v-btn class="action-button" color="important" outlined @click.native="select">Dormir</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="dataset.unlockDialog" width="550">
            <v-card>
                <v-card-title>
                    <div class="title-container">
                        <div class="title-block">
                            <span id="title" class="unlock-dialog-title">Desbloqueio por Código</span>
                        </div>
                        <div class="button-group">
                            <v-btn id="btnNew" icon type="button" color="important" small elevation="0"
                                @click="dataset.unlockDialog = false">

                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <span class="unlock-dialog-text">
                        O veículo de placa <span class="info-text">FP5T71Q</span> que você deseja desbloquear está on-line.
                        Você tem 02 opções de desbloqueio. O que você prefere fazer:
                    </span>
                </v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-btn class="unlock-dialog-action-button" color="important"
                                @click.native="unlock()">Desbloquear agora</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn class="unlock-dialog-action-button" color="important" outlined
                                @click.native="select">Desbloquear por código</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';

import vehicleBrands from "@/common/data/vehicle-brands.data";

Vue.use(VueLodash)

export default {
    name: "VehicleTrackerInfoBox",

    props: {
        vehicle: null,
        type: null
    },

    data: () => ({
        dataset: {
            unlockDialog: false,
            vehicleBrands: vehicleBrands,
        },
    }),

    created() {
    },

    methods: {
        unlock() {
            this.dataset.unlockDialog = true;
        }
    },
};
</script>

<style lang="scss">
.card-infobox {

    .title-label {
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        /* 380% */
    }

    .action-button {
        width: 100%;
    }


}
</style>