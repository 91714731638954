import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import isAllowed from "@/common/util/isAllowed";

import UserList from "@/views/user/UserList.vue";
import UserDetails from "@/views/user/UserDetails.vue";

import ModelList from "@/views/model/ModelList.vue";
import ModelDetails from "@/views/model/ModelDetails.vue";

import EquipmentList from "@/views/equipment/EquipmentList.vue";
import EquipmentDetails from "@/views/equipment/EquipmentDetails.vue";

import ClientList from "@/views/client/ClientList.vue";
import ClientDetails from "@/views/client/ClientDetails.vue";

import VehicleList from "@/views/vehicle/VehicleList.vue";
import VehicleDetails from "@/views/vehicle/VehicleDetails.vue";

import UnitDetails from "@/views/client/unit/UnitDetails.vue";

import Tracking from "@/views/tracking/Tracking.vue";

import user from "../common/util/identify";

import Gateway from "@/views/gateway/Gateway.vue";

import ChangeGateway from "@/views/gateway/ChangeGateway.vue";
import ChangeVehicle from "@/views/gateway/ChangeVehicle.vue";

// Route guard
const guard = function(to: any, from: any, next: any) {
  const token = localStorage.getItem("token");
  const dataUser = JSON.parse(localStorage.getItem("user") || "{}");

  const publicPath = to.matched.some(
    (path) => path.name === "Public" || path.name === "Home"
  );

  if (!token && to.path !== "/login" && !publicPath) {
    next({ path: "/login" });
    return;
  }

  if (Object.keys(dataUser).length !== 0 && !isAllowed(to.path, 'get')) {
    next({ path: "/tracking/3" });
    return;
  }

  if (to.path === '/clients' && isClient()) {
    next({ path: "/home" });
    return;
  }
  
  next();
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/Public.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: Login,
      },
      {
        path: "/",
        redirect: "/login",
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/Private.vue"),
    beforeEnter: guard,
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
        beforeEnter: guard,
      },
      {
        path: "/clients",
        name: "ClientList",
        component: ClientList,
        beforeEnter: guard,
      },
      {
        path: "/clients/:id",
        name: "ClientDetails",
        component: ClientDetails,
        beforeEnter: guard,
      },
      {
        path: "/clients/:clientId/units/:id",
        name: "UnitDetails",
        component: UnitDetails,
        beforeEnter: guard,
      },
      {
        path: "/users",
        name: "UserList",
        component: UserList,
        beforeEnter: guard,
      },
      {
        path: "/users/:id",
        name: "UserDetails",
        component: UserDetails,
        beforeEnter: guard,
      },
      {
        path: "/models",
        name: "ModelList",
        component: ModelList,
        beforeEnter: guard,
      },
      {
        path: "/models/:id",
        name: "ModelDetails",
        component: ModelDetails,
        beforeEnter: guard,
      },
      {
        path: "/equipments",
        name: "EquipmentList",
        component: EquipmentList,
        beforeEnter: guard,
      },
      {
        path: "/equipments/:id",
        name: "EquipmentDetails",
        component: EquipmentDetails,
        beforeEnter: guard,
      },
      {
        path: "/vehicles",
        name: "VehicleList",
        component: VehicleList,
        beforeEnter: guard,
      },
      {
        path: "/vehicles/:id",
        name: "VehicleDetails",
        component: VehicleDetails,
        beforeEnter: guard,
      },
      {
        path: "/tracking/:type",
        name: "Tracking",
        component: Tracking,
        beforeEnter: guard,
        // props: route => ({
        //   info: route.query.info,
        //   serial: route.query.serial,
        //   gateway: route.query.gateway
        // })
      },
      {
        path: "/gateway",
        name: "Gateway",
        component: Gateway,
        beforeEnter: guard,
      },
      {
        path: "/change-gateway",
        name: "ChangeGateway",
        component: ChangeGateway,
        beforeEnter: guard,
      },
      {
        path: "/change-vehicle",
        name: "ChangeVehicle",
        component: ChangeVehicle,
        beforeEnter: guard
      }
    ],
  },
];

function isClient() {
  const { client } = user.identify();
  return !!client;
}


const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;