import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCRQs1OHABKgpFqBckgu8_JApYPM9yiTJg',
    libraries: 'places, geometry',
    installComponents: false
  }
});