<template>
    <svg :style="{ transform: getTransform }" viewBox="0 0 285 263" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path 
          d="M136.742 175.544L179.095 141.248C181.992 138.901 182.613 134.864 180.48 132.231L86.8818 16.6468C84.7494 14.0134 80.6717 13.7809 77.7742 16.1273L35.4213 50.4239C32.5237 52.7704 31.9034 56.8072 34.0359 59.4405L127.634 175.025C129.767 177.658 133.844 177.891 136.742 175.544Z" 
          :fill="color"
        />
        <path 
          d="M133.701 172.185L134.995 171.137C135.511 170.719 135.622 170 135.242 169.531L131.905 165.411C131.526 164.942 130.799 164.901 130.283 165.318L128.989 166.367C128.473 166.784 128.362 167.503 128.742 167.972L132.079 172.093C132.458 172.562 133.185 172.603 133.701 172.185Z" 
          fill="white"
        />
        <path 
          d="M175.147 138.623L176.441 137.575C176.957 137.157 177.068 136.438 176.688 135.969L173.351 131.849C172.972 131.38 172.245 131.338 171.729 131.756L170.435 132.804C169.919 133.222 169.809 133.941 170.188 134.41L173.525 138.53C173.905 138.999 174.631 139.041 175.147 138.623Z" 
          fill="white"
        />
        <path 
          d="M102.425 124.845L136.687 97.1005C138.216 95.8618 138.375 93.7229 136.99 92.72C131.233 88.5397 125.157 83.6694 119.001 78.0204C112.511 72.0585 106.925 66.1361 102.151 60.524C101.217 59.4267 99.4412 59.3854 98.1399 60.4392L74.1291 79.8827C72.7587 80.9925 72.4666 82.8538 73.4953 83.9684C78.0947 88.9406 83.0978 95.1897 87.7768 102.878C92.3797 110.431 95.6527 117.685 98.0098 124.077C98.6245 125.756 100.831 126.146 102.423 124.857Z" 
          fill="white"
        />
        <path 
          d="M90.3914 120.571L55.1882 77.0984C54.8265 76.6518 55.4685 75.9445 55.981 76.2377C59.1601 78.069 62.6148 80.3539 66.1261 83.2181C70.3958 86.7075 73.7617 90.2414 76.3668 93.4019C79.3821 97.5217 82.5201 102.302 85.509 107.777C87.8193 111.988 89.7547 116.077 91.3796 119.937C91.6148 120.497 90.7614 121.042 90.3844 120.576Z" 
          fill="white"
        />
        <path 
          d="M134.862 84.3609L99.8324 41.1025C99.4656 40.6496 98.6315 41.148 98.8238 41.711C99.9752 45.1425 101.52 48.9325 103.584 52.896C106.137 57.7754 108.901 61.7969 111.45 65.0022C114.846 68.8138 118.863 72.8828 123.562 76.9726C127.138 80.0865 130.652 82.8128 134.023 85.2071C134.527 85.5597 135.244 84.8327 134.862 84.3609Z" 
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="68" height="161" fill="white" transform="translate(184.341 136.999) rotate(141)"/>
        </clipPath>
      </defs>
    </svg>
  </template>
  
  <script>
  export default {
    name: 'CarIcon2d',
    props: {
      rotation: {
        type: Number,
        default: 0,
      },
      color: {
        type: String,
        default: '#222E6C',
      },
    },
    computed: {
      getTransform() {
        const baseRotation = this.rotation;
        const flip = false;
        return flip ? `rotate(${baseRotation}deg) scaleX(-1)` : `rotate(${baseRotation}deg)`;
      },
    },
  };
  </script>
  