class ColorUtils {
    static getColor(vehicle) {
        return this.setColorIcon(vehicle.equipment.status, vehicle.equipment.model.type);
    }

    static setColorText(type, status) {
        if (type === 5) {
            switch (status) {
                case 1:
                case 2:
                case 6:
                    return '#FFB703'; // Amarelo
                case 3:
                case 7:
                    return '#FB3A04'; // Vermelho
                default:
                    return '#222D6C'; // Azul
            }
        }

        if (type === 4) {
            switch (status) {
                case 3:
                case 8:
                    return '#FB3A04'; // Vermelho
                default:
                    return '#222D6C'; // Azul
            }
        }

        return '#222D6C'; // Default case if type is not 4 or 5
    }

    static getTemplateFlag(type, status) {
        if (type === 5) {
            switch (status) {
                case 1:
                case 2:
                case 6:
                    return 'equipament-yellow'; // Amarelo
                case 3:
                    return 'equipament-red'; // Vermelho
                default:
                    return 'equipament-blue'; // Azul
            }
        }

        if (type === 4) {
            switch (status) {
                case 3:
                case 8:
                    return 'equipament-red'; // Vermelho
                default:
                    return 'equipament-blue'; // Azul
            }
        }

        return 'equipament-blue'; // Default case if type is not 4 or 5
    }

    static setColorIcon(status, type) {
        if (type == 5) {
            switch (status) {
                case 1:
                case 2:
                case 6:
                    return '#FFB703'; // Amarelo
                default:
                    return '#222D6C'; // Azul
            }
        }

        if (type == 4) {
            switch (status) {
                case 3:
                case 8:
                    return '#FB3A04'; // Vermelho
                default:
                    return '#222D6C'; // Azul
            }
        }

        return '#222D6C'; // Default case if type is not 4 or 5
    }

    static setColorButton(type, status) {
        if (type === 5) {
            switch (status) {
                case 1:
                case 2:
                case 4:
                case 6:
                    return 'yellow'; // Amarelo
                case 3:
                    return 'error'; // Vermelho
                default:
                    return 'primary'; // Azul
            }
        }

        if (type === 4) {
            switch (status) {
                case 3:
                case 8:
                    return 'error'; // Vermelho
                default:
                    return 'primary'; // Azul
            }
        }

        return 'primary'; // Default case if type is not 4 or 5
    }

    static setClassColorText(type, status) {
        const importantTextClasses = 'important--text title-label';
        const primaryTextClasses = 'primary--text title-label';
        const yellowTextClasses = 'yellow--text title-label';

        if (type === 5) {
            switch (status) {
                case 1:
                case 2:
                case 4:
                case 6:
                    return yellowTextClasses; // Amarelo
                case 3:
                case 7:
                    return importantTextClasses; // Vermelho
                default:
                    return primaryTextClasses; // Azul
            }
        }

        if (type === 4) {
            switch (status) {
                case 3:
                case 8:
                    return importantTextClasses; // Vermelho
                default:
                    return primaryTextClasses; // Azul
            }
        }

        return primaryTextClasses; // Default case if type is not 4 or 5
    }
}

export default ColorUtils;