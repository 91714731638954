<template>
    <svg width="14" :style="{ transform: `rotate(${rotation}deg)`, width: width + 'px', height: height + 'px'  }" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="01-moto-ok 1" clip-path="url(#clip0_849_171)">
            <g id="Camada_x0020_1">
                <path id="Vector"
                    d="M12.2743 13.993L13.9999 13.363L10.6464 1.79062L8.39996 2.5201L8.17207 1.85693L8.53016 1.35954L10.3534 0.79586L10.1256 0L4.49298 1.75743L4.68828 2.5201L6.60926 1.95642L7.03253 2.12221L7.19532 2.8185L5.33945 3.48168L2.83249 2.22162H2.05112L0.781307 2.55321L0.390625 4.01225L1.33486 7.06284V7.29494H1.13948L0.520905 7.46073L1.62785 8.95288L2.24642 10.3455L4.00461 12.5009L7.22783 15.3857L9.11622 14.9878L10.5813 17.0105L11.4604 16.7121V16.1815L10.8743 14.3578L11.1999 14.3246L10.9069 13.2304C10.7767 12.4678 11.7209 12.6004 11.6558 13.1972L12.2743 13.993Z"
                    :fill="color" />
                <path id="Vector_2"
                    d="M2.53952 5.10656L5.17669 4.27759L7.19535 11.9372L5.56745 12.2357C5.24187 12.2357 5.01391 12.2356 4.62321 12.0035L3.2232 10.7767L2.57203 9.4172L1.72557 6.63182C1.49766 5.76969 1.88836 5.33867 2.53952 5.10656Z"
                    fill="white" />
                <path id="Vector_3"
                    d="M8.82305 5.04019C9.08352 4.94071 9.34395 5.10655 9.44163 5.33867L10.1579 7.49399C10.2556 7.75926 10.0928 8.0245 9.8649 8.12397C9.60443 8.22345 9.34392 8.05769 9.24625 7.82558L8.52998 5.67025C8.43231 5.40498 8.56259 5.13966 8.82305 5.04019Z"
                    fill="white" />
                <path id="Vector_4"
                    d="M1.07424 7.26181L1.10675 6.26705L0.585867 4.54272C-0.781575 5.7696 0.585851 10.2461 2.27887 10.445L2.08349 10.0139C1.66024 9.78182 1.59515 9.28447 1.62771 8.88656L0.553277 7.46072L1.07424 7.26181Z"
                    fill="black" />
                <path id="Vector_5"
                    d="M10.9067 13.3299C10.8416 13.1641 10.8742 12.9984 10.9393 12.8657C12.0137 12.8657 13.023 14.9547 13.2509 16.2811C13.6741 19.0001 12.209 19.3317 11.4928 18.7348C10.7765 18.1711 10.1904 16.9111 10.1904 16.4469L10.5811 16.9111L11.33 16.7122C11.8509 17.8064 12.3392 17.7069 12.1764 16.2148C12.0136 15.0874 11.2323 13.9931 10.9067 14.6232L10.8416 14.3247L11.1672 14.2916L10.9067 13.3299Z"
                    fill="black" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_849_171">
                <rect width="14" height="19" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
  
<script>
export default {
  name: 'MotorcycleIcon',
  props: {
    rotation: {
      type: Number,
      default: 0
    },
    color: {
        type: String,
        default: '#222D6C'
      },
    width: {
      type: Number,
      default: 30
    },
    height: {
      type: Number,
      default: 30
    }
  }
}
</script>