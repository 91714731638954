<template>
    <v-dialog v-model="dataset.opened" max-width="650">
        <div class="modal">
            <v-card v-if="dataset.type === 1">
                <v-card-title class="headline title-modal">
                    Desbloqueio Equipamento
                    <v-spacer></v-spacer>
                    <v-icon @click="closeModal" class="close-icon" color="#FB3A04">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="box-info-text">
                            <span class="info-text">O equipmaento serial <span v-if="equipment" class="info-plate">{{ equipment.serial }}</span> que você deseja desbloquear esta on-line. <br/> Você tem 02 opções de desbolqueio. O que você prefere fazer:</span>
                        </v-col>
                    </v-row>
                    <v-row class="container-buttons">
                        <v-col cols="6">
                            <v-btn color="#FB3A04" :loading="dataset.loading" @click="handleUnblock">Desbloquear Agora</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="#FB3A04" outlined @click="handleChangeType(2)" disabled>Desbloquear por código</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';
import { eventHub } from '@/main';

Vue.use(VueLodash)

export default {
    name: "ModalUnblock",
    props: {
        equipment: Object,
    },
    data: () => ({
        dataset: {
            type: 1,
            opened: false,
            loading: false,
            selectedReason: null,
            data: {
                rede: null,
                observation: null,
                code: "0088326",
                reasons: [
                    {
                        id: "1",
                        name: "Manutenção do Veículo"
                    }
                ]
            }
        },
        defaultDataset: {
            type: 1,
            opened: false,
            loading: false,
            selectedReason: null,
            data: {
                rede: null,
                observation: null,
                code: "0088326",
                reasons: [
                    {
                        id: "1",
                        name: "Manutenção do Veículo"
                    }
                ]
            }
        }
    }),
    created() {
        eventHub.$on("EQUIPMENT-UNBLOCK-CHANGE", (value) => {
            this.dataset.opened = value;
        });
    },
    methods: {
        closeModal() {
            this.dataset.opened = false;
        },

        handleChangeType(type) {
            if(this.dataset.loading) return;

            this.dataset.type = type;
        },

        async handleUnblock() {
            if(!this.equipment) return;

            this.dataset.loading = true;
            try {
                await axios.post(`/equipments/${this.equipment.id}/instructions`, {
                    status: 8,
                    type: 2,
                    content: '{"block": false}'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 5500);
            } catch (error) {
                console.log('error ', error);
                this.dataset.opened = false;
                this.dataset.loading = false;
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.opened = false;
                this.dataset.loading = false;
                this.closeWindow();
                this.$dialog.notify.success('Instrução enviada com sucesso!', { position: 'top-right', timeout: 5000 });
            }
        }
    },
};
</script>

<style lang="scss">

.v-overlay--active>div {
   opacity: 0.9 !important;
   background-color: #222D6C !important;
}
.modal{
    
    .theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
        color: #222D6C !important;
        font-weight: 500 !important;
    }

    .close-icon {
        cursor: pointer;
    }

    .title-modal{
        color: #FB3A04;
        font-size: 32px !important;
        font-weight: 700 !important;
        text-transform: none !important;
        font-family: Montserrat !important;
    }

    .v-application .headline {
        font-family: Montserrat !important;
    }


    .info-text {
        color: #959595;
        font-size: 16px !important;
        font-weight: 500 !important;
        font-family: Montserrat !important;
    }

    .info-plate{
        color: #FB3A04;
        font-size: 16px !important;
        font-weight: 700 !important;
        font-family: Montserrat !important;
    }

    .container-buttons button{
        width: 100%;
        font-size: 13px !important;
        font-family: Montserrat !important;
    }

    .container-buttons button:first-of-type {
        color: #ffff;
    }


    .box-code {
        height: 100px;
        display: flex;
        margin-top: 40px !important;
        align-items: center;
        padding: 0 !important;
        flex-direction: column;
        justify-content: center;
        background-color: #EFEFEF;
    }

    .info-code{
        margin-bottom: 5px;
        color: #FB3A04;
        font-size: 35px !important;
        font-weight: 700 !important;
        font-family: Montserrat !important;
    }

    .text-info-code{
        color: #222D6C;
        font-weight: 500;
}
}


</style>



