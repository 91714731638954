const data = [
  { id: 5, name: "Retorno" },
  { id: 4, name: "Estoque" },
  { id: 2, name: "Trânsito" },
  { id: 1, name: "Instalado" },
  { id: 3, name: "Manutenção" },

];

export default [...data];
