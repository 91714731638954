<template>
    <v-dialog v-model="dataset.opened" max-width="550">
        <div class="modal">
            <v-card>
                <v-card-title class="headline title-modal">
                    Acordar Dispositivo
                    <v-spacer></v-spacer>
                    <v-icon @click="closeModal" class="close-icon" color="#FB3A04">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="box-info-text">
                            <span class="info-text">Você tem certeza que deseja acordar o dispositivo do  <br/>veículo de placa <span v-if="vehicle" class="info-plate">{{ vehicle.plate }}</span>?</span>
                        </v-col>
                    </v-row>
                    <v-row class="box-info-text">
                        <v-col cols="12" class="info-sub-text">
                            <span>Lembre-se que toda vez que você acordar o dispositivo aumentará o consumo de bateria. Só acorde se você tiver certeza da ação.</span>
                        </v-col>
                    </v-row>
                    <v-row class="container-buttons">
                        <v-col cols="6">
                            <v-btn color="#FB3A04" :loading="dataset.loading" @click="handleWakeup">Sim, Acordar Agora</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="#FB3A04" outlined @click="closeModal">Não, Manter Dormindo</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
import Vue from "vue";
import VueLodash from 'vue-lodash'
import _, { set } from 'lodash';
import axios from 'axios';
import { eventHub } from '@/main';

Vue.use(VueLodash)

export default {
    name: "ModalWakeup",
    props: {
        vehicle: Object,
        equipment: Object,
    },
    data: () => ({
        dataset: {
            opened: false,
            loading: false,
        },
    }),
    created() {
        eventHub.$on("VEHICLE-DETAIL-WAKEUP-CHANGE", (value) => {
            this.dataset.opened = value;
        });
    },
    methods: {
        closeModal() {
            this.dataset.opened = false;
        },

        async handleWakeup() {
            if(!this.vehicle || !this.equipment) return;
            this.dataset.loading = true;
            try {       
                await axios.post(`/vehicles/${this.vehicle.id}/equipments/${this.equipment.id}/instructions`, {
                    status: 5,
                    type: 1,
                    content: '{"interval": 180}'
                });             
                setTimeout(() => {
                    window.location.reload();
                }, 5500);
            } catch (error) {
                console.log('error ', error);
                this.dataset.opened = false;
                this.dataset.loading = false;
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.opened = false;
                this.dataset.loading = false;
                this.$dialog.notify.success('Instrução enviada com sucesso!', { position: 'top-right', timeout: 5000 });
            }
        }
    },
};
</script>

<style lang="scss">

.v-overlay--active>div {
   opacity: 0.9 !important;
   background-color: #222D6C !important;
}
.modal{
    
    .theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
        color: #222D6C !important;
        font-weight: 500 !important;
    }

    .close-icon {
        cursor: pointer;
    }

    .title-modal{
        color: #FB3A04 !important;
        font-size: 32px !important;
        font-weight: 700 !important;
        text-transform: none !important;
        font-family: Montserrat !important;
    }

    .v-application .headline {
        font-family: Montserrat !important;
    }


    .info-text {
        color: #959595;
        font-size: 16px !important;
        font-weight: 500 !important;
        font-family: Montserrat !important;
    }

    .info-sub-text{
        color: #959595;
        font-size: 13px !important;
        font-weight: 500 !important;
        font-family: Montserrat !important;
    }

    .info-plate{
        color: #FB3A04;
        font-size: 16px !important;
        font-weight: 700 !important;
        font-family: Montserrat !important;
    }

    .container-buttons button{
        width: 100%;
        font-size: 13px !important;
        font-family: Montserrat !important;
    }

    .container-buttons button:first-of-type {
        color: #ffff;
    }


    .box-code {
        height: 100px;
        display: flex;
        margin-top: 40px !important;
        align-items: center;
        padding: 0 !important;
        flex-direction: column;
        justify-content: center;
        background-color: #EFEFEF;
    }

    .info-code{
        margin-bottom: 5px;
        color: #FB3A04;
        font-size: 35px !important;
        font-weight: 700 !important;
        font-family: Montserrat !important;
    }

    .text-info-code{
        color: #222D6C;
        font-weight: 500;
}
}


</style>



