<template>
  <div class="indicator-container">
    <div class="signal-indicator">
      <div class="background"></div>
      <div class="inner-circle"></div>
      <div class="pointer-container">
        <div class="pointer" :style="{ transform: `rotate(${rotation}deg)` }"></div>
        <div class="pointer-base">
          <div class="pointer-inner-circle"></div>
        </div>
      </div>
      <span class="rssi-info">{{ rssi }} dB</span>
    </div>
    <div class="legend">
      <div class="legend-item">
        <div class="color-circle red"></div>
        <span>Distante</span>
      </div>
      <div class="legend-item">
        <div class="color-circle orange"></div>
        <span>Intermediário</span>
      </div>
      <div class="legend-item">
        <div class="color-circle green"></div>
        <span>Próximo</span>
      </div>
    </div>

    <v-card-text v-if="viewReturn">
      <v-row>
        <v-col cols="4">
          <v-btn class="action-button" color="primary" outlined @click="onReturn">Voltar</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'SignalStrengthIndicator',
  props: {
    rssi: {
      type: Number,
      required: true
    },
    minRssi: {
      type: Number,
      default: -0
    },
    maxRssi: {
      type: Number,
      default: -120
    },
    viewReturn: {
      type: Boolean,
      required: false,
      default: false,
    },
    onReturn: {
      type: Function,
      required: false
    }
  },
  computed: {
    rotation() {
      // Limita o valor de rssi entre minRssi e maxRssi
      const clampedRssi = Math.min(Math.max(this.rssi, this.maxRssi), this.minRssi);

      // Calcula a rotação com base no valor ajustado
      const range = this.minRssi - this.maxRssi;
      return ((clampedRssi - this.maxRssi) / range) * 240 - 120;
    }
  }
}
</script>

<style scoped>
.indicator-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signal-indicator {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: rotate(180deg);
  background: conic-gradient(
    #EFEFEF 0deg 60deg, 
    red 0deg 130deg, 
    orange 150deg 200deg, 
    green 245deg 300deg, 
    #EFEFEF 100deg 0deg
  );
}

.inner-circle {
  position: absolute;
  width: 270px;
  height: 270px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pointer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pointer {
  width: 5px;
  height: 120px;
  background-color: #222D6C;
  position: absolute;
  bottom: 50%;
  left: 50%;
  z-index: 100;
  border-radius: 20px;
  transform-origin: bottom center;
  transition: transform 0.5s ease;
}

.pointer-base {
  width: 30px;
  height: 30px;
  border: 1px solid #EFEFEF;
  background-color: #EFEFEF;
  border-radius: 50%;
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer-inner-circle {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
}

.rssi-info {
  position: absolute;
  top: 70%;
  left: 50%;
  font-weight: 500;
  transform: translateX(-50%);
  font-size: 20px;
  color: #959595;
}

.legend {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.color-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.red {
  background-color: red;
}

.orange {
  background-color: orange;
}

.green {
  background-color: green;
}
</style>