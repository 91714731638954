<template>
    <svg :style="{ transform: getTransform }" viewBox="0 0 1811 1694" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M934.411 1185.37L1213.41 959.44C1232.49 943.983 1239.16 920.577 1228.3 907.16L588.523 117.107C577.658 103.69 553.378 105.344 534.29 120.801L255.295 346.727C236.208 362.184 229.541 385.59 240.406 399.006L880.179 1189.06C891.043 1202.48 915.324 1200.82 934.411 1185.37Z" 
        :fill="color"
      />
      <path 
        d="M1247.97 1572.57L1526.96 1346.65C1546.05 1331.19 1552.71 1307.78 1541.85 1294.37L1276.36 966.514C1265.49 953.097 1241.21 954.751 1222.13 970.208L943.131 1196.13C924.044 1211.59 917.378 1235 928.242 1248.41L1193.73 1576.27C1204.6 1589.68 1228.88 1588.03 1247.97 1572.57Z" 
        :fill="color"
      />
      <path 
        d="M969.069 1191.9L1212.59 994.698L1189 965.566L945.478 1162.77L969.069 1191.9Z" 
        :fill="color"
      />
      <path 
        d="M1232.18 1555.69L1240.71 1548.79C1244.11 1546.03 1245.29 1541.87 1243.36 1539.48L1226.36 1518.48C1224.43 1516.1 1220.1 1516.39 1216.7 1519.14L1208.18 1526.05C1204.78 1528.8 1203.59 1532.97 1205.52 1535.36L1222.52 1556.35C1224.46 1558.74 1228.78 1558.44 1232.18 1555.69Z" 
        fill="white"
      />
      <path 
        d="M1505.2 1334.6L1513.73 1327.7C1517.13 1324.95 1518.32 1320.78 1516.38 1318.39L1499.38 1297.4C1497.45 1295.01 1493.12 1295.3 1489.72 1298.05L1481.2 1304.96C1477.8 1307.71 1476.61 1311.88 1478.55 1314.27L1495.54 1335.26C1497.48 1337.65 1501.8 1337.36 1505.2 1334.6Z" 
        fill="white"
      />
      <path 
        d="M1231.74 1478.15L1432.41 1315.65C1435.65 1313.03 1453.75 1297.73 1459.32 1275.61C1464.5 1255.07 1455.27 1243.01 1453.22 1240.48L1373.7 1142.29C1375.24 1144.65 1384.76 1160.23 1374.63 1183.14C1367.83 1198.52 1355.92 1208.8 1351.36 1212.49L1151.15 1374.61C1146.57 1378 1133.03 1387.38 1115.84 1389.84C1093.91 1392.95 1082.26 1382.49 1079.89 1380.21L1159.54 1478.57C1161.65 1480.89 1174.51 1494.73 1197 1492.11C1213.58 1490.21 1226.95 1481.55 1231.73 1478.22L1231.74 1478.15Z" 
        fill="white"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: 'TruckIcon2d',
    props: {
      rotation: {
        type: Number,
        default: 0,
      },
      color: {
        type: String,
        default: '#222D6C',
      },
    },
    computed: {
      getTransform() {
        return `rotate(${this.rotation}deg)`;
      },
    },
  };
  </script>
  