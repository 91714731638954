import Vue, { PluginObject } from "vue"; 


const types: any = {

    profiles: {
        1: 'Adulto',
        2: 'Infantil'
    },
    indicators: {
        1: 'Deglutição',
        2: 'Comunicação',
        3: 'Audiologia',
        4: 'Teste da Linguinha'
    },
    scales: {
        0: '',
        1: 'Asha Noms',
        2: 'Asha Facts',
        3: 'Fois',
        4: 'PTVO'
    },
    frequency: {
        1: '1x por dia',
        2: '2x por dia',
        3: '3x por dia',
        4: '1x por semana',
        5: '2x por semana',
        6: '3x por semana'
    },
    discharges: {
        1: 'Alta administrativa',
        2: 'Alta fonoaudiológica',
        3: 'Alta hospitalar',
        // 4: 'Inconformidade',
        5: 'Não apto',
        6: 'Não prescrito',
        7: 'Óbito',
        8: 'Piora clínica'
    },
    shifts: {
        0: {
            name: 'pendente',
            color: 'lightgray',
            icon: 'mdi-help-circle'
        },
        1: {
            name: 'checado',
            color: 'darkseagreen',
            icon: 'mdi-checkbox-marked-circle'
        },
        2: {
            name: 'não checado',
            color: 'palevioletred',
            icon: 'mdi-minus-circle'
        },
    },
    procedures: {
        video: {
            status: {
                1: "Contraste não entra na via aérea",
                2: "Contrate entra acima das pregas vocais sem resíduo",
                3: "Contraste permanece acima das pregas vocais com resíduo",
                4: "Constrate atinge pregas vocais sem resíduo",
                5: "Contrate atinge pregas vocais com resíduo visível",
                6: "Contraste passa para o nível glótico, mas não há resíduo no nível subglótico",
                7: "Contraste passa o nível glótico com resíduo no nível subglótico apeas do paciente responder",
                8: "Contraste passa o nível glótico, com resíduo no nível subglótico, mas o paciente não responde"
            }
        }
    },
    time: {
        types: {
            1: 'Sistema',
            2: 'Esquecimento',
            3: 'Sistema, mas editado',
            4: 'Horário não sincronizado'
        } 
    },
    authorization: {
        long: {
            1: 'Aguardando autorização',
            2: 'Autorizado',
            3: 'Não autorizado'
        },
        short: {
            1: 'aguardando',
            2: 'autorizado',
            3: 'não autorizado'
        }
    },
    conducts: {
        1: 'Via alternativa de alimentação + via oral',
        2: 'Via alternativa de alimentação exclusiva',
        3: 'Via oral exclusiva'
    },
    utensils: {
        1: 'Avent Fluxo 1',
        2: 'Avent Fluxo 2',
        3: 'Colher',
        4: 'Mamadeira Comum',
        5: 'NUK Fluxo Água e Chá',
        6: 'NUK Fluxo Leite',
        7: 'Seio Materno',
        8: 'Seio Materno com Intermediário'
    },
    consistencies: {
        1: 'Líquida',
        2: 'Líquida + Espessante',
        3: 'Pastosa',
        4: 'Pastosa + Espessante',
        5: 'Semi Pastosa',
        6: 'Semi Pastosa + Espessante',
        7: 'Sólida',
        8: 'Sólida + Espessante',
    },
    levels: { //are described by scale   AshaNoms = 1, AshaFacts = 2, Fois = 3, Ptvo = 4,
          '1': [
                {value: 1, text: 'Nível 1', description: 'O indivíduo não é capaz de deglutir com segurança pela boca. Toda nutrição e hidratação são necessárias através de recursos não orais (ex: sonda nasográstica, gastrostomia).'},
                {value: 2, text: 'Nível 2', description: 'O indivíduo não é capaz de deglutir com segurança pela boca para nutrição e hidratação, mas pode ingerir algum consistência, somente em terapia, com uso máximo e consistente de pistas. Método alternativo de alimentação é necessário'},
                {value: 3, text: 'Nível 3', description: 'Método alternativo de alimentação é necessário, uma vez que o indivíduo ingere menos que 50% da nutrição e hidratação pela boca; e/ou a deglutição é segura com uso moderado de pistas para uso de estratégias compensatórias; e/ou necessita de restrição máxima da dieta.'},
                {value: 4, text: 'Nível 4', description: 'A deglutição é segura, mas frequentemente requer uso moderado de pistas para uso de estratégias compensatórias; e/ou o indivíduo tem restrições moderadas de dieta; e/ou ainda necessita de alimentação por tubo e/ou suplemento oral.'},
                {value: 5, text: 'Nível 5', description: 'A deglutição é segura com restrições mínimas da dieta; e/ou ocasionalmente requer pistas mínimas para uso de estratégias compensatórias. Ocasionalmente pode auto monitorar-se. Toda nutrição e hidratação são recebidas pela boca durante a refeição'},
                {value: 6, text: 'Nível 6', description: 'A deglutição é segura e o indivíduo come e bebe independentemente. Raramente necessita de pistas mínimas para uso de estratégias compensatórias. Freqüentemente se auto monitora quando ocorre dificuldades. Pode ser necessário evitar alguns itens específicos de alimentos. (Ex: pipoca e amendoim). Tempo adicional para alimentação pode ser necessário (devido a disfagia).'},
                {value: 7, text: 'Nível 7', description: 'A habilidade do indivíduo em se alimentar independentemente não é limitada pela função de deglutição.A deglutição é segura e eficiente para todas as consistências. Estratégias compensatórias são utilizadas efetivamente quando necessárias.'}
              ],
          '2': [
                {value: 1, text: 'Nível 1', description: 'Reconhece faces familiares.'},
                {value: 2, text: 'Nível 2', description: 'Reconhece vozes familiares.'},
                {value: 3, text: 'Nível 3', description: 'Transmite gosto ou desgosto (ex. pessoas lugares, alimentos)'},
                {value: 4, text: 'Nível 4', description: 'Expressa sentimentos (por ex. felicidade, tristeza)'},
                {value: 5, text: 'Nível 5', description: 'Solicita ajuda quando necessário (por ex. gestos indicando que a cadeira de roda está travada)'},
                {value: 6, text: 'Nível 6', description: 'Expressa necessidades ou desejos (por exemplo comer, descansar).'},
                {value: 7, text: 'Nível 7', description: 'Reage numa emergência (por ex. chamar a polícia)'}
              ],
          '3': [
                {value: 1, text: 'Nível 1', description: 'Nada por via oral.'},
                {value: 2, text: 'Nível 2', description: 'Dependente de via alternativa e mínima VO de algum alimento ou líquido.'},
                {value: 3, text: 'Nível 3', description: 'Dependente de via alternativa com consistente VO de alimento ou líquido.'},
                {value: 4, text: 'Nível 4', description: 'VO total de uma única consistência.'},
                {value: 5, text: 'Nível 5', description: 'Via oral total com múltiplas consistências, porém com necessidade de preparo especial ou compensações.'},
                {value: 6, text: 'Nível 6', description: 'Via oral total com múltiplas consistências, porém sem necessidade de preparo especial ou compensações, porém com restrições alimentares.'},
                {value: 7, text: 'Nível 7', description: 'Via oral total sem restrições'}
              ],
          '4': [
                {value: 1, text: 'Nível 1', description: 'Oral - Não nutritiva - (PRO < 30% e TT < 1,5ml/min)'},
                {value: 2, text: 'Nível 2', description: 'Oral - Nutritiva  - Nível 2 (PRO < 30% e TT > 1,5ml/min) '},
                {value: 3, text: 'Nível 3', description: 'Oral - Nutritiva - Nível 3 (PRO > 30% e TT < 1,5ml/min) '},
                {value: 4, text: 'Nível 4', description: 'Oral - Nutritiva - Nível 4 (PRO > 30% e TT > 1,5ml/min)'},
            ]
      },
};

class TypesPlugin implements PluginObject<any>{

    install = (_Vue: typeof Vue, options?: any) => {        
        _Vue.prototype.$types = types;
    };
    [key: string]: any;    
}

const plugin = new TypesPlugin;
Vue.use(plugin);
