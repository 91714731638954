<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col cols="12">
                    <v-card class="filter-card">
                        <v-card-title class="filter-card-title">Filtro</v-card-title>
                        <v-card-text>
                            <v-form @submit.prevent="load()">
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-select id="client" :items="dataset.clients" label="Cliente"
                                            v-model="dataList.filter.client" item-value="id" item-text="name" outlined
                                            hide-details="auto">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select id="stage" :items="dataset.equipmentStages" label="Estágio"
                                            v-model="dataList.filter.stage" item-value="id" item-text="name" outlined
                                            hide-details="auto">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select id="status" :items="dataset.status" label="Status"
                                            v-model="dataList.filter.status" item-value="id" item-text="name" outlined
                                            hide-details="auto">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2"
                                            large>Filtrar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="table-card">
                        <v-card-title class="table-card-title">
                            <div class="title-container">
                                <div class="title-block">
                                    <span id="title" class="table-card-title">Equipamentos</span>
                                </div>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="dataList.headers" :items="dataList.data"
                                :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                                @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                                no-data-text="Nenhum equipamento foi encontrado" loading-text="Carregando dados..."
                                class="app-table hover-table" :options.sync="dataList.options"
                                :footer-props="dataList.footerOptions">

                                <template v-slot:[`item.serial`]="{ item }">
                                    <span class="list-item-title">{{ item.serial }}</span><br />
                                    <span class="list-item-subtitle">{{ item.iccid }}</span>
                                </template>

                                <template v-slot:[`item.model`]="{ item }">
                                    <span class="list-item-title">{{ item.model.name }}</span><br />
                                    <span class="list-item-subtitle">{{ item.type | enum(dataset.equipmentTypes, "id",
                                        "name")
                                    }}</span>
                                </template>

                                <template v-slot:[`item.stage`]="{ item }">
                                    <span class="list-item-title">{{ item.stage | enum(dataset.equipmentStages, "id",
                                        "name")
                                    }}</span><br />
                                    <span class="list-item-subtitle">{{ item.technician || "-" }}</span>
                                </template>

                                <template v-slot:[`item.client`]="{ item }">
                                    <span class="list-item-title">{{ item.client.name }}</span><br />
                                    <span v-if="item.vehicle" class="list-item-subtitle">{{ item.vehicle.type |
                                                                            enum(dataset.vehicleTypes, "id",
                                        "name") }} - {{ item.vehicle.plate.toUpperCase() }}</span>
                                    <span v-else class="list-item-subtitle">-</span>
                                </template>

                                <template v-slot:[`item.date`]="{ item }">
                                    <span class="list-item-title">{{ $moment(item.date).format('DD.MM.YYYY') }}</span><br />
                                    <span class="list-item-subtitle">{{ $moment(item.date).format('HH:MM:ss') }}</span>
                                </template>

                                <template v-slot:[`item.status`]="{ item }">
                                    <v-chip label text-color="primary">
                                        {{ item.status | enum(dataset.status, "id", "name") }}
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon @click="viewItem(item)" size="large" class="me-2">
                                        mdi-truck-delivery-outline
                                    </v-icon>
                                    <v-icon @click="viewItem(item)" size="large" class="me-2">
                                        mdi-qrcode
                                    </v-icon>
                                    <v-icon @click="viewItem(item)" size="large" class="me-2">
                                        mdi-pencil-outline
                                    </v-icon>
                                    <v-icon @click="remove(item)" size="large">
                                        mdi-delete-outline
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import vehicleTypes from "@/common/data/vehicle-types.data"
import equipmentTypes from "@/common/data/equipment-types.data"
import equipmentStages from "@/common/data/equipment-stages.data"
import status from "@/common/data/status.data";
import object from "@/common/util/object"

Vue.use(VueLodash)

export default {
    name: "EquipmentList",

    props: {
        model: null
    },

    data: () => ({
        dataset: {
            vehicleTypes: vehicleTypes,
            equipmentTypes: [{ id: "", name: "Todos:" }, ...equipmentTypes],
            equipmentStages: [{ id: "", name: "Todos:" }, ...equipmentStages],
            status: status,
            clients: [],
        },
        dataList: {
            filter: {
                type: "",
                client: "",
                status: 0
            },
            headers: [
                { text: "Serial/ICCID", value: "serial" },
                { text: "Modelo/Tipo", value: "model" },
                { text: "Estágio/Técnico", value: "stage" },
                { text: "Data", value: "date" },
                { text: "Cliente/Veículo", value: "client" },
                { text: "Status", value: "status" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
        this.loadClients();
    },

    methods: {
        clientPageText() {
            return `${this.dataList.paging.page} de ${this.dataList.paging.pages}`;
        },

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.list();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.list(true);
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        async load() {

            this.dataList.filter.model = this.model;

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/equipments', { params: { start: start, limit: limit, search: search } });

                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async loadClients() {
            try {
                const result = await axios.get('/clients', { params: { limit: 1000000 } });

                this.dataset.clients = [{ id: "", name: "Todos" }, ...result.data.data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async remove(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja excluir o equipamento?',
                title: 'Excluir equipamento',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                await axios.delete(`/equipments/${item.id}`)

                this.$dialog.notify.info("Equipamento excluído com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`equipments/${item.id}`);
        },
    },
};
</script>