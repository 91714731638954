<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="secondary" dark @click="add"
                            :loading="clientForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn :disabled="isClient()" id="btnSave" type="button" elevation="0" color="secondary" @click="update"
                            :loading="clientForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/clients">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="dataset.data.type !== 3 && !page.isNew">
            <v-col :cols="12">
                <div class="title-container">
                    <div class="button-group">
                        <v-btn :color="dataset.isViewUnits ? 'mediumGray' : 'secondary'" outlined
                            @click="dataset.isViewUnits = false">
                            Detalhes

                            <v-icon right>mdi-format-list-text</v-icon>

                        </v-btn>
                        <v-btn outlined @click="dataset.isViewUnits = true"
                            :color="!dataset.isViewUnits ? 'mediumGray' : 'secondary'">
                            Unidades
                            <v-icon right>mdi-source-branch</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <unit-list v-if="dataset.isViewUnits && !page.isNew && dataset.data.type !== 3"
            :client="dataset.data.id"></unit-list>
        <v-form v-if="!dataset.isViewUnits" ref="form" v-model="clientForm.validForm">
            <v-row>
                <v-col cols="12" sm="8">
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="name"
                                                :disabled="isClient()"
                                                :label="dataset.data.type === 3 ? 'Nome' : 'Nome fantasia'"
                                                v-model="dataset.data.name" required :rules="[$rules.required]" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field  label="Razão Social" v-model="dataset.data.tradeName" required
                                                outlined hide-details="auto" :disabled="dataset.data.type === 3 || isClient()">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="accountNumber" label="Código ERP"
                                                v-model="dataset.data.accountNumber" outlined hide-details="auto" disabled>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" v-if="dataset.data.type !== 3" id="CNPJ" label="CNPJ"
                                                v-model="dataset.data.registrationNumber" v-mask="$masks.cnpj" required
                                                :rules="[$rules.required, $rules.cnpj]" outlined hide-details="auto">
                                            </v-text-field>
                                            <v-text-field :disabled="isClient()" v-if="dataset.data.type === 3" id="CPF" label="CPF"
                                                v-model="dataset.data.registrationNumber" v-mask="$masks.cpf" required
                                                :rules="[$rules.required, $rules.cpf]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            <v-text-field id="clientIE" label="Inscrição estadual" v-model="dataset.data.ie"
                                                outlined hide-details="auto" :disabled="dataset.data.type === 3 || isClient()">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            <v-text-field id="clientIM" label="Inscrição municipal"
                                                v-model="dataset.data.im" outlined hide-details="auto"
                                                :disabled="dataset.data.type === 3 || isClient()">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="8">
                                            <v-textarea :disabled="isClient()" id="description" label="Dados adicionais"
                                                v-model="dataset.data.description" outlined hide-details="auto" rows="1"
                                                auto-grow></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select :disabled="isClient()" id="type" :items="dataset.clientTypes" label="Tipo"
                                                v-model="dataset.data.type" item-value="id" item-text="name" outlined
                                                hide-details="auto" v-on:change="changeType">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>Endereço</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="4" sm="3">
                                            <v-text-field :disabled="isClient()" id="addressZipCode" label="CEP" maxlength="9"
                                                v-mask="$masks.zipCode" v-model="dataset.data.addressZipCode" outlined
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field :disabled="isClient()" id="addressStreet" label="Endereço" maxlength="120"
                                                v-model="dataset.data.addressStreet" outlined
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col :cols="4">
                                            <v-text-field :disabled="isClient()" id="addressNumber" label="Número"
                                                v-model="dataset.data.addressNumber" maxlength="10" outlined
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col :cols="8">
                                            <v-text-field :disabled="isClient()" id="addressComplement" label="Complemento"
                                                v-model="dataset.data.addressComplement" maxlength="30" outlined
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="addressDistrict" label="Bairro" maxlength="40"
                                                v-model="dataset.data.addressDistrict" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="addressCity" label="Cidade" maxlength="60"
                                                v-model="dataset.data.addressCity" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select :disabled="isClient()" id="addressState" :items="dataset.states" label="Estado"
                                                v-model="dataset.data.addressState" item-value="id" item-text="name"
                                                outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>{{ dataset.data.type === 3 ? 'Contato' : 'Responsável' }}</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="contactName" label="Nome" v-model="dataset.data.contactName"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="contactPhone" label="Telefone" v-mask="$masks.phone"
                                                v-model="dataset.data.contactPhone" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="contactEmail" type="email" label="E-mail"
                                                v-model="dataset.data.contactEmail" :rules="[$rules.email]" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card color="theme-card">
                                <v-card-title class="card-group-title">Customizar</v-card-title>
                                <v-card-title class="card-section-title">Geral</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field disabled id="urlLogin" label="URL Login" v-model="dataset.data.themeSlug"
                                                :rules="[$rules.slug]" persistent-hint outlined>
                                                <!-- <template v-slot:append>
                                                    <TooltipViewer code="client.themeSlug"></TooltipViewer>
                                                </template> -->
                                            </v-text-field>
                                            <v-file-input disabled accept="image/png, image/jpeg" prepend-icon="" outlined counter
                                                :rules="[$rules.fileSize(4)]" hint="O arquivo não pode ser superior a 4MB"
                                                hide-details="auto" label="Logotipo" :clearable="false"
                                                @change="onLogoSelected" ref="logoFile">
                                                <!-- <template v-slot:append>
                                                    <TooltipViewer code="client.themeSlogan"></TooltipViewer>
                                                </template> -->
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-title class="card-section-title">Login</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-file-input disabled accept="image/png, image/jpeg" prepend-icon="" outlined counter
                                                label="Imagem" :rules="[$rules.fileSize(4)]"
                                                hint="O arquivo não pode ser superior a 4MB" :clearable="false"
                                                hide-details="auto" @change="onBackgroundSelected" ref="backgroundFile">
                                                <!-- <template v-slot:append>
                                                    <TooltipViewer code="client.backgroundImage"></TooltipViewer>
                                                </template> -->
                                            </v-file-input>
                                            <v-text-field disabled id="themeSlogan" v-model="dataset.data.themeSlogan" outlined
                                                hide-details="auto" label="Slogan">
                                                <!-- <template v-slot:append>
                                                    <TooltipViewer code="client.themeSlogan"></TooltipViewer>
                                                </template> -->
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-title class="card-section-title">Cores</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="color-row">
                                                <div class="color-column">
                                                    <v-text-field disabled id="themeLightPrimaryColor" class="color-picker"
                                                        type="color" v-model="dataset.data.themeLightPrimaryColor" outlined
                                                        hide-details="auto">
                                                    </v-text-field>
                                                </div>
                                                <div class="description-column">
                                                    <span class="color-title">Primária (padrão)</span>
                                                    <span>Cor utilizadas no topo, títulos e textos padrões na versão
                                                        clara</span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="color-row">
                                                <div class="color-column">
                                                    <v-text-field disabled id="themeDarkPrimaryColor" class="color-picker"
                                                        type="color" v-model="dataset.data.themeDarkPrimaryColor" outlined
                                                        hide-details="auto">
                                                    </v-text-field>
                                                </div>
                                                <div class="description-column">
                                                    <span class="color-title">Primária (modo escuro)</span>
                                                    <span>Cor utilizadas no topo, títulos e textos padrões na versão
                                                        escura</span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="color-row">
                                                <div class="color-column">
                                                    <v-text-field disabled id="themeLightSecondaryColor" class="color-picker"
                                                        type="color" v-model="dataset.data.themeLightSecondaryColor"
                                                        outlined hide-details="auto">
                                                    </v-text-field>
                                                </div>
                                                <div class="description-column">
                                                    <span class="color-title">Secundária (padrão)</span>
                                                    <span>Cor utilizada em lugares de destaque na versão clara</span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="color-row">
                                                <div class="color-column">
                                                    <v-text-field disabled id="themeDarkSecondaryColor" class="color-picker"
                                                        type="color" v-model="dataset.data.themeDarkSecondaryColor" outlined
                                                        hide-details="auto">
                                                    </v-text-field>
                                                </div>
                                                <div class="description-column">
                                                    <span class="color-title">Secundária (modo escuro)</span>
                                                    <span>Cor utilizada em lugares de destaque na versão escura</span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
  
<script>
import clientTypes from "@/common/data/client-types.data"
import states from "@/common/data/states.data"
import string from "@/common/util/string"
import user from "@/common/util/identify"
import UnitList from "@/views/client/unit/UnitList.vue"

import axios from "axios"

// import TooltipViewer from '@/components/ui/TooltipViewer.vue';

export default {
    name: "ClientDetails",

    components: { 'unit-list': UnitList },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            clientTypes: clientTypes,
            states: states,
            // states: states,
            // contractStatus: contractStatus,
            data: {
                status: 0
            },
            isViewUnits: false,
        },
        reportLinear: [
            {
                key: 1,
                text: "Aguarda Desinstalação",
                color: "black",
                value: 0
            },
            {
                key: 2,
                text: "Com Problema",
                color: "primary",
                value: 0
            },
            {
                key: 3,
                text: "Precisa Revisão",
                color: "secondary",
                value: 0
            },
            {
                key: 4,
                text: "Falha Desconhecida",
                color: "mediumGray",
                value: 0
            },
            {
                key: 5,
                text: "Aguarda Desbloqueio",
                color: "important",
                value: 0
            },
        ],
        clientId: undefined,
        clientForm: {
            files: {},
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            removeBackgroundLoading: false,
            removeLogoLoading: false,
            previewBackgroundLoading: false,
            previewLogoLoading: false,
            created: null,
        },
    }),

    created() {
        this.setPage();
        this.listBreadCrumbItems();
        this.load();
    },

    methods: {
        goBack() {
            this.$router.replace("/clients");
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? "Novo Cliente" : "Detalhes do cliente";
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Clientes",
                disabled: false,
                to: "/clients",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? "Incluir cliente" : "Detalhes do cliente",
                disabled: true,
                exact: true,
            });
        },

        onLogoSelected(file) {
            this.clientForm.files.logo = file;
        },

        onBackgroundSelected(file) {
            this.clientForm.files.background = file;
        },

        changeType(value) {
            if (value === 3) {
                this.dataset.data.ie = null;
                this.dataset.data.im = null;
                this.dataset.data.tradeName = null;

                setTimeout(() => {
                    this.dataset.data.registrationNumber = null;
                }, 200);
            }

        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/clients/${this.$route.params.id}`);

                this.dataset.data = result.data.data;

                this.page.title = this.dataset.data.name
            } catch (error) {
                console.log('error ', error)
            }
        },

        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o cliente?',
                title: 'Incluir cliente',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.clientForm.addLoading = true;

            const payload = this.buidClientPayload();

            try {
                const response = await axios.post('/clients', payload);

                this.$dialog.notify.info("Cliente salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.clientForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o cliente?',
                title: 'Atualizar cliente',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.clientForm.addLoading = true;

            const payload = this.buidClientPayload();

            try {
                const response = await axios.patch(`/clients/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Cliente salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.clientForm.addLoading = false;
            }
        },

        buidClientPayload() {
            const data = new FormData();
            if (this.dataset.data.name) {
                data.append("name", this.dataset.data.name);
            }

            if (this.dataset.data.registrationNumber) {
                data.append(
                    "registrationNumber",
                    string.sanitizeCpfCnpj(this.dataset.data.registrationNumber)
                );
            }

            if (this.dataset.data.status) {
                data.append("status", this.dataset.data.status);
            }

            if (this.dataset.data.tradeName) {
                data.append("tradeName", this.dataset.data.tradeName);
            }

            if (this.dataset.data.ie) {
                data.append("ie", this.dataset.data.ie);
            }

            if (this.dataset.data.im) {
                data.append("im", this.dataset.data.im);
            }

            if (this.dataset.data.type) {
                data.append("type", this.dataset.data.type);
            }

            if (this.dataset.data.description) {
                data.append("description", this.dataset.data.description);
            }

            if (this.dataset.data.contactName) {
                data.append("contactName", this.dataset.data.contactName);
            }

            if (this.dataset.data.contactPhone) {
                data.append(
                    "contactPhone",
                    string.sanitizePhone(this.dataset.data.contactPhone)
                );
            }

            if (this.dataset.data.contactEmail) {
                data.append("contactEmail", this.dataset.data.contactEmail);
            }

            if (this.dataset.data.addressStreet) {
                data.append("addressStreet", this.dataset.data.addressStreet);
            }

            if (this.dataset.data.addressNumber) {
                data.append("addressNumber", this.dataset.data.addressNumber);
            }

            if (this.dataset.data.addressStreet2) {
                data.append("addressStreet2", this.dataset.data.addressStreet2);
            }

            if (this.dataset.data.addressZipCode) {
                data.append("addressZipCode", this.dataset.data.addressZipCode);
            }

            if (this.dataset.data.addressDistrict) {
                data.append("addressDistrict", this.dataset.data.addressDistrict);
            }

            if (this.dataset.data.addressCity) {
                data.append("addressCity", this.dataset.data.addressCity);
            }

            if (this.dataset.data.addressState) {
                data.append("addressState", this.dataset.data.addressState);
            }

            if (this.dataset.data.addressComplement) {
                data.append("addressComplement", this.dataset.data.addressComplement);
            }

            if (this.dataset.data.themeLightPrimaryColor) {
                data.append("themeLightPrimaryColor", this.dataset.data.themeLightPrimaryColor);
            }

            if (this.dataset.data.themeLightSecondaryColor) {
                data.append(
                    "themeLightSecondaryColor",
                    this.dataset.data.themeLightSecondaryColor
                );
            }

            if (this.dataset.data.themeDarkPrimaryColor) {
                data.append("themeDarkPrimaryColor", this.dataset.data.themeDarkPrimaryColor);
            }

            if (this.dataset.data.themeDarkSecondaryColor) {
                data.append(
                    "themeDarkSecondaryColor",
                    this.dataset.data.themeDarkSecondaryColor
                );
            }

            if (this.dataset.data.themeSlogan) {
                data.append("themeSlogan", this.dataset.data.themeSlogan);
            }

            return data;
        },

        isClient() {
            const { client } = user.identify();
            return !!client;
        }


        // previewImage(fileId, type) {
        //     let loaderProperty;
        //     if (type === "background") loaderProperty = "previewBackgroundLoading";
        //     if (type === "logo") loaderProperty = "previewLogoLoading";

        //     this.clientForm[loaderProperty] = true;

        //     clientApi
        //         .getFile(this.clientId, fileId)
        //         .then((response) => {
        //             this.clientForm[loaderProperty] = false;

        //             download.saveFile(response);
        //         })
        //         .catch(() => (this.clientForm[loaderProperty] = false));
        // },

    },
};
</script>
  
<style lang="scss">

.theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
    color: #222D6C !important;
    font-weight: 500 !important;
}

.card-group-title {
    text-transform: capitalize;
    font-weight: normal;
    font-size: 22px;
    color: #2c3153;
}

.theme-card {

    .card-section-title {
        color: var(--v-mediumGray-base) !important;
        text-transform: capitalize !important;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    background: var(--v-gray-base) !important;
}

.color-row {
    display: flex;
    flex-direction: row;

    .color-column {
        padding-right: 24px;

        .color-picker {
            height: 55px;
            width: 55px;
        }
    }

    .description-column {
        display: flex;
        flex-direction: column;

        .color-title {
            color: #2c3153;
            font-size: 14px;
            font-weight: 700;
        }
    }
}

input[type="color"] {
    height: 32px;
    width: 32px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    cursor: pointer;
}

.v-text-field__prefix {
    padding-right: 0px !important;
}

.link-icon {
    cursor: pointer;
}
</style>