
<template>
    <div>
      <HeaderMobile />
      
      <div class="box-1">
        <v-row align="center" justify="center">
          <v-col>
            <span class="title-gateway">Gateway</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field id="plate" placeholder="Número do Gateway" v-on:keyup="handleClearGateway" append-icon-color="primary"
              v-model="dataset.filter.gateway" hide-details outlined>
              <template v-slot:append-outer>
                <v-btn @click="selectGateway" style="margin-top: -17px;" :height="56" color="secondary" elevation="0">OK</v-btn>
              </template>
            </v-text-field>
            <span class="example-text">Exemplo: b0fd0b7004730000</span>
          </v-col>
        </v-row>
        <v-row v-if="dataset.gatewaySelected">
          <v-col>
            <v-card v-if="!dataset.loading" class="gateway-card px-4" outlined>
              <v-card-text>
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col cols="1">
                    <v-icon size="30" color="important">mdi-broadcast</v-icon>
                  </v-col>
                  <v-col cols="8">
                    <div class="gateway-card-title">
                      <span>{{ dataset.gatewaySelected }}</span>
                    </div>
                    <div class="gateway-card-subtitle">gateway digitado</div>
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon @click="removeGateway">
                      <v-icon>mdi-delete-forever-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
  
      <v-row class="button-container" align="center" justify="center">
        <v-col cols="12">
          <v-btn 
            @click="nextStep()" 
            :height="56" 
            color="secondary" 
            elevation="0"
          >
            {{ 'Aplicar' }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn @click="goBack" :height="56" color="secondary" outlined elevation="0">Cancelar</v-btn>
        </v-col>
      </v-row>

 
    </div>
  </template>


  <script>
  import Vue from "vue";
  import _, { remove } from 'lodash';
  import axios from 'axios';
  import VueLodash from 'vue-lodash'
  import object from "@/common/util/object"
  import HeaderMobile from "@/layouts/components/HeaderMobile";

  import vehicleIcons from "@/common/data/vehicle-icons.data"
  
  Vue.use(VueLodash)

  export default {
    name: 'ChangeGateway',
    data() {
      return {
        vehicles: [],   
        dataset: {
          filter: {
            gateway: '',
          },
          loading: false,
          notGateway: false,
          filteredVehicles: [],
          gatewaySelected: null,
          vehicleIcons: vehicleIcons,
          infoNotGateway: 'Gateway não encontrado',
        },
        sheet: true,
      };
    },
    created() {
      this.dataset.filter.gateway = localStorage.getItem('gateway');
      this.selectGateway();
    },
    components: {
      HeaderMobile
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        
        async selectGateway() {            
          if(!this.dataset.filter.gateway) {
              return;
          }

          localStorage.setItem('gateway', this.dataset.filter.gateway);
          this.dataset.gatewaySelected = this.dataset.filter.gateway;

          this.$emit('update-filter-gateway', this.dataset.filter.gateway);
      },

        removeGateway() {
            // this.dataset.filter.info = null;
            // this.dataset.filter.serial = null;
            this.dataset.filter.gateway = null;
            this.dataset.gatewaySelected = null;
        },

        handleClearGateway() {
            if(this.dataset.filter.gateway == '') {
            this.removeGateway();
            this.dataset.notGateway = false;
            }
        },
    
        nextStep() {
            if(!this.dataset.gatewaySelected) {
                this.$dialog.notify.info('Informe o número do Gateway', { position: 'top-right', timeout: 5000 });
                return;
            }
                
            if(this.dataset.filter.gateway){
             localStorage.setItem('gateway', this.dataset.filter.gateway);
            }

            this.$router.push({ path: '/tracking/3' });
        },
    }
  }
  </script>
  
  <style lang="scss">
  .box-1, .box-2 {
    padding: 40px;
    min-height: 600px;
  }
  
  .title-gateway{
    position: relative;
    top: 10px;
    color: var(--v-primary-base);
    font-family: Montserrat !important;
    font-size: 45px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    text-transform: none;
  }
  
  .example-text {
    display: block;
    font-size: 12px;
    color: gray;
  }
  
  .custom-bottom-sheet {
    padding: 40px;
    font-family: Montserrat !important;
  }
  
  .headline {
    font-weight: 600 !important;
    text-transform: none !important;
    font-family: Montserrat !important;
    color: var(--v-primary-base);
  }
  
  .gateway-card {
    margin-top: -5px !important;

    .v-card__text {
      padding: 20px 5px !important;
    }
    .gateway-card-title {
      color: var(--v-primary-base);
      font-family: Montserrat;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
    }
  
    .gateway-card-subtitle {
      color: var(--v-mediumGray-base);
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      margin-top: -5px;
      font-weight: 500;
    }
  
    transition: ease-in-out 0.4s;
  }

  .vehicle-card {
    .v-card__text {
      padding: 20px 5px !important;
    }
    .vehicle-card-title {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .vehicle-card-subtitle {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }
    transition: ease-in-out 0.4s;
}

  
  .button-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    margin: 0 !important;
    transform: translateX(-50%);
    width: 100%;
    padding: 30px;
    background-color: white;
  }

  .button-container .v-btn {
    width: 100%;
  }

  .info-not-gateway svg{
    margin-right: 10px;
  }
  </style>