<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Gestão</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Equipamentos',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn :disabled="!$check('equipments/', ['POST'])" id="btnNew" type="button" color="secondary"
                            elevation="0" to="/equipments/novo">Incluir</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field id="serial" label="Serial" v-model="dataList.filter.serial" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select id="model" :items="dataset.models" label="Modelo"
                                        v-model="dataList.filter.model" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select id="type" :items="dataset.modelTypes" label="Tipo"
                                        v-model="dataList.filter.type" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select id="active" :items="dataset.status" label="Status"
                                        v-model="dataList.filter.active" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-select id="client" :items="dataset.clients" label="Cliente"
                                        v-model="dataList.filter.client" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select id="technician" :items="dataset.technicians" label="Técnico"
                                        v-model="dataList.filter.technician" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select id="stage" :items="dataset.equipmentStages" label="Estágio"
                                        v-model="dataList.filter.stage" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field id="iccid" label="ICCID" v-model="dataList.filter.iccid" hide-details
                                        outlined></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2"
                                        large>Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title">Equipamentos</span>
                            </div>
                            <div class="button-group">
                                <v-btn disabled id="btnNew" type="button" color="secondary" small elevation="0"
                                    @click="viewItem(null)">Exportar CSV</v-btn>
                                <v-btn disabled id="btnNew" type="button" color="primary" small elevation="0"
                                    @click="viewItem(null)">Editar em lote</v-btn>
                                <v-btn disabled id="btnNew" type="button" color="error" small elevation="0"
                                    @click="viewItem(null)">Excluir em lote</v-btn>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum equipamento foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">

                            <template v-slot:[`item.serial`]="{ item }">
                                <span class="list-item-title">{{ item.serial }}</span><br />
                                <span class="list-item-subtitle">{{ item.iccid || '-' }}</span>
                            </template>

                            <template v-slot:[`item.model`]="{ item }">
                                <span class="list-item-title">{{ item.model.name }}</span><br />
                                <span class="list-item-subtitle">{{ item.model.type | enum(dataset.modelTypes, "id", "name")
                                }}</span>
                            </template>

                            <template v-slot:[`item.stage`]="{ item }">
                                <span class="list-item-title">{{ item.stage | enum(dataset.equipmentStages, "id", "name")
                                }}</span><br />
                                <span class="list-item-subtitle">{{ item.technician || "-" }}</span>
                            </template>

                            <template v-slot:[`item.client`]="{ item }">
                                <span class="list-item-title">{{ item.client ? item.client.name : '-' }}</span><br />
                                <span v-if="item.vehicle" class="list-item-subtitle">{{ item.vehicle.type |
                                                                    enum(dataset.vehicleTypes, "id",
                                    "name") }} - {{ item.vehicle.plate.toUpperCase() }}</span>
                                <span v-else class="list-item-subtitle">-</span>
                            </template>

                            <template v-slot:[`item.date`]="{ item }">
                                <span class="list-item-title">{{ $moment(item.date).format('DD.MM.YYYY') }}</span><br />
                                <span class="list-item-subtitle">{{ $moment(item.date).format('HH:MM:ss') }}</span>
                            </template>

                            <!-- <template v-slot:[`item.active`]="{ item }">
                                <v-chip label text-color="primary">
                                    {{ item.active | enum(dataset.active, "id", "name") }}
                                </v-chip>
                            </template> -->

                            <template v-slot:[`item.active`]="{ item }">
                                <v-chip label :text-color="getTextColor(item.active)" :color="getStatusColor(item.active)">
                                    {{ item.active | enum(dataset.active, "id", "name") }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon @click="viewItem(item)" size="large" class="me-2">
                                    mdi-truck-delivery-outline
                                </v-icon>
                                <v-icon @click="viewItem(item)" size="large" class="me-2">
                                    mdi-qrcode
                                </v-icon>
                                <v-icon :disabled="!$check('equipments/1', ['PATCH'])" @click="viewItem(item)" size="large" class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                                <v-icon :disabled="!$check('equipments/1', ['DELETE'])" @click="remove(item)" size="large">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import modelTypes from "@/common/data/model-types.data"
import equipmentStages from "@/common/data/equipment-stages.data"
import vehicleTypes from "@/common/data/vehicle-types.data"
import active from "@/common/data/status.data";
import object from "@/common/util/object"

Vue.use(VueLodash)

export default {
    name: "ModelList",

    data: () => ({
        dataset: {
            modelTypes: [{ id: "", name: "Todos:" }, ...modelTypes],
            equipmentStages: [{ id: "", name: "Todos:" }, ...equipmentStages],
            active: [{ id: "", name: "Todos:" }, ...active],
            vehicleTypes: vehicleTypes,
            models: [],
            clients: [],
        },
        dataList: {
            filter: {
                type: "",
                model: "",
                stage: "",
                client: "",
                technician: "",
                active: ""
            },
            headers: [
                { text: "Serial/ICCID", value: "serial" },
                { text: "Modelo/Tipo", value: "model" },
                { text: "Estágio/Técnico", value: "stage" },
                { text: "Data", value: "date" },
                { text: "Cliente/Veículo", value: "client" },
                { text: "Status", value: "active" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
        this.loadClients();
        this.loadModels();
    },

    methods: {
        clientPageText() {
            return `${this.dataList.paging.page} de ${this.dataList.paging.pages}`;
        },

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.load();
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/equipments', { params: { start: start, limit: limit, search: search } });
                
                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async loadModels() {
            try {
                const result = await axios.get('/models', { params: { start: 0, limit: 1000000 } });

                this.dataset.models = [{ id: "", name: "Todos:" }, ...result.data.data];
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadClients() {
            try {
                const result = await axios.get('/clients', { params: { start: 0, limit: 1000000 } });

                this.dataset.clients = [{ id: "", name: "Todos:" }, ...result.data.data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async remove(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja excluir o equipamento?',
                title: 'Excluir equipamento',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                await axios.delete(`/equipments/${item.id}`)

                this.$dialog.notify.info("Equipamento excluído com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`equipments/${item.id}`);
        },

        getStatusColor(status) {
            return status === 1 ? '#222D6C12' : '#FB3A0412';
        },

        getTextColor(status) {
            return status === 1 ? '#222D6C' : '#FB3A04';
        }
    },
};
</script>