<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Usuários</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Usuários',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn :disabled="!$check('users/', ['POST'])" id="btnNew" type="button" color="secondary"
                            elevation="0" to="/users/novo">Incluir</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field id="name" label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select :items="dataset.clients" label="Cliente" v-model="dataList.filter.client"
                                        item-value="id" item-text="name" v-on:change="changeClient" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select :items="dataset.units" label="Unidades" v-model="dataList.filter.unit"
                                        item-value="id" item-text="name" outlined hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select :items="dataset.status" label="Status" v-model="dataList.filter.status"
                                        item-value="id" item-text="name" outlined hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2"
                                        large>Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Usuários</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum usuário foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">
                            <template v-slot:[`item.name`]="{ item }">
                                <v-avatar :size="30">
                                    <img :src="'https://ui-avatars.com/api/?name=' + item.name" />
                                </v-avatar>
                                <span class="ml-4 list-item-title">{{ item.name }}</span><br />
                            </template>

                            <template v-slot:[`item.client`]="{ item }">
                                <span class="list-item-title">{{ item.client ? item.client.name : "-" }}</span><br />
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip label :text-color="getTextColor(item.status)" :color="getStatusColor(item.status)">
                                    {{ item.status | enum(dataset.status, "id", "name") }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon :disabled="!$check('users/1', ['PATCH'])" @click="viewItem(item)" size="large"
                                    class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                                <v-icon :disabled="!$check('users/1', ['DELETE'])" @click="remove(item)" size="large">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"
import status from "@/common/data/user-status.data";

Vue.use(VueLodash)

export default {
    name: "UserList",

    data: () => ({
        dataset: {
            status: [{ id: "", name: "Todos" }, ...status],
            clients: [],
            units: [],
            data: {}
        },
        dataList: {
            filter: {
                name: "",
                client: "",
                unit: "",
                status: ""
            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "Cliente", value: "client" },
                { text: "E-mail", value: "email" },
                { text: "Status", value: "status" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() { 
        this.load();
        this.loadClients();
    },

    methods: {
        clientPageText() {
            return `${this.dataList.paging.page} de ${this.dataList.paging.pages}`;
        },

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.load();
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        changeClient(client) {
            this.dataset.data.unit = null;
            this.dataset.units = [];

            if (client) this.loadUnits(client);
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/users', { params: { start: start, limit: limit, search: search } });

                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async loadUnits(client) {
            try {
                const result = await axios.get('/units', { params: { start: 0, limit: 1000000, client } });

                this.dataset.units = [{ id: "", name: "Todos" }, ...result.data.data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadClients() {
            try {
                const result = await axios.get('/clients', { params: { start: 0, limit: 1000000 } });

                this.dataset.clients = [{ id: "", name: "Todos" }, ...result.data.data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async remove(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja excluir o usuário?',
                title: 'Excluir usuário',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                const result = await axios.delete(`/users/${item.id}`)

                this.$dialog.notify.info("Usuário excluído com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`users/${item.id}`);
        },

        getStatusColor(status) {
            return status === 0 ? '#222D6C12' : '#FB3A0412';
        },

        getTextColor(status) {
            return status === 0 ? '#222D6C' : '#FB3A04';
        },
    },
};
</script>