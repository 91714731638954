<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›"
                            :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn disabled v-if="!page.isNew" type="button" elevation="0" color="primary">Chamar
                            Técnico</v-btn>
                        <v-btn type="button" elevation="0" color="secondary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn type="button" elevation="0" color="secondary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>

                        <v-btn v-if="!page.isNew && equipmentModel == 5 || equipmentModel == 4" id="btnBack" type="button" elevation="0"
                        :color="ColorUtils.setColorButton(equipmentModel, equipmentStatus)" @click="handleModal(dataset.data)" >{{setInfoButton(equipmentModel, equipmentStatus)}}</v-btn>

                        <v-btn type="button" elevation="0" color="primary" outlined to="/equipments">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="button-group">
                        <v-btn :color="dataset.tab === 1 ? 'secondary' : 'mediumGray'" outlined
                            @click="dataset.tab = 1">
                            Detalhes

                            <v-icon right>mdi-text-long</v-icon>

                        </v-btn>
                        <v-btn v-if="!page.isNew" outlined @click="dataset.tab = 2"
                            :color="dataset.tab === 2 ? 'secondary' : 'mediumGray'">
                            Instruções
                            <v-icon right>mdi-message-arrow-right-outline</v-icon>
                        </v-btn>
                        <v-btn v-if="!page.isNew" outlined @click="dataset.tab = 3"
                            :color="dataset.tab === 3 ? 'secondary' : 'mediumGray'">
                            Conexões recentes
                            <v-icon right>mdi-timeline-clock-outline</v-icon>
                        </v-btn>

                        <v-btn v-if="!page.isNew" outlined @click="dataset.tab = 4"
                            :color="dataset.tab === 4 ? 'secondary' : 'mediumGray'">
                            Eventos
                            <v-icon right>mdi-database-sync </v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-form v-if="dataset.tab === 1" ref="form" v-model="dataForm.validForm" class="mt-7">
            <v-row>
                <v-col cols="12" sm="8">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Informações</v-card-title>
                                <v-card-text v-if="dataset.data != null">
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-select id="model" :items="dataset.models" label="Modelo" required
                                                v-on:change="changeModel" :rules="[$rules.required]"
                                                v-model="dataset.data.model" item-value="id" item-text="name" outlined
                                                hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="serial" label="Serial" v-model="dataset.data.serial"
                                                required :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="4">
                                            <v-select id="type" :items="dataset.modelTypes" label="Tipo" required
                                                readonly disabled :rules="[$rules.required]" v-model="dataset.modelType"
                                                item-value="id" item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-select id="active" :items="dataset.active" label="Status" required
                                                :rules="[$rules.required]" v-model="dataset.data.active" item-value="id"
                                                item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select id="stage" :items="dataset.equipmentStages" label="Estágio"
                                                required :rules="[$rules.required]" v-model="dataset.data.stage"
                                                item-value="id" item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>

                                        <v-col cols="12" sm="4">
                                            <v-select id="technician" :items="dataset.technicians" label="Técnico"
                                                v-model="dataset.data.technician" item-value="id" item-text="name"
                                                outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="this.dataset.modelType === 4">
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="eui" label="EUI" v-model="dataset.data.eui"
                                                outlined hide-details="auto" v-mask="$masks.eui" required :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="4">
                                            <v-text-field id="iccid" label="ICCID" v-model="dataset.data.iccid"
                                                outlined hide-details="auto" v-mask="$masks.iccid" required :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="4">
                                            <v-text-field id="mac" label="MAC" v-model="dataset.data.macAddress"
                                                outlined hide-details="auto" v-mask="$masks.mac" required
                                                :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="this.dataset.modelType === 5">
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="eui" label="EUI" v-model="dataset.data.eui"
                                                outlined hide-details="auto" v-mask="$masks.eui" required :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="firmware" label="Versão Firmware"
                                                v-model="dataset.data.firmware" readonly outlined hide-details="auto" disabled>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="hardware" label="Versão Hardware"
                                                v-model="dataset.data.hardware" readonly outlined hide-details="auto" disabled>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="this.dataset.modelType === 4">
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="firmware" label="Versão Firmware"
                                                v-model="dataset.data.firmware" readonly disabled outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="hardware" label="Versão Hardware"
                                                v-model="dataset.data.hardware" readonly disabled outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select id="origin" :items="dataset.originTypes"
                                                v-model="dataset.data.origin" label="Origem cadastro" item-value="id"
                                                item-text="name" outlined  hide-details="auto" required
                                                :rules="[$rules.required]">
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="this.dataset.modelType === 4">
                                        <v-col cols="12" sm="12">
                                            <v-textarea id="description" label="Dados Adicionais"
                                                v-model="dataset.data.description" outlined hide-details="auto" rows="1"
                                                auto-grow>
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="this.dataset.modelType === 5">
                                        <v-col cols="12" sm="4">
                                            <v-select id="origin" :items="dataset.originTypes"
                                                v-model="dataset.data.origin" label="Origem cadastro" item-value="id"
                                                item-text="name" outlined hide-details="auto" required
                                                :rules="[$rules.required]">
                                            </v-select>
                                        </v-col>

                                        <v-col cols="12" sm="8">
                                            <v-textarea id="description" label="Dados Adicionais"
                                                v-model="dataset.data.description" outlined hide-details="auto" rows="1"
                                                auto-grows>
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="this.dataset.modelType != 5 && this.dataset.modelType != 4 && this.dataset.modelType != null">
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="mac" label="MAC" v-model="dataset.data.macAddress"
                                                required :rules="[$rules.required]" v-mask="$masks.mac" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="4">
                                            <v-select id="origin" :items="dataset.originTypes"
                                                v-model="dataset.data.origin" label="Origem cadastro" item-value="id"
                                                item-text="name" outlined hide-details="auto" required
                                                :rules="[$rules.required]">
                                            </v-select>
                                        </v-col>

                                        <v-col cols="12" sm="4">
                                            <v-textarea id="description" label="Dados Adicionais"
                                                v-model="dataset.data.description" outlined hide-details="auto" rows="1"
                                                auto-grow>
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col>
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Cliente</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-select id="client" :items="dataset.clients" label="Cliente"
                                                v-on:change="changeClient" v-model="dataset.data.client" item-value="id"
                                                item-text="name" outlined hide-details="auto" clearable>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select id="unit" :items="dataset.units" label="Unidade"
                                                v-model="dataset.data.unit" item-value="id" item-text="name" outlined
                                                hide-details="auto" clearable>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">
                                    <div class="title-container">
                                        <span class="table-card-title">Veículo</span>
                                    </div>
                                </v-card-title>
                                <v-card-text class="d-flex flex-row">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="6">
                                            <vehicle-associated v-if="dataset.data.vehicle"
                                                :vehicle="dataset.data.vehicle"></vehicle-associated>
                                            <span v-else>Nenhum veículo associado</span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card color="theme-card">
                                <v-card-title class="card-group-title">Histórico</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <event-table 
                                            :history="history" 
                                            @update-history-selected="updateHistorySelected"
                                            @show-history-modal="showHistroyModal"
                                        ></event-table>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-row v-if="dataset.tab === 1">
            <v-col cols="12">
                <v-card class="card-form-outlined">
                    <v-card-title class="card-group-title">
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title">Periféricos</span>
                            </div>
                            <div class="button-group">
                                <v-btn id="btnNew" type="button" color="secondary" small elevation="0"
                                    @click="openEquipmentSelectionModal()">Associar periférico</v-btn>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-card class="peripheral-container">
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <span>Periféricos Associados</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-chip-group>
                                                    <v-chip v-for="(item, index) in dataset.data.peripherals"
                                                        :key="index" class="custom-chip" color="gray" label close
                                                        close-icon="mdi-close" @click:close="removeAssociation(item)">
                                                        {{ item.model.type | enum(dataset.modelTypes, "id", "name") }} -
                                                        {{
                                                            item.serial }}
                                                    </v-chip>
                                                </v-chip-group>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <instruction-list v-if="dataset.tab === 2" :equipment="dataset.data"></instruction-list>
        <connection-list v-if="dataset.tab === 3"></connection-list>
        <event-list v-if="dataset.tab === 4" :equipment="dataset.data"></event-list>

        <equipment-selection ref="equipmentModal" :type="2" :client="clientId" :entity="dataset.data.id"
            :selectedEquipments="selectedPeripherals"></equipment-selection>


        <!-- Modal para bloqueio -->
        <modal-block :equipment="dataset.data"></modal-block>
        <!-- Modal para dormir -->
        <modal-sleep :equipment="dataset.data"></modal-sleep>
        <!-- Modal para acordar -->
        <modal-wakeup :equipment="dataset.data"></modal-wakeup>
        <!-- Modal para desbloqueio -->
        <modal-unblock :equipment="dataset.data"></modal-unblock>

        <modal-history :history="dataset.historySelected" />
    </v-container>
</template>

<script>
import { eventHub } from '@/main';
import modelTypes from "@/common/data/model-types.data";

import equipmentStages from "@/common/data/equipment-stages.data"
import originTypes from "@/common/data/origin-types.data"
import active from "@/common/data/status.data"
import string from "@/common/util/string"

import ModalBlock from "@/views/equipment/components/ModalBlock"
import ModalSleep from "@/views/equipment/components/ModalSleep"
import ModalUnblock from "@/views/equipment/components/ModalUnblock"
import ModalWakeup from "@/views/equipment/components/ModalWakeup"
import ModalHistroy from "@/components/event/components/ModalHistory.vue"

import EventTable from "@/components/event/EventTable"
import VehicleAssociated from "@/components/vehicle/VehicleAssociated"
import EquipmentSelection from "@/components/equipment/EquipmentSelection"
import InstructionList from "@/views/equipment/instruction/InstructionList"
import ConnectionList from "@/views/equipment/connection/ConnectionList"
import EventList from "@/views/equipment/event/EventList"

import axios from "axios"

import ColorUtils from "@/common/util/ColorUtils";

export default {
    name: "ModelDetails",

    components: {
        'modal-history': ModalHistroy,
        'modal-block': ModalBlock,
        'modal-sleep': ModalSleep,
        'modal-unblock': ModalUnblock,
        'modal-wakeup': ModalWakeup,
        'event-table': EventTable,
        'vehicle-associated': VehicleAssociated,
        'equipment-selection': EquipmentSelection,
        'instruction-list': InstructionList,
        'connection-list': ConnectionList,
        'event-list': EventList
   },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        ColorUtils,
        dataset: {
            historySelected: {},
            breadcrumbItems: [],
            modelTypes: modelTypes,
            equipmentStages: equipmentStages,
            originTypes: originTypes,
            active: active,
            models: [],
            
            modelType: null,
            clients: [],
            units: [],
            tab: 1,
            data: {
                client: null,
                unit: null,
            }
        },
        history: [],
        equipmentModel: null,
        equipmentStatus: null,
        clientId: undefined,
        selectedPeripherals: [],
        dataForm: {
            files: {},
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            created: null,
        },
    }),

    created() {
        this.setPage();
        this.listBreadCrumbItems();
        this.loadData();


        if(!this.page.isNew) {
            this.listHistory();
        }

        eventHub.$emit("EQUIPMENT-BLOCK-CHANGE", false);
        eventHub.$emit("EQUIPMENT-SLEEP-CHANGE", false);
        eventHub.$emit("EQUIPMENT-WAKEUP-CHANGE", false);
        eventHub.$emit("EQUIPMENT-UNBLOCK-CHANGE", false);
    },
    

    methods: {
        goBack() {
            this.$router.replace("/equipments");
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? "Novo Equipamento" : "Detalhes";
        },

        
        updateHistorySelected(history){
            this.dataset.historySelected = history;
        },

        showHistroyModal() {
            eventHub.$emit("HISTORY-DETAIL", true);
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Equipamentos",
                disabled: false,
                to: "/equipments",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? "Incluir equipamento" : "Detalhes do equipamento",
                disabled: true,
                exact: true,
            });
        },

        changeClient(client) {
            this.dataset.data.unit = null;
            this.dataset.units = [];

            if (client) {
                this.clientId = client;
                this.loadUnits(client);
            } 
        },

        changeModel(model) {

            if (!model) return;

            const omodel = this.dataset.models.find((m) => m.id === model);
            this.dataset.modelType = omodel.type
        },

        async listHistory() {
            try {
                const result = await axios.get(`/history`, { params: { start: 0, limit: 1000000, entity: this.$route.params.id, entityType: 2 } });
                this.history = result.data.data;
            } catch (error) {
                console.log('error ', error)
            }
        },

        async loadData() {
            await this.load();
            await this.loadModels();
            await this.loadClients();

            if (!this.page.isNew) {
                this.changeModel(this.dataset.data.model.id)

                this.clientId = this.dataset.data.client;
                await this.loadUnits(this.dataset.data.client);
            }
        },


        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/equipments/${this.$route.params.id}`);
                
                if(result.data.data){
                    this.dataset.data = result.data.data;

                    if(this.dataset.data.model.type == 4 || this.dataset.data.model.type == 5){
                        this.equipmentStatus = this.dataset.data.status;
                        this.equipmentModel = this.dataset.data.model.type;

                    }

                }
            } catch (error) {
                console.log('error ', error)
            }
        },

        async loadUnits(client) {

            if(client) {
                try {
                const clientId = typeof client === 'object' ? client.id : client;
                const result = await axios.get('/units', { params: { start: 0, limit: 1000000, client: clientId } });

                this.dataset.units = result.data.data;
                
            } catch (error) {
                const errorMessage = this.$codes(error) || 'Ocorreu um erro ao carregar as unidades.';
                this.$dialog.notify.error(errorMessage, { position: 'top-right', timeout: 5000 });
            }
            }

        },
        
        async loadClients() {
            try {
                const result = await axios.get('/clients', { params: { start: 0, limit: 1000000 } });
                    
                if(result.data.data){
                    this.dataset.clients = result.data.data;
                }
            
                if (this.dataset.data.client != null) this.loadUnits(this.dataset.data.client);

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadModels() {
            try {
                const result = await axios.get('/models', { params: { start: 0, limit: 1000000 } });
                this.dataset.models = result.data.data;
            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o equipamento?',
                title: 'Incluir equipamento',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/equipments', payload);

                this.$dialog.notify.info("Equipamento salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o equipamento?',
                title: 'Atualizar equipamento',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/equipments/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Equipamento salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        buildPayload() {
            const data = {
                serial: this.dataset.data.serial,
                model: this.dataset.data.model.id || this.dataset.data.model,
                active: this.dataset.data.active,
                stage: this.dataset.data.stage,
                status: 4,
                technician: this.dataset.data.technician,
                iccid: this.dataset.data.iccid,
                eui: this.dataset.data.eui,
                firmware: this.dataset.data.firmware,
                hardware: this.dataset.data.hardware,
                macAddress: this.dataset.data.macAddress,
                origin: this.dataset.data.origin,
                description: this.dataset.data.description,
                client: this.dataset.data.client,
                unit: this.dataset.data.unit,
                peripherals: (this.dataset.data.peripherals || []).map((x) => x.id)
            };

            return data;
        },

        removeAssociation(item) {
            const verifySelectedPeripheral = this.selectedPeripherals.findIndex((x) => x.id === item.id);

            if (verifySelectedPeripheral !== -1) {
                this.selectedPeripherals.splice(verifySelectedPeripheral, 1)
            }

            this.dataset.data.peripherals.splice(this.dataset.data.peripherals.findIndex((x) => x.id === item.id), 1)
        },

        openEquipmentSelectionModal() {
            if (!this.clientId){
                this.$dialog.notify.info('Selecione o cliente', { position: 'top-right', timeout: 5000 });
                return;
            }
            this.$refs.equipmentModal.open().then((peripherals) => {
                const filterPeripherals = peripherals.filter((a) => {
                    return !this.dataset.data.peripherals.find((b) => a.id === b.id)
                })

                this.selectedPeripherals = [...this.selectedPeripherals, ...filterPeripherals];

                this.dataset.data.peripherals = [...filterPeripherals, ...this.dataset.data.peripherals]
            });
        },

        handleModal(equipment) {

            if(equipment.status === 3 && item.equipment.model.type === 4) {
                eventHub.$emit("EQUIPMENT-UNBLOCK-CHANGE", true);
            }

            if( (equipment.status === 1 || equipment.status == 2 ) && equipment.model.type === 5) {
                eventHub.$emit("EQUIPMENT-SLEEP-CHANGE", true);
            }

            
            if( equipment.status === 4 && equipment.model.type === 5) {
                eventHub.$emit("EQUIPMENT-WAKEUP-CHANGE", true);

            }

            if( ( equipment.status === 1 || equipment.status == 2 ) && equipment.model.type === 4) {
                eventHub.$emit("EQUIPMENT-BLOCK-CHANGE", true);
            }
        },

        setInfoButton(type, status) {
            if(type === 5) {
                switch (status) {
                case 1:
                    return 'Dormir';
                case 2:
                    return 'Dormir';
                case 3:
                    return 'Dormir';
                case 5:
                    return 'Acordando...';
                case 6:
                    return 'Aguardando...';
                default:
                    return 'Acordar';
                }
            }

            if(type === 4) {
                switch (status) {
                case 1:
                    return 'Bloquear';
                case 2:
                    return 'Bloquear';
                case 3:
                    return 'Desbloquear';
                case 8:
                    return 'Desbloqueando';
                case 7:
                    return 'Bloqueando';
                default:
                    return 'Bloquear';
            }
            }
        },

    },
};
</script>
  
<style lang="scss">

.theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
    color: #222D6C !important;
    font-weight: 500 !important;
}
</style>
