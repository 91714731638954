const data = [
  { id: 1, name: 'Moto', icon: 'motorcycle-icon', assets: 'motorcycle.svg' },
  { id: 2, name: 'Compacto', icon: 'hatch-icon', assets: 'hatch.svg' },
  { id: 3, name: 'Carro', icon: 'car-icon', assets: 'car.svg' },
  { id: 4, name: 'Caminhonete', icon: 'pickup-icon', assets: 'pickup.svg' },
  { id: 5, name: 'Van e Micro', icon: 'van-icon', assets: 'van.svg' },
  { id: 6, name: 'Ônibus', icon: 'bus-icon', assets: 'bus.svg' },
  { id: 7, name: 'Caminhão', icon: 'truck-icon', assets: 'truck.svg' }
]

export default [...data]
