<template>
    <v-card class="card-infobox" :width="350" color="white">
        <v-card-text>
            <div>
                <span :class="ColorUtils.setClassColorText(position.equipment.model.type, position.equipment.status)" >
                    {{ position.prefix }} - {{ position.plate }}
                </span>
                <br />
                <span class="desc-label">Prefixo - Placa</span>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ address ? address : '...' }}</span>
                        <br />
                        <span class="desc-label">Endereço</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ position.accurate ? formatCoordinates(position.lat, position.lng) :  `Gateway ${position.gateway}` }}</span>
                        <br />
                        <span class="desc-label">{{ position.accurate ? 'Posição Precisa' : 'Posição Imprecisa' }}</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ formatDate(position.date) }}</span>
                        <br />
                        <span class="desc-label">Data</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">{{ formatHour(position.date) }}</span>
                        <br />
                        <span class="desc-label">Horário</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ position.equipment.serial }}</span>
                        <br />
                        <span class="desc-label">Dispositivo</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">{{ position.battery }}mV</span>
                        <br />
                        <span class="desc-label">Bateria</span>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>

import Vue from "vue";
import moment from 'moment';
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import ColorUtils from "@/common/util/ColorUtils";

import vehicleBrands from "@/common/data/vehicle-brands.data";

Vue.use(VueLodash)

export default {
    name: "VehicleHistoryInfoBox",

    props: {
        position: null,
    },

    data: () => ({
        ColorUtils,
        address: null,
        dataset: {
            unlockDialog: false,
            vehicleBrands: vehicleBrands,
        },
    }),

    created() {
        if(this.position) {
            this.getAdrress(this.position.plate, this.position.lat, this.position.lng);
        }
    },

    methods: {
        unlock() {
            this.dataset.unlockDialog = true;
        },

        async getAdrress(plate, lat, lng) {
            const storedData = JSON.parse(localStorage.getItem('vehicleData')) || [];
            const existingEntry = storedData.find(entry => entry.lat === lat && entry.lng === lng);

            if (existingEntry) {
                this.address = existingEntry.address;
            } else {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            const address = results[0].formatted_address;
                            this.address = address;

                            const newEntry = { plate, lat, lng, address };
                            const updatedData = storedData.filter(entry => entry.plate !== plate);
                            updatedData.push(newEntry);
                            localStorage.setItem('vehicleData', JSON.stringify(updatedData));
                        } else {
                            console.log('Nenhum endereço encontrado');
                        }
                    } else {
                        console.error('Geocoder falhou devido a: ' + status);
                    }
                });
            }
        },

        formatCoordinates(lat, lng) {
            return `${lat.toFixed(5)}, ${lng.toFixed(5)}`;
        },
        
        formatDate(date) {
            if (!date) return '';
            return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
        },

        formatHour(date) {
            if (!date) return '';
            const d = new Date(date);
            return moment(d, 'YYYY-MM-DDThh:mm').format('HH:mm');
        }
    },
};
</script>

<style lang="scss">
.card-infobox {

    .title-label {
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        /* 380% */
    }

    .action-button {
        width: 100%;
    }

    .position-chip-text {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

    .position-info-text {
        color: var(--v-important-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

}
</style>