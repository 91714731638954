<template>
    <svg width="16" :style="{ transform: `rotate(${rotation}deg)`, width: width + 'px', height: height + 'px'  }" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="02-hatch-ok 1" clip-path="url(#clip0_849_186)">
            <g id="Camada_x0020_1">
                <g id="_1696232004688">
                    <path id="Vector"
                        d="M7.19193 15.3235L0.40403 9.59205C-0.121222 9.19404 -0.0404536 8.87567 -0.0404536 8.23886V3.94033C-0.0404536 3.50252 0.282838 2.7065 0.727282 2.5473L8.84844 0.0796553C9.33328 -0.0795487 9.53538 0.0398931 10.1414 0.517505L12.3637 2.46771C12.8485 2.86572 13.3737 3.90057 13.6161 4.57719C13.9798 5.69161 14.3434 6.806 15.6363 8.71645C15.9596 9.27366 15.9596 10.0299 15.9596 11.1841V13.3731C15.9596 13.8508 15.7171 14.1294 15.1111 14.3682L10.1818 15.8806C9.01007 16.1194 8.00001 16.0001 7.19193 15.3235Z"
                        :fill="color" />
                    <path id="Vector_2"
                        d="M1.53524 5.6517L5.57565 9.23379C6.06049 9.6716 6.06051 9.55224 5.8989 9.07462L4.96959 6.80589C4.88878 6.56708 4.68674 5.93033 4.04027 5.37311L1.69682 3.34322C1.29278 2.86561 0.605909 3.14428 0.686717 3.70149L0.888738 4.61683C0.929142 5.05465 1.1312 5.33329 1.53524 5.6517Z"
                        fill="white" />
                    <path id="Vector_3"
                        d="M13.6159 7.60192C14.0604 7.52232 14.222 7.32334 14.02 6.92533L12.8079 3.98009C12.525 3.34327 12.2018 2.90542 11.5957 2.34821L9.53519 0.557166C9.05034 0.119355 8.6867 0.0795738 8.12104 0.278579L1.21191 2.38805C1.69676 2.22885 1.97957 2.42781 2.3028 2.74622L4.48468 4.65669C4.92913 5.0945 5.33313 5.57213 5.65636 6.36815L6.74725 8.99496C6.90886 9.43277 7.07052 9.63185 7.59577 9.47265L13.6159 7.60192Z"
                        fill="white" />
                    <path id="Vector_4"
                        d="M9.00986 12.6965H9.29267C9.45428 12.6965 9.57548 12.8159 9.57548 12.9751V14.8458C9.57548 15.005 9.45428 15.1244 9.29267 15.1244H9.00986C8.84824 15.1244 8.72705 15.005 8.72705 14.8458V12.9751C8.72705 12.8159 8.84824 12.6965 9.00986 12.6965Z"
                        fill="white" />
                    <path id="Vector_5"
                        d="M15.6769 10.7065L15.7577 10.5871C15.8385 10.5075 15.8789 10.7463 15.8789 10.9055V12.8558C15.8789 13.015 15.7981 13.0946 15.7577 13.1742L15.6769 13.2538C15.5961 13.2936 15.5557 13.0946 15.5557 12.9354V11.0249C15.5557 10.8657 15.5961 10.7861 15.6769 10.7065Z"
                        fill="white" />
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_849_186">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
  
<script>
export default {
  name: 'HatchIcon',
  props: {
    rotation: {
      type: Number,
      default: 0
    },
    color: {
        type: String,
        default: '#222D6C'
      },
    width: {
      type: Number,
      default: 30
    },
    height: {
      type: Number,
      default: 30
    }
  }
}
</script>