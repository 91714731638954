<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Modelos</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Modelos',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn :disabled="!$check('models/', ['POST'])" id="btnNew" type="button" color="secondary" elevation="0" to="/models/novo">Incluir</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-text-field id="name" label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-select id="type" :items="dataset.modelTypes" label="Tipo" v-model="dataList.filter.type"
                                        item-value="id" item-text="name" outlined hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field id="partNumber" label="Part Number" v-model="dataList.filter.partNumber"
                                        hide-details outlined></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2"
                                        large>Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Modelos</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum modelo foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">
                            <template v-slot:[`item.type`]="{ item }">
                                <span>{{ item.type | enum(dataset.modelTypes, "id", "name") }}</span>
                            </template>

                            <template v-slot:[`item.type`]="{ item }">
                                <span>{{ item.type | enum(dataset.modelTypes, "id", "name") }}</span>
                            </template>

                            <template v-slot:[`item.equipments`]="{ item }">
                                <span>{{ item.equipments ? item.equipments.length : 0 }}</span>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon :disabled="!$check('models/1', ['PATCH'])" @click="viewItem(item)" size="large" class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                                <v-icon :disabled="!$check('models/1', ['DELETE'])" @click="remove(item)" size="large">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>

// import clientStatus from "@/common/data/client-status.data";
import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"
import modelTypes from "@/common/data/model-types.data";

Vue.use(VueLodash)

export default {
    name: "ModelList",

    data: () => ({
        dataset: {
            modelTypes: [{ id: "", name: "Todos: " }, ...modelTypes],
        },
        dataList: {
            filter: {
                name: "",
                registrationNumber: "",
                city: "",
                status: ""
            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "Tipo", value: "type" },
                { text: "Part Number", value: "partNumber" },
                { text: "Equip Associados", value: "equipments" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
    },

    methods: {
        clientPageText() {
            return `${this.dataList.paging.page} de ${this.dataList.paging.pages}`;
        },
        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.load();
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),
        reload: function () {
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/models', { params: { start: start, limit: limit, search: search } });

                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async remove(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja excluir o modelo?',
                title: 'Excluir modelo',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                await axios.delete(`/models/${item.id}`)

                this.$dialog.notify.info("Modelo excluído com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`models/${item.id}`);
        },
    },
};
</script>