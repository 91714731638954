<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn :disabled="!$check('users/', ['POST'])" id="btnSave" type="button" elevation="0"
                            color="secondary" dark @click="add" :loading="dataForm.addLoading"
                            v-if="page.isNew">Salvar</v-btn>
                        <v-btn :disabled="!$check('users/1', ['PATCH'])" id="btnSave" type="button" elevation="0"
                            color="secondary" dark @click="update" :loading="dataForm.updateLoading"
                            v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined to="/users">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-select :disabled="isClient()" id="type" :items="dataset.clients" label="Cliente"
                                                v-on:change="changeClient" v-model="dataset.data.client" item-value="id"
                                                item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select id="type" :disabled="!dataset.data.client" :items="dataset.units"
                                                label="Unidade" v-model="dataset.data.unit" item-value="id" item-text="name"
                                                outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field label="Endereço" :value="getAddressByUnit" required outlined
                                                hide-details="auto" :disabled="true">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-select id="role" required :rules="[$rules.required]" :items="dataset.roles"
                                                        label="Perfil" v-model="dataset.data.roles[0]" item-value="id" item-text="name"
                                                        outlined hide-details="auto">
                                                <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>{{ item.name }}</span>
                                                </v-chip>
                                                <span v-if="index === 1" class="grey--text text-caption">
                                                    (+{{ dataset.data.roles.length - 1 }} outros)
                                                </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="name" label="Nome completo" v-model="dataset.data.name"
                                                required :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="email" type="email" label="E-mail" required
                                                v-model="dataset.data.email" :rules="[$rules.email]" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="page.isNew">
                                        <v-col cols="12" sm="6">
                                            <v-text-field id="password" label="Nova senha" :append-icon="dataForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                                " required :rules="[$rules.required, $rules.strongPassword]"
                                                :type="dataForm.showPassword ? 'text' : 'password'"
                                                v-model="dataset.data.password" @click:append="
                                                    dataForm.showPassword = !dataForm.showPassword
                                                    " outlined hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field id="confirmPassword" label="Repita a senha" :append-icon="dataForm.showPasswordConfirmation
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off'
                                                " required
                                                :rules="[$rules.required, dataset.data.password === dataset.data.passwordConfirmation || 'Senha não confere']"
                                                :type="dataForm.showPasswordConfirmation
                                                    ? 'text'
                                                    : 'password'
                                                    " v-model="dataset.data.passwordConfirmation"
                                                @click:append="dataForm.showPasswordConfirmation = !dataForm.showPasswordConfirmation"
                                                outlined hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea id="description" label="Observações"
                                                v-model="dataset.data.description" outlined hide-details="auto" rows="1"
                                                auto-grow></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-row>
            <v-col cols="12">
                <v-card class="card-form-outlined" color="gray">
                    <v-card-title class="card-group-title">
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title">Acesso</span>
                            </div>
                            <div class="button-group">
                                <v-btn id="btnNew" type="button" color="secondary" small elevation="0"
                                    @click="openAccessSelectionModal()">Incluir Acesso</v-btn>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-card class="outlined-container">
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <span>Unidades</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-chip-group>
                                                    <v-chip v-for="(item, index) in dataset.access.units" :key="index"
                                                        class="custom-chip" label close close-icon="mdi-close"
                                                        @click:close="removeAssociationUnit(item)">
                                                        {{ item.name }}
                                                    </v-chip>
                                                </v-chip-group>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-card class="outlined-container">
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <span>Grupos</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-chip-group>
                                                    <v-chip v-for="(item, index) in dataset.access.groups" :key="index"
                                                        class="custom-chip" label close close-icon="mdi-close"
                                                        @click:close="removeAssociation(item)">
                                                    </v-chip>
                                                </v-chip-group>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-card class="outlined-container">
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <span>Veículos</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-chip-group>
                                                    <v-chip v-for="(item, index) in dataset.access.vehicles" :key="index"
                                                        class="custom-chip" label close close-icon="mdi-close"
                                                        @click:close="removeAssociationVehicle(item)">
                                                        {{ item.plate | VMask($masks.plate) }}
                                                    </v-chip>
                                                </v-chip-group>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <access-selection ref="accessSelectionModal" :selectedItems="this.dataset.access"></access-selection>
    </v-container>
</template>
  
<script>
import states from "@/common/data/states.data"
import string from "@/common/util/string"

import axios from "axios"
import user from "@/common/util/identify"
import AccessSelection from "@/components/access/AccessSelection.vue"

export default {
    name: "UserDetails",

    components: {
        'access-selection': AccessSelection
    },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            states: states,
            data: {
                client: null,
                unit: null,
                roles: [],
                accessAssociations: {
                    units: [],
                    vehicles: []
                },
            },
            roles: [],
            units: [],
            clients: [],
            access: {
                vehicles: [],
                groups: [],
                units: []
            }
        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
    }),

    created() {

        this.setPage();
        this.listBreadCrumbItems();
        this.loadData();
        this.selectUserClient();
    },

    computed: {
        getAddressByUnit() {
            if (!this.dataset.data.unit || !this.dataset.data.unit.addressStreet) return '';

            let unit = this.dataset.units.find((x) => x.id === this.dataset.data.unit);

            return unit && unit.addressStreet ? unit.addressStreet : ''
        }
    },

    methods: {
        goBack() {
            this.$router.replace("/users");
        },

        isClient() {
            const { client } = user.identify();
            return !!client;
        },

        async selectUserClient() {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user.client) {
                this.dataset.data.client = user.client;
                await this.loadUnits(this.dataset.data.client);
            }
        },

        async loadData() {
            await this.load();
            await this.loadClients();
            await this.loadRoles();

            if (!this.page.isNew && this.dataset.data.client) {
                await this.loadUnits(this.dataset.data.client.id);
            }
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? "Novo Usuário" : "Detalhes do usuário";
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Usuários",
                disabled: false,
                to: "/users",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? "Incluir usuário" : "Detalhes do usuário",
                disabled: true,
                exact: true,
            });
        },

        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o usuário?',
                title: 'Incluir usuário',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/users', payload);

                this.$dialog.notify.info("Usuário salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o usuário?',
                title: 'Atualizar usuário',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/users/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Usuário salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/users/${this.$route.params.id}`);

                this.dataset.data = result.data.data;

                this.dataset.access.vehicles = this.dataset.data.accessAssociations.vehicles;
                this.dataset.access.units = this.dataset.data.accessAssociations.units;

                this.page.title = this.dataset.data.name
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadUnits(client) {

            try {
                const result = await axios.get('/units', { params: { start: 0, limit: 1000000, client } });
                this.dataset.units = [{ id: null, name: "Todos" }, ...result.data.data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadRoles(client) {
            try {
                const result = await axios.get('/roles', { params: { start: 0, limit: 1000000 } });

                this.dataset.roles = result.data.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadClients() {
            try {
                const result = await axios.get('/clients', { params: { start: 0, limit: 1000000 } });

                this.dataset.clients = [{ id: null, name: "Todos" }, ...result.data.data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        buildPayload() {
            const data = {
                name: this.dataset.data.name,
                email: this.dataset.data.email,
                description: this.dataset.data.description,
                client: this.dataset.data.client,
                unit: this.dataset.data.unit,
                associations: this.normalizeAssociations(),
                credential: {
                    username: this.dataset.data.email,
                    password: this.dataset.data.password,
                },
                roles: [this.dataset.data.roles]

            }

            return data;
        },

        changeClient(client) {
            this.dataset.data.unit = null;
            this.dataset.units = [];
            if (client) this.loadUnits(client);
        },

        removeAssociationUnit(item) {
            const verifySelectedUnit = this.dataset.access.units.findIndex((x) => x.id === item.id);

            if (verifySelectedUnit !== -1) {
                this.dataset.access.units.splice(verifySelectedUnit, 1)
            }
        },

        removeAssociationVehicle(item) {
            const verifySelectedVehicle = this.dataset.access.vehicles.findIndex((x) => x.id === item.id);

            if (verifySelectedVehicle !== -1) {
                this.dataset.access.vehicles.splice(verifySelectedVehicle, 1)
            }
        },

        resolveAccessVehicles(vehicles) {
            const filterAccessVehicles = vehicles.filter((a) => {
                return !this.dataset.access.vehicles.find((b) => a.id === b.id)
            })

            this.dataset.access.vehicles = [...filterAccessVehicles, ...this.dataset.access.vehicles]
        },

        resolveAccessUnits(units) {
            const filterAccessUnits = units.filter((a) => {
                return !this.dataset.access.units.find((b) => a.id === b.id)
            })


            this.dataset.access.units = [...filterAccessUnits, ...this.dataset.access.units]
        },

        resolveAccessGroups(groups) {
            const filterAccessGroups = groups.filter((a) => {
                return !this.dataset.access.groups.find((b) => a.id === b.id)
            })

            this.dataset.access.groups = [...filterAccessGroups, ...this.dataset.access.groups]
        },

        normalizeAssociations() {
            let vehicles = this.dataset.access.vehicles.map((x) => {
                return { association: x.id, type: 1 }
            })

            let units = this.dataset.access.units.map((x) => {
                return { association: x.id, type: 2 }
            })

            let associations = [...vehicles, ...units]

            return associations;
        },

        openAccessSelectionModal() {
            this.$refs.accessSelectionModal.open().then((results) => {
                this.resolveAccessVehicles(results.vehicles);
                this.resolveAccessUnits(results.units);
                this.resolveAccessGroups(results.groups);
            });
        },
    },
};
</script>
  
<style lang="scss">
.theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
    color: #222D6C !important;
    font-weight: 500 !important;
}
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}
</style>