<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col cols="12">
                    <v-card class="filter-card">
                        <v-card-title class="filter-card-title">Filtro</v-card-title>
                        <v-card-text>
                            <v-form @submit.prevent="load()">
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field id="id" label="ID" v-model="dataList.filter.id" hide-details
                                            outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field id="id" label="Data criação" v-model="dataList.filter.date"
                                            hide-details outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select id="status" :items="[]" label="Tempo conexão"
                                            v-model="dataList.filter.status" item-value="id" item-text="name" outlined
                                            hide-details="auto">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2"
                                            large>Filtrar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="table-card">
                        <v-card-title class="table-card-title">
                            <div class="title-container">
                                <div class="title-block">
                                    <span id="title" class="table-card-title">Conexões Recentes</span>
                                </div>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="dataList.headers" :items="dataList.data"
                                :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                                @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                                no-data-text="Nenhuma conexão foi encontrada" loading-text="Carregando dados..."
                                class="app-table hover-table" :options.sync="dataList.options"
                                :footer-props="dataList.footerOptions">

                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

Vue.use(VueLodash)

export default {
    name: "EquipmentList",

    props: {
        model: null
    },

    data: () => ({
        dataset: {
            status: [],
        },
        dataList: {
            filter: {
                type: "",
                client: "",
                status: 0
            },
            headers: [
                { text: "ID", value: "id" },
                { text: "Criado", value: "date" },
                { text: "Atualizado (HH:MM:SS)", value: "updatedAt" },
                { text: "Tempo conexão (HH:MM:SS)", value: "time" },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
    },

    methods: {

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.list();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.list(true);
        },

    },
};
</script>