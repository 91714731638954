const data = [
  { id: 4, name: "MAC 4G" },
  { id: 6, name: "MAC LITE" },
  { id: 3, name: 'Relé Wi-Fi' },
  { id: 1, name: 'Jammer CAN' },
  { id: 5, name: "Tracker Lora" },
  { id: 2, name: 'Sensor Painel' },


]

export default [...data]
