<template>
    <v-dialog v-model="dialog.show" :max-width="800" @keydown.esc="cancel">
        <v-card>
            <v-card-title>
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Associar Equipamento</span>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnNew" icon type="button" color="mediumGray" small elevation="0"
                            @click="dialog.show = false">

                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="load()">
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-text-field id="serial" label="Serial" v-model="dataset.filter.serial" hide-details
                                outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select id="model" :items="dataset.models" label="Modelo" v-model="dataset.filter.model"
                                item-value="id" item-text="name" outlined hide-details="auto">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select id="equipment" :items="dataset.equipmentTypes" label="Tipo"
                                v-model="dataset.filter.type" item-value="id" item-text="name" outlined hide-details="auto">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2" large>Filtrar</v-btn>
                        </v-col>
                    </v-row>

                </v-form>

                <v-row>
                    <v-col>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum equipamento foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions" show-select v-model="dataList.selected"
                            @toggle-select-all="selectAllToggle">

                            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                <v-simple-checkbox :value="isSelected" :readonly="item.id === entity"
                                    :disabled="item.id === entity" @input="select($event)"></v-simple-checkbox>
                            </template>

                            <template v-slot:[`item.serial`]="{ item }">
                                <span class="list-item-title">{{ item.serial }}</span><br />
                                <span class="list-item-subtitle">{{ item.iccid }}</span>
                            </template>

                            <template v-slot:[`item.model`]="{ item }">
                                <span class="list-item-title">{{ item.model.name }}</span><br />
                                <span class="list-item-subtitle">{{ item.type | enum(dataset.equipmentTypes,
                                    "id",
                                    "name")
                                }}</span>
                            </template>

                            <template v-slot:[`item.stage`]="{ item }">
                                <span class="list-item-title">{{ item.stage | enum(dataset.equipmentStages,
                                    "id",
                                    "name")
                                }}</span><br />
                                <span class="list-item-subtitle">{{ item.technician || "-" }}</span>
                            </template>

                            <template v-slot:[`item.client`]="{ item }">
                                <span class="list-item-title">{{ item.client ? item.client.name : '-' }}</span><br />
                            </template>

                            <template v-slot:[`item.date`]="{ item }">
                                <span class="list-item-title">{{ $moment(item.date).format('DD.MM.YYYY') }}</span>
                                <br />
                                <span class="list-item-subtitle">{{ $moment(item.date).format('HH:MM:ss') }}</span>
                            </template>

                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <div class="button-group">
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
                    <v-btn color="secondary" @click.native="select">Associar</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
  
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import equipmentTypes from "@/common/data/equipment-types.data"
import equipmentStages from "@/common/data/equipment-stages.data"
import status from "@/common/data/status.data";
import object from "@/common/util/object"

export default {
    name: "EquipmentSelection",

    props: {
        type: null,
        entity: null,
        client: null,
        selectedEquipments: []
    },

    data: () => ({

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },
        dataset: {
            filter: {},
            equipments: [],
            models: [],
            equipmentTypes: [{ id: "", name: "Todos:" }, ...equipmentTypes],
            equipmentStages: equipmentStages,
        },
        dataList: {
            filter: {
                type: "",
                client: "",
                status: 0
            },
            headers: [
                { text: "Serial/ICCID", value: "serial" },
                { text: "Modelo/Tipo", value: "model" },
                { text: "Estágio/Técnico", value: "stage" },
                { text: "Data", value: "date" },
                { text: "Cliente", value: "client" },
            ],
            data: [],
            selected: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },

    }),

    methods: {

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))
 
            if (this.client) {
                search.client = this.client;
            }

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/equipments', { params: { start, limit, search, association: true,} });

                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

                this.dataList.selected = this.selectedEquipments;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        open() {
            this.dialog.show = true;

            this.load();

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        select() {
            let selected = this.dataList.selected;

            this.resolve(selected);

            this.dataList.selected = [];

            this.dialog.show = false;
        },

        cancel() {
            this.reject();
            this.dialog.show = false;
        },

        selectAllToggle(props) {
            this.dataList.selected = this.dataList.data.filter((x) => x.id !== entity)
        }

    },
};
</script>
  
<style lang="scss" scoped>
.device-block {
    display: flex;
}

.device-card-label {
    font-size: 14px;
    margin-left: 12px;

    .line1 {
        font-weight: 500;
        font-size: 16px;
    }
}

.page-title {
    font-size: 24px;
    text-transform: capitalize;
}
</style>