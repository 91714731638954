<template>
    <v-card outlined @click="detailVehicle(vehicle)">
        <v-card-text>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="1" class="mr-4">
                    <v-icon :size="30">
                        mdi-truck-fast-outline
                    </v-icon>
                </v-col>
                <v-col>
                    <span>{{ vehicle.plate }}</span>
                    <br />
                    <span>{{ vehicle.type | enum(dataset.vehicleTypes, "id", "name") }} - {{ vehicle.brand | enum(dataset.vehicleBrands, "id", "name") }} | {{ vehicle.year }}</span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import _ from 'lodash';
import axios from 'axios';

import vehicleTypes from "@/common/data/vehicle-types.data"
import vehicleBrands from "@/common/data/vehicle-brands.data"
import object from "@/common/util/object"

export default {
    name: "EquipmentAssociated",

    props: {
        vehicle: {
            type: Object
        },
    },

    data: () => ({

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },
        dataset: {
            vehicleTypes: vehicleTypes,
            vehicleBrands: vehicleBrands
        },
    }),

    methods: {

        openDialog() {
            this.dialog.show = true;

            this.load()
        },

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/equipments', { params: { start: start, limit: limit, search: search } });

                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async removeAssociation(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja excluir a associação?',
                title: 'Excluir associação',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                await axios.delete(`/equipments/associations/${item.id}`)

                this.$dialog.notify.info("Associação excluída com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            // this.$router.push(`${this.client}/units/${!item ? 'novo' : item.id}`);
        },
        detailVehicle(vehicle) {
            this.$router.push(`/vehicles/${vehicle.id}`);
        },
    },
};
</script>
  
<style lang="scss" scoped>
span {
    color: var(--v-mediumGray-base);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.page-title {
    font-size: 24px;
    text-transform: capitalize;
}
</style>