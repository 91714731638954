<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col cols="12">
                    <v-card class="filter-card">
                        <v-card-title class="filter-card-title">Filtro</v-card-title>
                        <v-card-text>
                            <v-form @submit.prevent="load()">
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field id="id" label="ID" v-model="dataList.filter.id" hide-details
                                            outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select id="type" :items="[]" label="Tipo" v-model="dataList.filter.type"
                                            item-value="id" item-text="name" outlined hide-details="auto">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select 
                                            id="status" 
                                            :items="[{id: null, name: 'Todos'}, ...dataset.instructionStatus]" 
                                            label="Status" 
                                            v-model="dataList.filter.status"
                                            item-value="id" 
                                            item-text="name" 
                                            outlined 
                                            hide-details="auto">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2"
                                            large>Filtrar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="table-card">
                        <v-card-title class="table-card-title">
                            <div class="title-container">
                                <div class="title-block">
                                    <span id="title" class="table-card-title">Instruções</span>
                                </div>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="dataList.headers" :items="dataList.data"
                                :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                                @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                                no-data-text="Nenhuma instrução foi encontrada" loading-text="Carregando dados..."
                                class="app-table hover-table" :options.sync="dataList.options"
                                :footer-props="dataList.footerOptions">

                                <template v-slot:[`item.type`]>
                                    <span class="list-item-title"> Intervalo de comunicação </span><br />
                                </template>

                                <template v-slot:[`item.status`]="{ item }">
                                    <span class="list-item-title">{{ getStatusName(item.status) }}</span>
                                </template>

                                <template v-slot:[`item.progress`]="{ item }">
                                    <span class="list-item-title">{{ item.progress }}%</span>
                                </template>

                                
                                <template v-slot:[`item.date`]="{ item }">
                                    <span class="list-item-title">{{ $moment(item.date).format('DD.MM.YYYY') }}</span><br />
                                    <span class="list-item-subtitle">{{ $moment(item.date).format('HH:MM:ss') }}</span>
                                </template>

                                <template v-slot:[`item.updatedAt`]="{ item }">
                                    <span class="list-item-title">{{ daysFromNow(item.date) }} dias</span>
                                </template>

                                <template v-slot:[`item.description`]="{ item }">
                                    <span class="list-item-title">{{ getDescription(item.content) }}</span><br />
                                    <!-- <span class="list-item-subtitle">Intervalo: {{ item.content.interval }}</span> -->
                                </template>

                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
  
<script>

import Vue from "vue";
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import VueLodash from 'vue-lodash'

Vue.use(VueLodash)

export default {
    name: "InstructionList",

    props: {
        model: null,
        equipment: {
            type: Object,
            default: () => ({})
        }
    },

    data: () => ({
        dataset: {
            status: [],
            instructionStatus: [
                { id: 0, name: "Cancelado" },
                { id: 1, name: "Aguardando" },
                { id: 2, name: "Iniciado" },
                { id: 3, name: "Sucesso" },
                { id: 4, name: "Falha" },
                { id: 5, name: "Rejeitado" },
                { id: 6, name: "Não implementado" },
            ]
        },
        dataList: {
            filter: {
                type: "",
                client: "",
                status: null
            },
            headers: [
                { text: "ID", value: "id" },
                { text: "Tipo", value: "type" },
                { text: "Status", value: "status" },
                { text: "Progresso", value: "progress" },
                { text: "Criado", value: "date" },
                { text: "Atualizado", value: "updatedAt" },
                { text: "Detalhe", value: "description" },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            paging: {
                page: 1,
                size: 10
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
    },

    methods: {

        async load() {
            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v));
            search = !_.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            const limit = this.dataList.options.itemsPerPage;
            const start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get(`/instructions/${this.equipment.id}`, {
                    params: { start: start, limit: limit, search: search }
                });

                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {

                this.dataList.loading = false;
            }

        },

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.load();
        },

        getStatusName(statusId) {
            const status = this.dataset.instructionStatus.find(item => item.id === statusId);
            return status ? status.name : 'Desconhecido';
        },

        daysFromNow(date) {
            const now = moment();
            const itemDate = moment(date);
            return now.diff(itemDate, 'days');
        },

        getDescription(content) {
            if (content && content.interval !== undefined) {
                return content.interval > 0 ? 'Acordar' : 'Dormir';
            }
            return 'Dormir';
        }

    },
};
</script>