<template>
    <v-row>
        <v-col cols="12">
            <v-card class="table-card">
                <v-card-title>
                    <div class="title-container">
                        <div class="title-block">
                            <span id="title" class="table-card-title">Anexos</span>
                        </div>
                        <div class="button-group">
                            <v-btn id="btnNew" type="button" color="secondary" small elevation="0"
                                @click="openFileSelectionModal()">Adicionar</v-btn>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="dataList.headers" :items="dataList.data" :server-items-length="dataList.count"
                        @update:items-per-page="pageSizeChanged" @update:page="pageChanged" :disable-sort="true"
                        :loading="dataList.loading" no-data-text="Nenhum anexo foi encontrado"
                        loading-text="Carregando dados..." class="app-table hover-table" :options.sync="dataList.options"
                        :footer-props="dataList.footerOptions">
                        <!-- <template v-slot:[`item.date`]="{ item }">
                                <span class="list-item-title">{{ item.name }}</span><br />
                                <span class="list-item-subtitle">{{ item.type | enum(dataset.clientTypes, "id", "name")
                                }}</span>
                            </template> -->

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon @click="viewItem(item)" size="large" class="me-2">
                                mdi-tray-arrow-down
                            </v-icon>
                            <v-icon @click="viewItem(item)" size="large" class="me-2">
                                mdi-pencil-outline
                            </v-icon>
                            <v-icon @click="remove(item)" size="large">
                                mdi-delete-outline
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>

        <file-selection ref="fileModal"></file-selection>
    </v-row>
</template>
  
<script>

// import clientStatus from "@/common/data/client-status.data";
import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import FileSelection from "@/components/file/FileSelection.vue"

Vue.use(VueLodash)

export default {
    name: "FileList",

    props: {
        client: null
    },

    components: {
        'file-selection': FileSelection
    },

    data: () => ({
        dataList: {
            headers: [
                { text: "Nome", value: "name" },
                { text: "Extensão", value: "registrationNumber" },
                { text: "Peso", value: "contactPhone" },
                { text: "Inserido por", value: "addressCity" },
                { text: "Data/Hora", value: "vehicles" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
    },

    methods: {
        clientPageText() {
            return `${this.dataList.paging.page} de ${this.dataList.paging.pages}`;
        },

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.list();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.list(true);
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),
        reload: function () {
            this.load();
        },

        async load() {

            // let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            // search = !object.isEmpty(search) ? search : null;

            // this.dataList.loading = true;

            // var limit = this.dataList.options.itemsPerPage;
            // var start = (this.dataList.options.page - 1) * limit;

            // try {
            //     const result = await axios.get('/units', { params: { start: start, limit: limit, search: search, client: this.client } });

            //     console.log('result ', result.data.data)

            //     this.dataList.data = result.data.data;
            //     this.dataList.count = result.data.count;

            // } catch (error) {
            //     this.$dialog.notify.error(this.$codes(error), { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
            // } finally {
            //     this.dataList.loading = false;
            // }

        },

        async remove(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja excluir o anexo?',
                title: 'Excluir anexo',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                await axios.delete(`/units/${item.id}`)

                this.$dialog.notify.info("Anexo excluído com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        openFileSelectionModal() {
            this.$refs.fileModal.open().then((file) => {
            });
        },
    },
};
</script>