<template>
  <v-container class=" fill-height grid-list-md text-xs-center container-login">
    <v-row justify="center" style="margin: 0px">
      <v-col cols="12" sm="" md="6" lg="8" justify="space-between">
        <v-row>
          <img class="logo" src="@/assets/logo-pilgrim.svg"/>
        </v-row>
        <v-row style="padding: 100px 0px 30px 0px">
          <div class="text-h3 font-weight-bold" style="color: #222D6C !important;">Login</div>
        </v-row>
        <v-row justify="center" style="height: 300px">
          <v-form ref="form" v-model="loginForm.validForm" lazy-validation style="width: 100%" @submit.prevent="login">
            <v-text-field placeholder="Digite seu e-mail" label="E-mail" ref="username" outlined
              :rules="[$rules.required, $rules.email]" v-model="loginForm.user.username"></v-text-field>

            <v-text-field outlined label="Senha" :append-icon="loginForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              required :rules="[$rules.required]" :type="loginForm.showPassword ? 'text' : 'password'"
              v-model="loginForm.user.password" @click:append="loginForm.showPassword = !loginForm.showPassword"
              hide-details="auto" class="mb-4" id="password"></v-text-field>

            <v-row justify="center">
              <v-col>
                <v-btn v-if="$platform.isMobile()" class="btn-auth" width="100%" color="secondary" elevation="2" x-large
                  style="height: 56px; margin-top: 10px" :loading="loginForm.loading" type="submit">Acessar</v-btn>
                <v-btn v-else color="secondary" elevation="2" x-large style="height: 56px; margin-top: 10px"
                  :loading="loginForm.loading" type="submit">Autenticar</v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col>
                <a v-if="$platform.isMobile()" class="btn-forgot-password">Recuperar Senha</a>
                <a v-else class="btn-forgot-password">Redefinir senha ></a>
              </v-col>
            </v-row>
          </v-form>
        </v-row>
        <v-row class="mt-10" tyle="height: 500px;">
          <img src="@/assets/logo-baitatec-gray.svg" :width="150" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import * as jwt from "jwt-decode";

export default {
  name: "Login",

  data: () => ({
    page: {
      slogan: null,
    },
    username: "",
    password: "",
    loading: false,
    valid: true,
    show: false,
    rules: {
      email: [
        (v) => !!v || "O usuário é obrigatório",
        // (v) =>
        //   (v && v.length <= 25) ||
        //   "O usuário deve ter menos de 10 caracteres",
      ],
      password: [(v) => !!v || "A senha é obrigatória"],
    },
    loginForm: {
      validForm: true,
      showPassword: false,
      loading: false,
      user: {},
    },
  }),

  mounted() {
    if (this.$route.query.username) {
      this.loginForm.user.username = this.$route.query.username;
    }
    
  },

  methods: {
    empty() {
      return (
        !this.$data.loginForm.user.username ||
        !this.$data.loginForm.user.password
      );
    },
    async login() {

      if (!this.$refs.form.validate()) return;

      let data = {
        username: this.loginForm.user.username,
        password: this.loginForm.user.password,
      };

      this.$data.loginForm.loading = true;

      try {
        const result = await axios.post('/sessions', data);

        if (!result.data.success) throw new Error();

        localStorage.clear();
        localStorage.setItem('token', JSON.stringify(result.data.data));

        var dataUser = jwt(result.data.data.access);

        this.$root.user = dataUser;
        localStorage.setItem('user', JSON.stringify(dataUser));
        
        if (this.isGateway(dataUser)) {
          if(this.isMobile()){
            router.push("/gateway");
          }else{
            router.push("/tracking/3");
          }
          this.$root.role = 'antenista';
        } else {
          if(this.isMobile()){
            this.$dialog.notify.info('Acesse via desktop', { position: 'top-right', timeout: 5000 });
            localStorage.clear();
            return;
          }
          router.push("/home");
        }

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
        this.$data.loginForm.user.password = "";
      } finally {
        this.$data.loginForm.loading = false;
      }
    },

    forgotPassword() {
      this.$router.replace("/solicitar-senha");
    },

    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
 
    isGateway (dataUser) {
      return dataUser.permissions.some(permission => permission.path === '/tracking/3');
    }


  },
};
</script>

<style scoped lang="scss">

.container-login{
  overflow: scroll !important;
  background-color: #F5F6FA;
  padding: 0 0 40px 0 !important;
}
.theme--light.v-input input, 
.theme--light.v-input textarea, 
.v-select__selection--comma {
  color: #222D6C !important;
  font-weight: 500 !important;
}

  .logo {
    height: 80px;  
}



.btn-forgot-password {
  font-size: 14px;
  color: #9fa1a4;
  font-weight: 400;
}

.btn-login {
  text-transform: none !important;
  letter-spacing: 1px;
  font-weight: 400;
}


@media screen and (max-width: 960px) {
  .logo {
    height: 60px;
  }

  .v-btn {
    width: 100% !important;
  }

  .container {
    padding: 50px !important;
  }
}

</style>