
function isAllowed(path, method) {
  const data = JSON.parse(localStorage.getItem('user'));

  if (!data || !data.permissions) {
    return false;
  }

  return data.permissions.some(infos => {
    if (infos.path === '.*' || path === undefined) {
      return true;
    }

    if (infos.path === path) {
      return infos.methods.includes(method);
    }

    return false;
  });
}

export default isAllowed;