<template>
    <svg width="24" :style="{ transform: `rotate(${rotation}deg)`, width: width + 'px', height: height + 'px'  }" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="04-caminhonete-ok 1" clip-path="url(#clip0_849_208)">
            <g id="Camada_x0020_1">
                <path id="Vector"
                    d="M16.892 19.6847C15.4055 20.1351 14.0091 20.1351 13.1983 19.1441L0.450558 8.37831C-0.0899827 7.92786 -0.0449816 7.47743 -0.0449816 6.71167V3.19813C6.3464e-05 2.61255 0.225311 2.47739 0.991076 2.16207L7.8379 0.0449787C8.64871 -6.63092e-05 9.4145 -0.0901784 10.4505 0.585497L12.5676 2.47748L15.0451 1.75671L18.3785 4.54948C20.0452 5.94588 20.5857 7.2973 21.1262 8.55856C21.6667 9.86487 22.2524 10.8107 23.1082 11.6215C23.874 12.2071 23.874 12.3873 23.874 14.0089V16.7567C23.8289 17.3423 23.1983 17.7477 22.4326 17.9729L16.892 19.6847Z"
                    :fill="color" />
                <path id="Vector_2"
                    d="M15.6307 16.3513L15.946 16.3063C16.1262 16.2613 16.2614 16.4414 16.2614 16.6216V18.6486C16.2614 18.8288 16.1262 18.9639 15.946 18.9639L15.6307 19.009C15.4505 19.0541 15.3154 18.8739 15.3154 18.6937V16.6667C15.3154 16.4865 15.4956 16.3513 15.6307 16.3513Z"
                    fill="white" />
                <path id="Vector_3"
                    d="M23.108 14.099L23.3333 14.009C23.4684 13.9639 23.5585 14.1441 23.5585 14.3242V16.3063C23.5585 16.4865 23.4684 16.5765 23.3333 16.6216L23.108 16.7117C22.9729 16.7567 22.8828 16.5765 22.8828 16.3964V14.4144C22.8828 14.2342 22.9729 14.1441 23.108 14.099Z"
                    fill="white" />
                <path id="Vector_4"
                    d="M10.7209 11.0809C11.2164 12.0718 11.3064 12.6575 10.9911 12.3872L5.22539 7.43227C4.95512 7.162 4.95508 6.80164 4.95508 6.53137V5.76552C4.95508 5.27002 5.04515 5.08991 5.4956 5.49531L8.96404 8.46822C9.77485 9.23398 10.2254 9.90968 10.7209 11.0809Z"
                    fill="white" />
                <path id="Vector_5"
                    d="M20.8557 9.41427C21.081 9.90976 20.9458 10.0899 20.6305 10.18L13.3332 12.4323C12.6125 12.7476 12.3422 12.6576 12.0269 11.9368C11.0359 9.72963 10.8106 8.96377 9.18901 7.56738L6.08095 4.86468C5.90077 4.6845 5.6305 4.54943 5.40527 4.59447L14.4594 1.84668L18.198 5.04492C18.8737 5.67555 19.3242 6.3062 19.7296 6.98188L20.8557 9.41427Z"
                    fill="white" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_849_208">
                <rect width="23.9189" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
<script>
export default {
  name: 'PickupIcon',
  props: {
    rotation: {
      type: Number,
      default: 0
    },
    color: {
        type: String,
        default: '#222D6C'
      },
    width: {
      type: Number,
      default: 30
    },
    height: {
      type: Number,
      default: 30
    }
  }
}
</script>