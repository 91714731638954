<template>
    <v-navigation-drawer v-model="dataset.opened" absolute bottom temporary right hide-overlay stateless :width="440" class="custom-drawer">
        <div v-if="dataset.openedFilter" class="px-6">
            <v-row class="mt-2" align="center" justify="center">
                <v-col>
                    <span class="list-vehicle-title">Veículos</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col align="right" class="mt-2">
                    <v-btn icon elevation="0" @click="closeFilter()"><v-icon>mdi-close</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row class="container-filters-II" v-if="!isClient()">
                <v-col>
                    <v-text-field id="plate" label="Busque por cliente, placa ou prefixo" append-icon="mdi-magnify"
                        append-icon-color="primary" v-model="dataset.filter.info" v-on:keyup="debounceHistory" hide-details transition="scale-transition" outlined></v-text-field>
                </v-col>
            </v-row>
            <v-row class="container-filters-II" v-else>
                <v-col>
                    <v-text-field id="plate" label="Busque por placa ou prefixo" append-icon="mdi-magnify"
                        append-icon-color="primary" v-model="dataset.filter.info" v-on:keyup="debounceHistory" hide-details transition="scale-transition" outlined></v-text-field>
                </v-col>
            </v-row>
            <v-row class="container-filters-II">
                <v-col>
                    <v-select id="status" v-model="dataset.filter.accurate" :items="datalist.precision" 
                        label="Precisão dos Pontos"
                        v-on:change="debounceHistory"
                        item-value="id" item-text="name" outlined hide-details="auto">
                    </v-select>
                </v-col>
            </v-row>
            <v-row class="container-filters-II">
                <v-col>
                    <v-menu ref="dateRangeMenu" v-model="dateRangeMenu"
                        :close-on-content-click="false" transition="scale-transition" offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-bind="attrs" v-on="on"
                                label="Data Início e Fim"
                                :value="dateRangeText" readonly outlined
                                append-icon="mdi-calendar">
                                <template v-slot:append v-if="startDate || endDate">
                                    <v-icon @click="clearDates" class="mr-2">mdi-close</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="selectedDate" @change="updateDate"
                            :max="dateRangeStep === 'end' ? null : endDate"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-row v-if="dataset.loading">
                <v-col>
                    <template >
                        <v-progress-linear indeterminate color="#222D6C"></v-progress-linear>
                    </template>
                </v-col>
            </v-row>

            <v-row class="info-not-vehicle" v-if="!dataset.loading && dataset.notVehicle">
                <v-col>
                    <template >
                    <v-alert type="error" dismissible icon="mdi-broadcast-off">
                        <span>{{ dataset.infoNotVehicle }}</span>
                    </v-alert>
                    </template>
                </v-col>
            </v-row>

            <v-row class="container-cards-history">
                <v-col>
                    <v-card class="vehicle-card px-2 my-2" outlined v-for="(item, index) in datalist.vehicles"
                        @click="openHistoryDetails(item)" v-bind:key="index">
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="12" sm="2">

                                    <component :class="item.icon == 7 ? 'icon-size-truck-III' : 'icon-size-III'" :is="getIconComponent(item)" :color="ColorUtils.getColor(item)"></component>
                                </v-col>
                                <v-col cols="12" sm="5">
                                    <v-row no-gutters>
                                        <v-col>
                                            <div 
                                                class="vehicle-card-title"
                                                :style="{ color: ColorUtils.setColorText(item.equipment.model.type, item.equipment.status)}"
                                            >
                                            {{ item.plate }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col>
                                            <div class="vehicle-card-subtitle">{{ item.client.name }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="1" sm="5" class="custom-col-flag">
                                    <div :class="ColorUtils.getTemplateFlag(item.equipment.model.type, item.equipment.status)">
                                        <span>{{ getEquipmentTypeName(item.equipment.model.type) }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <!-- Componente de detalhes -->
        <div v-else class="px-6">
            <v-row class="mt-2" align="center" justify="center">
                <v-col>
                    <span class="list-vehicle-title">{{dataset.vehicleSelected.prefix}} - {{dataset.vehicleSelected.plate}}</span>
                    <br>
                    <span class="list-vehicle-subtitle">Placa</span>
                </v-col>
                <v-col sm="2" align="right" class="mt-2">
                    <v-btn icon elevation="0" @click="closeHistoryDetails()"><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <span class="subtitle-label">{{ dataset.vehicleSelected.brand | enum(dataset.vehicleBrands, "id", "name") }}</span>
                    <br />
                    <span class="desc-label">Marca</span>
                </v-col>
            </v-row>
            <v-row class="custom-row-history">
                <v-col>
                    <span class="subtitle-label">{{ dataset.vehicleSelected.client.name }}</span>
                    <br />
                    <span class="desc-label">Cliente</span>
                </v-col>
            </v-row>
            <v-row class="custom-row-history">
                <v-col>
                    <span class="subtitle-label">{{dataset.vehicleSelected.equipment.serial}}</span>
                    <br />
                    <span class="desc-label">Equipamento</span>
                </v-col>
            </v-row>
            <v-row class="custom-row-history">
                <v-col>
                    <span class="subtitle-label">{{ dataset.filter.accurate | enum(datalist.precision, "id", "name") }}</span>
                    <br />
                    <span class="desc-label">Precisão dos pontos</span>
                </v-col>
            </v-row>
            <v-row class="custom-row-history">
                <v-col>
                    <span class="subtitle-label">{{ formatDate(datalist.filter.startDate) }} à {{ formatDate(datalist.filter.endDate) }}</span>
                    <br />
                    <span class="desc-label">Intervalos de datas</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <span class="list-vehicle-title">Histórico</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="timeline">
                    <div
                        v-for="(position, index) in dataset.vehicleSelected.positions"
                        :key="index"
                        class="timeline-item"
                    >
                        <div class="timeline-icon">
                        <v-icon :color="'primary'" size="30">
                            {{ position.accurate ? 'mdi-map-marker-check' : 'mdi-map-marker-alert-outline' }}
                        </v-icon>
                        </div>
                        <div class="timeline-content">
                            <div class="d-flex align-center">
                                <span class="subtitle-label">{{ formatDateAndTime(position.date) }}</span>
                            </div>
                   
                            <div class="vertical-border">
                                <div>
                                    <span class="desc-label">
                                        {{ position.accurate ? 'Precisa' : 'Imprecisa' }}
                                        ({{ position.accurate ? formatCoordinates(position.lat, position.lng) :  `${position.gateway}` }})
                                      </span> <br />
                                    <span class="desc-label">{{ position.battery }} mV</span>
                                </div>
                                <v-icon class="custom-icon"> mdi-navigation </v-icon>
                            </div>
                        </div>
                    </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-row class="row-info-box">
            <v-col>
                <div class="info-detail"> 
                    <span>
                        Ao clicar sob o PIN no mapa, você terá mais informações a respeito do ponto selecionado.
                    </span>
                </div>
            </v-col>
        </v-row>
    </v-navigation-drawer>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _, { debounce } from 'lodash';
import axios from "axios"

import moment from 'moment';
import { eventHub } from '@/main'
import object from "@/common/util/object"
import user from "@/common/util/identify";
import vehicleIcons from "@/common/data/vehicle-icons.data"
import vehicleBrands from "@/common/data/vehicle-brands.data";

import CarIcon from '@/components/icons/CarIcon2d.vue';
import BusIcon from '@/components/icons/BusIcon.vue';
import HatchIcon from '@/components/icons/HatchIcon.vue';
import PickupIcon from '@/components/icons/PickupIcon.vue';
import TruckIcon from '@/components/icons/TrcukIcon2d.vue';
import VanIcon from '@/components/icons/VanIcon.vue';
import MotorcycleIcon from '@/components/icons/MotorcycleIcon.vue';
import { stringify } from "querystring";

import ColorUtils from "@/common/util/ColorUtils";

Vue.use(VueLodash)

export default {
    name: "VehicleFilter",

    props: {
        vehicles: null,
        historyPath: [],
        zoomMap: Function,
        clearInfosHistory: Function,
    },

    data: () => ({
        ColorUtils,
        dataset: {
            opened: false,
            loading: false,
            openedFilter: true,
            vehicleIcons: vehicleIcons,
            vehicleBrands: vehicleBrands,
            filter: {
                accurate: null, 
            },
            status: [],
            events: [],
            vehicleSelected: null,
            infoNotVehicle: 'Veículo não encontrado',
            dates: ['2018-09-15', '2018-09-20'],
        },
        datalist: {
            vehicles: [],
            precision: [
                { id: null, name: 'Precisos e Imprecisos' },
                { id: 1, name: 'Precisos' },
                { id: 0, name: 'Imprecisos' }
            ],
            filter: {
                endDate: '',
                dateRange: '',
                startDate: ''
            },
        },
        endDate: '',
        startDate: '',
        selectedDate: '',
        dateRangeMenu: false,
        dateRangeStep: 'start',
        selectedVehicle: {}
    }),

    created() {
        eventHub.$on("VEHICLE-HISTORY-FILTER-CHANGE", (value) => {
            this.dataset.opened = value;
        });

        // const info = localStorage.getItem('info');

        // if(info) {
        //     this.dataset.filter.info = info;
        //     this.getVehicles();
        // }
    },

    computed: {
        dateRangeText() {
            return `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
        },
    },

    methods: {

        isClient() {
            const { client } = user.identify();
            return !!client;
        },

        debounce: _.debounce(function() {
            this.load();
        }, 500),
        
        debounceHistory: _.debounce(function() {
            this.checkDatesAndDebounce();
        }, 500),

        formatCoordinates(lat, lng) {
            return `${lat.toFixed(5)}, ${lng.toFixed(5)}`;
        },

        closeFilter() {
            this.dataset.opened = false;
            this.clearHistoryPath();
            this.closeHistoryDetails();
        },

        formatDate(date) {
            if (!date) return '';
            return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
        },

        formatDateAndTime(date) {
            const d = new Date(date);
            return moment(d, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY - HH:mm');
        },

        formatDateTime(date, time) {
            return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
        },

        updateDate() {
            if (this.dateRangeStep === 'start') {
                this.startDate = this.selectedDate;
                this.dataset.filter.startDate = this.formatDateTime(this.startDate, '00:00:00');
                this.datalist.filter.startDate = this.selectedDate
                this.dateRangeStep = 'end';
                this.selectedDate = '';
            } else {
                this.endDate = this.selectedDate;
                this.dataset.filter.endDate = this.formatDateTime(this.endDate, '23:59:59');

                this.datalist.filter.endDate = this.selectedDate;
                this.dateRangeMenu = false;
                this.dateRangeStep = 'start';
            }

            this.checkDatesAndDebounce();
        },

        async getVehicles() {
            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            if(!search.info) {
                return;
            }

            this.dataset.loading = true;

            try {
                const result = await axios.get('/vehicles/broadcaster', { params: { start: 0, limit: 10000, search: search } });

                if(result.data.data.length > 0) {
                    this.dataset.notVehicle = false;

                    // const v = []
                    // for (let i =0; i < 20; i++) {
                    //     v.push(result.data.data[0]);
                    // }
                   // this.datalist.vehicles = result.data.data;
                    //this.resolveVehicles();
                } else {
                    this.dataset.notVehicle = true;
                    this.datalist.vehicles = null;
                }
                
            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },

        async load(){

            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            if(this.dataset.filter.accurate == 0) {
                search.accurate = Boolean(this.dataset.filter.accurate);
            }

            if(this.dataset.filter.accurate == 1) {
                search.accurate = Boolean(this.dataset.filter.accurate);
            }

            this.dataset.loading = true;
            try {
                const result = await axios.get(`/tracking/history`, { params: { start: 0, limit: 10000, search: search } });

                const vehicles = result.data.data[0];
                vehicles.forEach(vehicle => {
                if (vehicle.positions && Array.isArray(vehicle.positions)) {
                    vehicle.positions.sort((a, b) => new Date(b.date) - new Date(a.date));
                }
                });

                this.datalist.vehicles = vehicles;

                this.resolveVehicles();
        
            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }  finally {
                this.dataset.loading = false;
            }
        },

        async openHistoryDetails(item) {
            this.dataset.vehicleSelected = item;

            const historyPaths = [];
            this.$emit('update:historyPath', historyPaths);
            const positions = [...item.positions];

            console.log('historyPaths ', positions)
            if (positions.length > 1000) {
                this.$dialog.notify.error('Limite de pontos excedido. Selecione um período menor.', { position: 'top-right', timeout: 5000 });
                return;
            }

            positions
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .forEach(position => {
                historyPaths.push({
                    lat: position.lat,
                    lng: position.lng,
                    battery: position.battery,
                    date: position.date,
                    gateway: position.gateway,
                    rssi: position.rssi,
                    snr: position.snr,
                    prefix: item.prefix,
                    plate: item.plate,
                    equipment: item.equipment,
                    accurate: position.accurate ? 1 : 0,
                });
            });
            
            this.dataset.openedFilter = false;
            this.$emit('update:historyPath', historyPaths);
            eventHub.$emit('HISTORY-VIEWER', true);
            setTimeout(() => {
                this.zoomMap(positions);
            }, 500);
        },

        checkDatesAndDebounce() {   
            if(this.dataset.filter.info && !this.startDate && !this.endDate) {
                return
            }

            if (this.startDate && this.endDate) {
                this.debounce();
            }

            if(!this.startDate && this.dataset.filter.accurate) {
                this.$dialog.notify.info("Selecione as datas", { position: 'top-right', timeout: 5000 });
            }
        },

        resolveVehicles() {
            for (let item of this.datalist.vehicles) {
                const icon = this.getAssetsByIcon(item.icon);
                item.customIcon = icon;
            }
        },

        getAssetsByIcon(icon) {
            return this.dataset.vehicleIcons.find((x) => x.id === icon).assets
        },

        clearHistoryPath() {
            this.clearInfosHistory();
            this.$emit('update:historyPath', []);
        },

        closeHistoryDetails() {
            this.dataset.openedFilter = true;
            this.clearHistoryPath();
            eventHub.$emit('HISTORY-VIEWER', false);
        },

        getIconComponent(vehicle) {
            const iconComponents = {
                'car.svg': CarIcon,
                'bus.svg': BusIcon,
                'hatch.svg': HatchIcon,
                'motorcycle.svg': MotorcycleIcon,
                'pickup.svg': PickupIcon,
                'truck.svg': TruckIcon,
                'van.svg': VanIcon
            };
        return iconComponents[vehicle.customIcon] || null;
        },

        getEquipmentTypeName(type) {
            if(type == 5){
                return 'Mac Lora'
            }

            if(type == 4){
                return 'Mac 4g'
            }
        },

        clearDates() {
            this.startDate = null;
            this.endDate = null;
            this.selectedDate = null;
            this.dateRangeMenu = false;
            this.dateRangeStep = 'start';
        }

    },
};
</script>

<style lang="scss">

.custom-drawer{
    overflow: hidden !important;
}

.v-date-picker-title{
    display: none !important;
}

.theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
    color: #222D6C !important;
    font-weight: 500 !important;
}
.vehicle-card {
    .vehicle-card-title {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }

    .vehicle-card-subtitle {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }
}

.title-label {
    color: var(--v-primary-base) !important;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.subtitle-label {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}

.desc-label {
    color: var(--v-mediumGray-base);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* 380% */
}

.info-not-vehicle{
    color: var(--v-important-base) !important;
}

.custom-col-flag{
    display: flex;
    justify-content: flex-end !important;
}

.equipament-blue-I,
.equipament-red-I,
.equipament-yellow {
    padding: 5px;
    width: 100% !important;
    border-radius: 5px;
    display: flex;  
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
}

.equipament-blue-I{
    color: var(--v-primary-base);
    background-color: rgba(34, 45, 108, 0.07);
}

.equipament-red-I{
    color: var(--v-important-base);
    background-color: rgba(251, 58, 4, 0.07);
}

.equipament-yellow{
    color: var(--v-yellow-base);
    background-color: rgba(255, 183, 3, 0.07);
}

.list-vehicle-title{
    font-size: 26px !important;
    font-weight: 800 !important;
}

.custom-row-history{
    margin-top: -15px !important;
}

.timeline {
    top:-10px;
    position: relative;
    margin-bottom: 150px;
  }
  
  .timeline-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .timeline-icon {
    flex-shrink: 0;
    margin-right: 10px;
  }
  
  .timeline-content {
    flex-grow: 1;
    position: relative;
  }
  
  .vertical-border {
    border-left: 2px solid #000;
    height: 50px;
    margin-top: 5px;
    margin-left: -26px;
    padding-left: 20px;
    position: relative;
  }
  
  .vertical-border > .custom-icon {
    position: absolute;
    top: 25%;
    left: -10.9px;
    z-index: 9999;
    font-size: 20px;
    color: var(--v-primary-base);
  }


  .container-cards-history{
    margin-bottom: 150px;
  }

.row-info-box{
    width: 105%;
    padding: 25px;
    bottom: 12px;
    position: absolute; 
    z-index: 10000;
    background-color: #ffffff;

}
  
  .info-detail{
    width: 100%;
    padding: 15px;
    font-size: 13px;
    background-color: #F7F7F7;
  }

</style>