import Vue, { PluginObject } from 'vue'

const messages: Map<number, string> = new Map([
  [1010, 'Nome inválido'],
  [1011, 'Nome não fornecido'],
  [1012, 'Nome não encontrado'],
  [1013, 'E-mail inválido'],
  [1014, 'E-mail não fornecido'],
  [1015, 'E-mail não encontrado'],
  [1016, 'Cliente inválido'],
  [1017, 'Cliente não encontrado'],
  [1018, 'Cliente não fornecido'],
  [1019, 'Unidade inválida'],
  [1020, 'Unidade não encontrada'],
  [1021, 'Unidade não fornecida'],
  [1022, 'Username inválido'],
  [1023, 'Username não fornecido'],
  [1024, 'Username não encontrado'],
  [1025, 'Senha inválida'],
  [1026, 'Senha não fornecida'],
  [1027, 'Senha não encontrada'],
  [1028, 'Start inválido'],
  [1029, 'Start não encontrado'],
  [1030, 'Start não fornecido'],
  [1031, 'Limit inválido'],
  [1032, 'Limit não encontrado'],
  [1033, 'Limit não fornecido'],
  [1034, 'Busca inválida'],
  [1035, 'ID inválido'],
  [1036, 'ID não fornecido'],
  [1037, 'ID não encontrado'],
  [1038, 'Razão social inválida'],
  [1039, 'Razão social não fornecida'],
  [1040, 'Razão social não encontrada'],
  [1041, 'CPF/CNPJ inválido'],
  [1042, 'CPF/CNPJ não fornecido'],
  [1043, 'CPF/CNPJ não encontrado'],
  [1044, 'IE inválido'],
  [1045, 'IM inválido'],
  [1046, 'Endereço inválido'],
  [1047, 'Número inválido'],
  [1048, 'CEP não fornecido'],
  [1049, 'Bairro inválido'],
  [1050, 'Cidade inválida'],
  [1051, 'Estado inválido'],
  [1052, 'Complemento inválido'],
  [1053, 'Nome de contato inválido'],
  [1054, 'Telefone de contato inválido'],
  [1055, 'E-mail de contato inválido'],
  [1056, 'Tipo inválido'],
  [1057, 'Tipo não fornecido'],
  [1058, 'Tipo não encontrado'],
  [1059, 'Status inválido'],
  [1060, 'Status não fornecido'],
  [1061, 'Status não encontrado'],
  [1062, 'Descrição inválida'],
  [1063, 'Valor inválido'],
  [1064, 'Valor não fornecido'],
  [1065, 'Valor não encontrado'],
  [1066, 'Equipamento inválido'],
  [1067, 'Placa inválida'],
  [1068, 'Placa não fornecida'],
  [1069, 'Marca inválida'],
  [1070, 'Marca não fornecida'],
  [1071, 'Prefixo inválido'],
  [1072, 'Prefixo não fornecido'],
  [1073, 'Modelo inválido'],
  [1074, 'Modelo não fornecido'],
  [1075, 'Ano inválido'],
  [1076, 'Ano não fornecido'],
  [1077, 'Ícone inválido'],
  [1078, 'Ícone não fornecido'],
  [1079, 'Link não encontrado'],
  [1080, 'URL inválida'],
  [1081, 'URL não fornecido'],
  [1082, 'Associação inválida'],
  [1083, 'Associação não fornecida'],
  [1084, 'Serial inválido'],
  [1085, 'Serial não fornecido'],
  [1086, 'Estágio inválido'],
  [1087, 'Estágio não fornecido'],
  [1088, 'ICCID inválido'],
  [1089, 'ICCID não fornecido'],
  [1090, 'Firmware inválido'],
  [1091, 'Firmware não fornecido'],
  [1092, 'Hardware inválido'],
  [1093, 'Hardware não fornecido'],
  [1094, 'Part Number inválido'],
  [1095, 'Part Number não fornecido'],
  [1096, 'Mac Address inválido'],
  [1097, 'Mac Address não fornecido'],
  [1098, 'E-mail duplicado'],
  [1099, 'CPF/CNPJ duplicado'],
  [1100, 'MAC duplicado'],
  [1101, 'Serial duplicado']
])

class CodesPlugin implements PluginObject<any> {
  install = (_Vue: typeof Vue, options?: any) => {
    _Vue.prototype.$codes = function(object: any) {
      var message = ''
      if (object && object.data) {
        message =
          messages.get(object.data.code) || object.data.message || 'nenhuma informação disponível'
        if (object.data.code == 1002)
          message += `\n\n${object.data.message && object.data.message.substring(0, 500)}`
      }

      return message
    }
  };
  [key: string]: any
}

const plugin = new CodesPlugin()
Vue.use(plugin)
