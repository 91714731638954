<template>
    <v-dialog v-model="dialog.show" :max-width="550" @keydown.esc="cancel">
        <v-card>
            <v-card-title>
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Incluir Acesso</span>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnNew" icon type="button" color="mediumGray" small elevation="0"
                            @click="dialog.show = false">

                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="loadItems()">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-select id="model" :items="dataset.accessTypes" label="Incluir acesso a"
                                v-model="dataset.selectedAccessType" item-value="id" item-text="name" outlined
                                hide-details="auto" v-on:change="changeType">
                            </v-select>
                        </v-col>
                        <v-col v-if="dataset.selectedAccessType == 1" cols="12" sm="6">
                            <v-text-field id="name" label="Nome" v-model="dataset.filter.name" hide-details
                                outlined></v-text-field>
                        </v-col>
                        <v-col v-if="dataset.selectedAccessType == 2" cols="12" sm="6">
                            <v-text-field id="unit" label="Unidade" v-model="dataset.filter.unit" hide-details
                                outlined></v-text-field>
                        </v-col>
                        <v-col v-if="dataset.selectedAccessType == 3" cols="12" sm="6">
                            <v-select id="model" :items="dataset.vehicleBrands" label="Marca" v-model="dataset.filter.brand"
                                item-value="id" item-text="name" outlined hide-details="auto">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="dataset.selectedAccessType == 1">
                        <v-col cols="12" sm="6">
                            <v-text-field id="registrationNumber" label="CNPJ" v-model="dataset.filter.registrationNumber"
                                hide-details outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field id="address" label="Localização" v-model="dataset.filter.addressStreet"
                                hide-details outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="dataset.selectedAccessType == 2">
                        <v-col cols="12" sm="6">
                            <v-text-field id="vehicle" label="Veículo" v-model="dataset.filter.vehicle" hide-details
                                outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field id="plate" label="Placa" v-model="dataset.filter.plate" hide-details
                                outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="dataset.selectedAccessType == 3">
                        <v-col cols="12" sm="6">
                            <v-text-field id="model" label="Modelo" v-model="dataset.filter.model" hide-details
                                outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field id="plate" label="Placa" v-model="dataset.filter.plate" hide-details
                                outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2" large>Filtrar</v-btn>
                        </v-col>
                    </v-row>

                </v-form>

                <!-- <access-selection-vehicle v-if="dataset.selectedAccessType == 1" :filter="dataset.filter"></access-selection-vehicle> -->
                <access-unit-selection ref="accessSelectionUnit" @emit-result="selectUnits"
                    v-if="dataset.selectedAccessType == 1" :filter="dataset.filter"></access-unit-selection>

                <access-vehicle-selection ref="accessSelectionVehicle" @emit-result="selectVehicles"
                    v-if="dataset.selectedAccessType == 3" :filter="dataset.filter"></access-vehicle-selection>

            </v-card-text>
            <v-card-text>
                <div class="button-group">
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
                    <v-btn color="secondary" @click.native="select">Associar</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
  
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import status from "@/common/data/status.data";
import object from "@/common/util/object"

import vehicleBrands from "@/common/data/vehicle-brands.data";

import AccessVehicleSelection from "@/components/access/AccessVehicleSelection.vue"
import AccessUnitSelection from "@/components/access/AccessUnitSelection.vue"

export default {
    name: "EquipmentSelection",

    components: {
        'access-vehicle-selection': AccessVehicleSelection,
        'access-unit-selection': AccessUnitSelection
    },

    props: {
        selectedItems: {
            type: Object
        }
    },

    data: () => ({

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },
        dataset: {
            filter: {},
            equipments: [],
            models: [],
            selectedAccessType: 1,
            accessTypes: [{ id: 1, name: "Unidade" }, { id: 2, name: "Grupo" }, { id: 3, name: "Veículo" }],
            vehicleBrands: vehicleBrands,
            selectedVehicles: [],
            selectedGroups: [],
            selectedUnits: []
        },
    }),

    methods: {

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.load();
        },

        open() {
            this.dialog.show = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        close() {
            this.dialog.show = false;
        },

        select() {
            // this.$refs.accessSelectionVehicle.clear();
            // this.$refs.accessSelectionUnit.clear();

            this.resolve({ vehicles: this.dataset.selectedVehicles, groups: this.dataset.selectedGroups, units: this.dataset.selectedUnits });

            this.clearItems();

            this.close();
        },

        clearItems() {
            this.dataset.selectedUnits = []
            this.dataset.selectedVehicles = []
            this.dataset.selectedGroups = []
        },

        cancel() {
            this.reject();
            this.close();
        },

        selectAllToggle(props) {
            this.dataList.selected = this.dataList.data.filter((x) => x.id !== entity)
        },

        loadItems() {
            switch (this.dataset.selectedAccessType) {
                case 1:
                    this.$refs.accessSelectionUnit.load();
                    break;
                case 2:

                    break;
                case 3:
                    this.$refs.accessSelectionVehicle.load();
                    break;

                default:
                    break;
            }
        },

        selectVehicles(items) {
            this.dataset.selectedVehicles = items;
        },

        selectUnits(items) {
            this.dataset.selectedUnits = items;
        },

        selectGroups(items) {
            this.dataset.selectedGroups = items;
        },

        changeType() {
            this.dataset.filter = {}

            this.loadItems()

        },

    },
};
</script>
  
<style lang="scss" scoped>
.device-block {
    display: flex;
}

.device-card-label {
    font-size: 14px;
    margin-left: 12px;

    .line1 {
        font-weight: 500;
        font-size: 16px;
    }
}

.page-title {
    font-size: 24px;
    text-transform: capitalize;
}
</style>