<template>
    <v-card class="card-infobox-sheet">
        <v-card-text>
            <div class="container">
                <div class="avatar-box">
                    <template>
                        <v-avatar>
                            <img :src="'https://ui-avatars.com/api/?name=' + user.name + '&size=108&font-size=0.5&color=222D6C&background=efefef&rounded=true'" class="avatar-border" />
                        </v-avatar>
                    </template>
                </div>
            </div>

            <v-row>
                <v-col cols="12" class="title-info">
                    <span>{{ user.name }}</span>
                </v-col>
                <v-col cols="12" class="subtitle-info">
                    <span>{{ user.email }}</span>
                </v-col>
            </v-row>

            <v-row class="info-row">
                <v-col cols="10">
                    <div class="info-title">{{ dataset.gateway }}</div>
                    <div class="info-subtitle">Gateway Atual</div>
                </v-col>
                <v-col cols="2" class="edit-icon">
                    <v-btn icon @click="editGateway">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="info-row">
                <v-col cols="10">
                    <div class="info-title">{{ dataset.prefix }} - {{ dataset.plate }}</div>
                    <div class="info-subtitle">Veículo Atual</div>
                </v-col>
                <v-col cols="2" class="edit-icon">
                    <v-btn icon @click="editVehicle">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <!-- Agora adicione uma vrow com um botão de sair -->
            <div class="info-row-btn">
                <v-btn @click="logout" :height="56" class="close-button" color="primary" outlined elevation="0">
                    Sair
                </v-btn>
            </div>

        </v-card-text>
    </v-card>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import vehicleBrands from "@/common/data/vehicle-brands.data";

export default {
    name: 'SheetSettings',
    props: {
        vehicle: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            user: {
                name: '',
                email: ''
            },
            dataset: {
                plate: '',
                prefix: '',
                gateway: '',
                equipmentType: null,
                equipmentStatus: null,
                vehicleBrands: vehicleBrands,
            },
        }
    },
    mounted() {
        this.user = this.$root.user;

        if(this.vehicle){
            this.dataset.plate = this.vehicle.plate;
            this.dataset.prefix = this.vehicle.prefix;
        }

        if(!this.dataset.plate || !this.dataset.prefix) {
            const vehicles = JSON.parse(localStorage.getItem('vehicles'));
            this.dataset.plate = vehicles[0].plate;
            this.dataset.prefix = vehicles[0].prefix;
        }

        const gateway = localStorage.getItem('gateway');
        if(gateway) {
            this.dataset.gateway = gateway;
        }
    },
    methods: {
        logout() {
            localStorage.clear();
            this.$router.push("/login");
            setTimeout(() => {
            window.location.reload();
            }, 50);
        },
        editGateway() {
            this.$router.push({ path: '/change-gateway' });
        },
        editVehicle() {
            this.$router.push({ path: '/change-vehicle' });
        },
    }
}
</script>

<style lang="scss">
.card-infobox-sheet {
    font-family: Montserrat !important;
    background-color: #f7f7f7 !important;
    bottom: 80px;
    padding: 40px !important;
    position: fixed;
    z-index: 1005 !important;	
    overflow: auto;

    .v-avatar {
        width: 100px !important;
        height: 100px !important;
    }
    .container {
        display: flex;
        justify-content: center;
        position: fixed;
    }
    
    .avatar-box {
        top: -100px;
        width: 120px;
        height: 120px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        position: relative;
        justify-content: center;
        background-color: #ffff;
        transform: translateX(-50%);
    }
    
    .avatar-border {
        position: static;
        border-radius: 50%;
    }
    
    .title-info {
        margin-top: 50px;
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 700;
    }

    .subtitle-info {
        font-size: 15px;
        margin-top: -25px;
    }

    .info-row {
        margin-top: 20px;
    }

    .info-row-btn {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 50px;
        
        button {
            width: 100% !important;
        }
    
    }


    .info-title {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        color: var(--v-primary-base);
    }

    .info-subtitle {
        font-family: Montserrat;
        font-size: 14px;
    }

    .edit-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>