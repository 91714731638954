<template>
    <v-card class="card-infobox-sheet">
        <v-card-text>
            <v-row align="center" align-content="center" justify="space-between">
                <v-col cols="8">
                    <span 
                        :class="ColorUtils.setColorText(dataset.equipmentType, dataset.equipmentStatus)" 
                    >
                        {{ vehicle.prefix }} - {{ vehicle.plate }}
                    </span>
                    <br/>
                    <span class="desc-label">Placa</span>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        @click="closeSheet"
                        class="close-button"
                        icon
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>

                <SignalStrengthIndicator :view-return="false" :rssi="dataset.vehicleRssi" :minRssi="dataset.minRssi" :maxRssi="dataset.maxRssi" />
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import axios from "axios";
import ColorUtils from "@/common/util/ColorUtils";
import vehicleBrands from "@/common/data/vehicle-brands.data";
import SignalStrengthIndicator from "./SignalStrengthIndicator.vue";

export default {
    name: 'SheetApproximation',
    props: {
        vehicle: {
            type: Object,
            required: true
        }
    },
// setar um watch para atualizar o vehicle.rssi
    watch: {
        vehicle: {
            handler: function (val) {
                this.dataset.vehicleRssi = val.rssi;
            },
            deep: true
        }
    },

    components: {
        SignalStrengthIndicator
    },

    created() {
        this.loadRssi();
        this.dataset.vehicleRssi = this.vehicle.rssi;
    },

    data() {
        return {
            ColorUtils,
            dataset: {
                minRssi: -48,
                maxRssi: -120,
                vehicleRssi: 0,
                equipmentType: null,
                equipmentStatus: null,
                vehicleBrands: vehicleBrands,
            },
        }
    },
    mounted() {
        if (this.vehicle && this.vehicle.equipment) {
            this.dataset.equipmentStatus = this.vehicle.equipment.status;
            this.dataset.equipmentType = this.vehicle.equipment.model.type;
        }
    },
    methods: {
        closeSheet() {
            this.$emit('close');
        },
        async loadRssi() {
            const response = await axios.get(`/equipments/1/rssi`);
            if(response.data.data) {
                this.dataset.minRssi = response.data.data.min;
                this.dataset.maxRssi = response.data.data.max;
            }
        },
    }
}
</script>


<style lang="scss">
.card-infobox-sheet {
    background-color: #f7f7f7 !important;
    bottom: 80px;
    padding: 40px 40px 70px 40px;
    position: fixed;
    z-index: 1005 !important;	
    overflow: auto;
    .title-label {
        font-family: Montserrat;
        font-size: 20px !important;
        margin-left: 0px !important;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 17px !important;
        font-style: normal;
        font-weight: 600;
    }

    .subtitle-label-sm {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        font-size: 13px !important;
    }

}
</style>