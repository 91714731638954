<template>
    <v-card class="card-infobox" :width="350" color="white">
        <v-card-text>
            <div>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="1">
                        <v-icon size="40" color="primary">mdi-car-wrench</v-icon>
                    </v-col>
                    <v-col>
                        <span class="ml-4 title-label">Técnico</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">Auto Elétrica Boa Vista</span>
                        <br />
                        <span class="desc-label">Estabelecimento</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">51 2312-1002</span>
                        <br />
                        <span class="desc-label">Fone</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">Aberto</span>
                        <br />
                        <span class="desc-label">Horário Abertura</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <span class="subtitle-label">R. Anita Garibaldi, 2301 - Loja 04 - Boa Vista, Porto Alegre - RS,
                    90480-201</span>
                <br />
                <span class="desc-label">Endereço</span>
            </div>
            <div class="mt-2">
                <span class="subtitle-label">-29.854873,
                    -50.9358308</span>
                <br />
                <span class="desc-label">Coordenadas</span>
            </div>
        </v-card-text>
        <v-card-text>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="2">
                    <v-avatar>
                        <img src="https://randomuser.me/api/portraits/men/50.jpg" />
                    </v-avatar>
                </v-col>
                <v-col>
                    <span class="subtitle-label">Manoel Linhares</span>
                    <br />
                    <span class="desc-label">Técnico Responsável</span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';

Vue.use(VueLodash)

export default {
    name: "TechnicianInfoBox",

    data: () => ({
        dataset: {
        },
    }),

    created() {
    },

    methods: {
    },
};
</script>

<style lang="scss">
.card-infobox {

    .title-label {
        font-family: Montserrat;
        font-size: 28px;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 700;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        /* 380% */
    }

}
</style>