<template>
    <v-card class="card-infobox-sheet">
        <div>
            <v-row align="center" align-content="center" justify="space-between">
                <v-col cols="8">
                    <span 
                        :class="ColorUtils.setColorText(dataset.equipmentType, dataset.equipmentStatus)" 
                    >
                        {{ vehicle.prefix }} - {{ vehicle.plate }}
                    </span>
                    <br/>
                    <span class="desc-label">Placa</span>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        @click="closeSheet"
                        class="close-button"
                        icon
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <div>
                <div v-if="vehicle.position.accurate" class="position-info accurate">
                    <v-icon color="#222D6C" size="20">mdi-map-marker-check</v-icon>
                    <span>Posição Precisa</span>
                </div>
                <div v-else class="position-info inaccurate">
                    <v-icon color="#222D6C" size="20">mdi-map-marker-alert-outline</v-icon>
                    <span>Posição Imprecisa</span>
                </div>
            </div>

            <div>
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.brand | enum(dataset.vehicleBrands, "id", "name") }}</span>
                        <br />
                        <span class="desc-label">Marca</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.client.name }}</span>
                        <br />
                        <span class="desc-label">Cliente</span>
                    </v-col>
                </v-row>
            </div>

            <div v-if="dataset.equipmentType === 5">
                <div>
                    <v-row>
                        <v-col>
                            <span class="subtitle-label">{{ vehicle.equipment.serial }}</span>
                            <br />
                            <span class="desc-label">Equipamento</span>
                        </v-col>
                        <v-col>
                            <span class="subtitle-label">{{ vehicle.battery }}</span>
                            <br />
                            <span class="desc-label">Bateria</span>
                        </v-col>
                    </v-row>
                </div>
    
                <div>
                    <v-row>
                        <v-col>
                            <span class="subtitle-label">{{ this.formatDate(vehicle.date) }} </span>
                            <br />
                            <span class="desc-label">Data ({{  this.calculateTimeDifference(vehicle.date) }})</span>
                        </v-col>
                        <v-col>
                            <span class="subtitle-label">{{ this.formatTime(vehicle.date) }}</span>
                            <br />
                            <span class="desc-label">Hora</span>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <div v-if="dataset.equipmentType === 4">
                <div>
                    <v-row>
                        <v-col>
                            <span class="subtitle-label">{{ vehicle.equipment.serial }}</span>
                            <br />
                            <span class="desc-label">Equipamento</span>
                        </v-col>

                        <!-- TODO: O que seria tempo bloqueado? -->
                        <v-col>
                            <span class="subtitle-label">{{ this.formatTime(vehicle.date) }}</span>
                            <br />
                            <span class="desc-label">Tempo Bloqueado</span>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <div>
                <v-row>
                    <v-col>
                        <span class="subtitle-label-sm">{{ address ? address : '...' }}</span>
                        <br />
                        <span class="desc-label">Endereço</span>
                    </v-col>
                    <v-col style="max-width: 180px;">
                        <span class="subtitle-label-sm">{{ vehicle.position.lat.toFixed(8) }}</span>
                        <br />  
                        <span class="subtitle-label-sm">{{ vehicle.position.lng.toFixed(8) }}</span>
                        <br />  
                        <span class="desc-label">Coordenadas</span>
                    </v-col>
                </v-row>
            </div>

            <div v-if="trackingType == '3'">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.rssi }} dB</span>
                        <br />
                        <span class="desc-label">RSSI</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">{{ vehicle.snr }} dB</span>
                        <br />
                        <span class="desc-label">SNR</span>
                    </v-col>
                </v-row>
            </div>

        </div>
    </v-card>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import ColorUtils from "@/common/util/ColorUtils";
import vehicleBrands from "@/common/data/vehicle-brands.data";

export default {
    name: 'SheetVehicle',
    props: {
        trackingType: String,
        vehicle: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            ColorUtils,
            address: null,
            dataset: {
                equipmentType: null,
                equipmentStatus: null,
                vehicleBrands: vehicleBrands,
            },
        }
    },
    mounted() {
        if (this.vehicle && this.vehicle.equipment) {
            this.dataset.equipmentStatus = this.vehicle.equipment.status;
            this.dataset.equipmentType = this.vehicle.equipment.model.type;
            this.getAdrress(this.vehicle.plate, this.vehicle.position.lat, this.vehicle.position.lng);
        }
    },
    methods: {
        async getAdrress(plate, lat, lng) {
            const storedData = JSON.parse(localStorage.getItem('vehicleData')) || [];
            const existingEntry = storedData.find(entry => entry.lat === lat && entry.lng === lng);

            if (existingEntry) {
                this.address = existingEntry.address;
            } else {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            const address = results[0].formatted_address;
                            this.address = address;

                            const newEntry = { plate, lat, lng, address };
                            const updatedData = storedData.filter(entry => entry.plate !== plate);
                            updatedData.push(newEntry);
                            localStorage.setItem('vehicleData', JSON.stringify(updatedData));
                        } else {
                            console.log('Nenhum endereço encontrado');
                        }
                    } else {
                        console.error('Geocoder falhou devido a: ' + status);
                    }
                });
            }
        },
        closeSheet() {
            this.$emit('close');
        },
        formatDate(dateString) {
            return moment(dateString).format('DD/MM/YYYY');
        },
        formatTime(dateString) {
            return moment(dateString).format('HH:mm:ss');
        },
        calculateTimeDifference(dateString) {
            const now = moment();
            const vehicleDate = moment(dateString);
            const diffMinutes = now.diff(vehicleDate, 'minutes');
            const diffHours = now.diff(vehicleDate, 'hours');
            const diffDays = now.diff(vehicleDate, 'days');
            const diffWeeks = Math.floor(diffDays / 7);
            const diffMonths = now.diff(vehicleDate, 'months');
            const diffYears = now.diff(vehicleDate, 'years');

            if (diffMinutes < 60) {
                return `há ${diffMinutes} minuto${diffMinutes !== 1 ? 's' : ''}`;
            } else if (diffHours < 24) {
                return `há ${diffHours} hora${diffHours !== 1 ? 's' : ''}`;
            } else if (diffDays < 7) {
                return `há ${diffDays} dia${diffDays !== 1 ? 's' : ''}`;
            } else if (diffWeeks < 4) {
                return `há ${diffWeeks} semana${diffWeeks !== 1 ? 's' : ''}`;
            } else if (diffMonths < 1) {
                const additionalDays = diffDays % 30;
                return `há ${additionalDays} dia${additionalDays !== 1 ? 's' : ''}`;
            } else if (diffMonths < 12) {
                return `há ${diffMonths} mês${diffMonths !== 1 ? 'es' : ''}`;
            } else {
                return `há ${diffYears} ano${diffYears !== 1 ? 's' : ''}`;
            }
        },
        setInfoButton(type, status) {
            if (type === 5) {
                switch (status) {
                    case 1:
                    case 2:
                    case 3:
                        return 'Dormir';
                    case 5:
                        return 'Acordando...';
                    case 6:
                        return 'Aguardando...';
                    default:
                        return 'Acordar';
                }
            }

            if (type === 4) {
                switch (status) {
                    case 1:
                    case 2:
                        return 'Bloquear';
                    case 3:
                        return 'Desbloquear';
                    case 8:
                        return 'Desbloqueando';
                    case 7:
                        return 'Bloqueando';
                    default:
                        return 'Bloquear';
                }
            }
        },
    }
}
</script>


<style lang="scss">
.card-infobox-sheet {
    bottom: 80px;
    padding: 40px;
    position: fixed;
    z-index: 1005 !important;	
    overflow: auto;
    .title-label {
        font-family: Montserrat;
        font-size: 20px !important;
        margin-left: 0px !important;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 17px !important;
        font-style: normal;
        font-weight: 600;
    }

    .subtitle-label-sm {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        font-size: 13px !important;
    }

    .position-info {
        display: flex;
        padding: 7px;
        margin: 20px 0;
        background-color: #f3f3f3;
        align-items: center;
        gap: 8px;
    }
    
    .position-info span {
        font-weight: 600;
        font-size: 13px;
        color:#222D6C;
    }
    
    .inaccurate{
        width: 170px;
    }
    .accurate{
        width: 150px;
    }

}
</style>