<template>
    <div class="control-button">

        <v-row class="mb-2" no-gutters>
            <v-col>
                <v-btn class="custom-control-button" small color="white" @click="viewLegends()">
                    <v-icon>
                        mdi-help-rhombus-outline
                    </v-icon>
                </v-btn>
            </v-col>
            <div v-if="dataset.viewLegends" class="legend-menu">
                <div>
                    <div class="title-container">
                        <div >
                            <span class="headline">Legenda</span>
                        <br />
                            <span class="subtitle">Veículos</span>
                        </div>
                        <div class="btn-legend">
                            <v-btn icon @click="viewLegends">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div class="container-colors">
                        <div class="legend-item">
                            <v-icon color="#FFB703">mdi-bookmark</v-icon>
                            <p>Acordado</p>
                        </div>
                        <div class="legend-item">
                            <v-icon color="#222E6C">mdi-bookmark</v-icon>
                            <p>Conectado</p>
                        </div>
                        <div class="legend-item">
                            <v-icon color="#FB3A04">mdi-bookmark</v-icon>
                            <p>Bloqueado</p>
                        </div>
                        <div class="legend-item">
                            <v-icon color="#01BCEF">mdi-bookmark</v-icon>
                            <p>Desconectado</p>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>


        
        <v-row v-if="trackingType == 3" class="mb-2" no-gutters>
            <v-col>
                <v-btn class="custom-control-button" small color="white" @click="openVehicleNearbyFilter()">
                    <v-icon>
                        mdi-map-marker-distance
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-else-if="trackingType == 2" class="mb-2" no-gutters>
            <v-col>
                <v-btn class="custom-control-button" small color="white" @click="openVehicleFilter()">
                    <v-icon>
                        mdi-map-marker-path
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-else class="mb-2" no-gutters>
            <v-col>
                <v-btn class="custom-control-button" small color="white" @click="openVehicleFilter()">
                    <v-icon>
                        mdi-car-multiple
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        

        <!-- <v-row class="mb-2" no-gutters>
            <v-col>
                <v-btn class="custom-control-button" small color="white">
                    <v-icon>
                        mdi-alert-rhombus-outline
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mb-2" no-gutters>
            <v-col>
                <v-btn class="custom-control-button" small color="white"
                    @click="dataset.safeAreaInfoBoxOpened = !dataset.safeAreaInfoBoxOpened">
                    <v-icon>
                        mdi-store-marker-outline
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mb-2" no-gutters>
            <v-col>
                <v-btn class="custom-control-button" small color="white"
                    @click="dataset.technicianInfoBoxOpened = !dataset.technicianInfoBoxOpened;">
                    <v-icon>
                        mdi-car-wrench
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row> -->
        <v-row class="mb-2" no-gutters>
            <v-col>
                <v-btn class="custom-control-button" small color="white" @click="setZoomIn()">
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mb-2" no-gutters>
            <v-col>
                <v-btn class="custom-control-button" small color="white" @click="setZoomOut()">
                    <v-icon>
                        mdi-minus
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="mb-2" no-gutters v-if="trackingType != 2">
            <v-col> 
                <div class="box-polling">
                    <v-icon v-if="counter == -1" color="#222D6C" class="icon-pulse">
                        mdi-cloud-arrow-down-outline
                    </v-icon>

                    <v-progress-circular v-else
                        rotate="-90"
                        size="30"
                        width="2"
                        :value="progressPercent"
                        color="primary"
                    >
                        {{ counter }}
                    </v-progress-circular>
                </div>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import Vue from "vue";
import VueLodash from 'vue-lodash';
import _ from 'lodash';
import { eventHub } from '@/main';
import vehicleIcons from "@/common/data/vehicle-icons.data";

Vue.use(VueLodash);

export default {
    name: "VehicleFilter",

    props: {
        trackingType: null,
        counter: {
            type: Number,
        },
        waitTime: {
            type: Number,
        }
    },

    data() {
        return {
            dataset: {
                viewLegends: false,
                opened: false,
                vehicleIcons: vehicleIcons,
                filter: {},
                status: [],
                events: []
            }
        };
    },

    computed: {
        progressPercent() {
            return (this.counter / (this.waitTime / 1000)) * 100;
        }
    },

    created() {
    },

    methods: {
        openVehicleFilter() {
            
            if (this.trackingType == 1) {
                eventHub.$emit('VEHICLE-FILTER-CHANGE', true);
            }

            if (this.trackingType == 2) {
                eventHub.$emit('VEHICLE-HISTORY-FILTER-CHANGE', true);
            }
        },

        openVehicleNearbyFilter() {
            eventHub.$emit('VEHICLE-NEARBY-FILTER-CHANGE', true);
        },

        setZoomIn() {
            this.$emit('zoomChange', 'in');
        },

        setZoomOut() {
            this.$emit('zoomChange', 'out');
        },

        viewLegends() {
            this.dataset.viewLegends = !this.dataset.viewLegends;
        }
    },


};
</script>

<style lang="scss">
.control-button {
    position: absolute;
    bottom: 100px;
    left: 30px;
}

.custom-control-button {
    height: 45px !important;
}

.legend-menu {
    width: 200px;
    left: 70px;
    position: absolute;

    padding: 20px!important;
    background-color: #ffff;
}

.title-container {
    display: flex;
    justify-content: space-between;
}

.subtitle{
    top: -5px;
    font-size: 14px;
    font-weight: 500;
    color: #9E9E9E;
    position: relative;
}

.btn-legend{
    margin-top: -20px;
}

.container-colors{
    margin-top: 10px;

    p{   
        margin: 4px 0;
        font-size: 15px;
        color: #959595;
    }
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.legend-item v-icon {
    margin-right: 8px;
}

.box-polling {
    width: 50px;
    height: 45px;
    display: flex;
    padding: 2%;
    font-weight: 600;
    color:#222D6C;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #ffff;

    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}


@keyframes pulse-arrow {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.icon-pulse {
    animation: pulse-arrow 0.9s ease-in-out infinite;
}

</style>