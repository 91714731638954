<template>
    <v-card class="card-safe-area-infobox" :width="350" color="white">
        <v-card-text>
            <div>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="1">
                        <v-icon size="40" color="secondary">mdi-store-marker-outline</v-icon>
                    </v-col>
                    <v-col>
                        <span class="ml-4 title-label">Área Segura</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">Posto Charrua Passo D’Areia</span>
                        <br />
                        <span class="desc-label">Estabelecimento</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <v-row>
                    <v-col>
                        <span class="subtitle-label">51 3361-9508</span>
                        <br />
                        <span class="desc-label">Fone</span>
                    </v-col>
                    <v-col>
                        <span class="subtitle-label">Aberto</span>
                        <br />
                        <span class="desc-label">Horário Abertura</span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-2">
                <span class="subtitle-label">Av. Plínio Brasil Milano, 2343 - Passo d'Areia, Porto Alegre - RS,
                    90520-000</span>
                <br />
                <span class="desc-label">Endereço</span>
            </div>
            <div class="mt-2">
                <span class="subtitle-label">-29.854873,
                    -50.9358308</span>
                <br />
                <span class="desc-label">Coordenadas</span>
            </div>
        </v-card-text>
        <v-card-text>
            <div class="d-flex flex-wrap">
                <div class="text-center d-flex flex-column align-center justify-center add-info-box">
                    <v-icon size="20" color="white">
                        mdi-bed-clock
                    </v-icon>
                </div>
                <div class="ml-2 text-center d-flex flex-column align-center justify-center add-info-box">
                    <v-icon size="20" color="white">
                        mdi-food
                    </v-icon>
                </div>
                <div class="ml-2 text-center d-flex flex-column align-center justify-center add-info-box">
                    <v-icon size="20" color="white">
                        mdi-gas-station
                    </v-icon>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';

Vue.use(VueLodash)

export default {
    name: "TechnicianInfoBox",

    data: () => ({
        dataset: {
        },
    }),

    created() {
    },

    methods: {
    },
};
</script>

<style lang="scss">
.card-safe-area-infobox {

    .title-label {
        color: var(--v-secondary-base) !important;
        font-family: Montserrat;
        font-size: 28px;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 700;
    }

    .subtitle-label {
        color: var(--v-primary-base);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }

    .desc-label {
        color: var(--v-mediumGray-base);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        /* 380% */
    }

    .add-info-box {
        background: var(--v-secondary-base) !important;
        width: 45px;
        height: 45px;
    }

}
</style>