<template>
  <svg :style="{ width: width + 'px', height: height + 'px' }" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <!-- Fixed Circle -->
    <circle cx="50" cy="50" r="15" :fill="color" />

    <!-- Pulsating Circle -->
    <circle cx="50" cy="50" r="15" :fill="color">
      <animate attributeName="r" from="15" to="50" dur="2s" repeatCount="indefinite" />
      <animate attributeName="opacity" from="1" to="0" dur="2s" repeatCount="indefinite" />
    </circle>
  </svg>
</template>

<script>
export default {
  name: 'PulsatingSignalIcon',
  props: {
    color: {
      type: String,
      default: '#FF5252'
    },
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 50
    }
  }
}
</script>

<style scoped>
@keyframes pulse {
  0% {
    r: 15;
    opacity: 1;
  }
  100% {
    r: 50;
    opacity: 0;
  }
}

circle[style*="pulse"] {
  animation: pulse 2s infinite ease-out;
}
</style>