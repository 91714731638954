<template>
    <v-data-table :headers="historyList.headers" :items="history" no-data-text="Nenhum histórico encontrado"
        hide-default-footer :disable-sort="true" :loading="historyList.loading" class="app-table">
        <template v-slot:[`item.type`]="{ item }">
            <span class="line1">{{ item.type | enum(historyType, "id", "name")}}</span>
            <span class="line2">{{item.user.name}}</span>
        </template>

        <template v-slot:[`item.date`]="{ item }">
            <span class="line1">
                {{ formatDate(item.createdAt) }}
            </span>
        </template>

        <template v-slot:[`item.action`]="{ item }">
            <div class="action-button-container">
                <v-icon @click="viewDetails(item)" :disabled="item.type !== 2" size="large">
                    mdi-playlist-plus
                </v-icon>
            </div>
        </template>
        <modal-history :history="dataset.historySelected" />
    </v-data-table>
</template>
  
<script>
import { eventHub } from '@/main';
import historyType from "@/common/data/history-type"
import HistoryModal from "@/components/event/components/ModalHistory.vue"
import historyEntityTipe from "@/common/data/history-entity-type"

export default {
    name: "EventTable",
    comments: {
        'modal-history': HistoryModal,
    },
    props: {
        history: {
            type: Array,
            default: () => [],
        },
        displayEntity: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
        return {
            dataset: {
                historySelected: {},
            },
            historyType: historyType,
            historyEntityTipe: historyEntityTipe,
            historyList: {
                headers: [
                    {
                        class: "header-item",
                        text: "Registro",
                        value: "type",
                    },
                    {
                        class: "header-item",
                        text: "Data/Hora",
                        value: "date",
                    },
                    {
                        class: "header-item centered-header",
                        text: "Ação",
                        value: "action",
                    },
                ],
                loading: false,
            },
        };
    },

    methods: {
        formatDate(date) {
            return date ? this.$moment(date).format("DD.MM.YYYY HH:mm:ss") : "";
        },
        viewDetails(item) {
            this.$emit('update-history-selected', item);
            this.$emit('show-history-modal', true);

        },
    },
};
</script>
  
<style lang="scss" scoped>
.event {
    display: block;
    margin-top: 14px;
    font-size: 12px;
    font-weight: 400;
    color: #2c3153;
}

::v-deep .v-data-table__wrapper {
  background-color: var(--v-gray-base);
}

.line1 {
    font-size: 15px;
    display: block;
    font-weight: 500;
}

.line2 {
    font-size: 12px;
    display: block;
    color: #959595;
}

.action-button-container {
    display: flex;
    justify-content: center;
}

.centered-header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

body{
    
    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--v-primary-base);
        border-radius: 10px;
    }
}
</style>