<template>
    <v-dialog v-model="dataset.opened" max-width="600">
        <div class="modal">
            <v-card>
                <v-card-title class="headline title-modal">
                    Metadata
                    <v-spacer></v-spacer>
                    <v-icon @click="closeModal" class="close-icon" color="#222D6C">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="box-info-text">
                            <span class="info-text">Abaixo, estão listadas as informações adicionais
                                que o evento registrado disponibiliza.</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div class="json-metadata" v-html="formattedMetadata"></div>
                        </v-col>
                    </v-row>
                    <v-row class="container-buttons">
                        <v-col cols="6">
                            <v-btn color="#01BCEF" @click="copy">Copiar Informações</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';
import { eventHub } from '@/main';

Vue.use(VueLodash)

export default {
    name: "ModalMetadata",
    props: {
        equipment: Object,
    },
    data: () => ({
        dataset: {
            opened: false,
        },

    }),
    created() {
        eventHub.$on("EQUIPMENT-MODAL", (value) => {
            this.dataset.opened = value;
        });
    },
    computed: {
        formattedMetadata() {
            return `<pre>${JSON.stringify(this.equipment.metadata, null, 2)}</pre>`;
        }
    },
    methods: {
        closeModal() {
            eventHub.$emit("EQUIPMENT-MODAL", false);
        },
        copy() {
            const infos = JSON.stringify(this.equipment.metadata, null, 2);

            if (navigator.clipboard) {
                navigator.clipboard.writeText(infos).then(() => {
                    eventHub.$emit("EQUIPMENT-MODAL", false);

                    this.$dialog.notify.info('Copiado para a área de transferência.', { position: 'top-right', timeout: 3000 });
                }).catch(err => {
                    console.log(err)
                    this.$dialog.notify.error('Erro ao copiar.', { position: 'top-right', timeout: 3000 });
                });
            } else {
                this.$dialog.notify.error('A API de área de transferência não está disponível.', { position: 'top-right', timeout: 3000 });
            }
            
        }
    },
};
</script>

<style lang="scss">

.v-overlay--active>div {
   opacity: 0.9 !important;
   background-color: #222D6C !important;
}
.modal{
    
    .theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
        color: #222D6C !important;
        font-weight: 500 !important;
    }

    .close-icon {
        cursor: pointer;
    }

    .title-modal{
        color: #222D6C !important;
        font-weight: 700 !important;
        font-size: 32px !important;
        text-transform: none !important;
    }

    .v-application .headline {
        font-family: Montserrat !important;
    }


    .info-text {
        color: #959595;
        font-size: 15px !important;
        font-weight: 400 !important;
        font-family: Montserrat !important;
    }

    .container-buttons{
        width: 100%;
        display: flex;
        justify-content: right;
    }

    .json-metadata {
        width: 100%;
        height: auto;
        padding: 20px;
        color: #222D6C;
        font-size: 14px !important;
        font-weight: 600 !important;
        background-color: #F5F5F5;
        font-family: Montserrat !important;
    }
}


</style>



