import Vue, { PluginObject } from 'vue'

const image = {
  customSvgColor: (image, replaceTag, color) => {
    if (replaceTag && image.indexOf(replaceTag) > -1) image = image.replace(replaceTag, color)

    return "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(image)));
  }
}

class ImagePlugin implements PluginObject<any> {
  install = (_Vue: typeof Vue, options?: any) => {
    _Vue.prototype.$image = image;
  }
}

const plugin = new ImagePlugin()
Vue.use(plugin)
