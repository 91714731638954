const data = [
  { id: 1, name: 'Volvo' },
  { id: 2, name: 'DAF' },
  { id: 3, name: 'Volkswagen' },
  { id: 4, name: 'Scania' },
  { id: 5, name: 'Mercedes-Benz' },
  { id: 6, name: 'Iveco' },
  { id: 7, name: 'Ford' },
  { id: 8, name: 'Chevrolet' },
  { id: 9, name: 'Fiat' },
  { id: 10, name: 'Hyundai' },
  { id: 11, name: 'Toyota' },
  { id: 12, name: 'Jeep' },
  { id: 13, name: 'Renault' },
  { id: 14, name: 'Honda' }
]

export default [...data]
