<template>
    <v-row>
        <v-col cols="12">
            <v-card class="table-card">
                <v-card-title>
                    <div class="title-container">
                        <div class="title-block">
                            <span id="title" class="table-card-title">Links</span>
                        </div>
                        <div class="button-group">
                            <v-btn id="btnNew" type="button" color="secondary" small elevation="0"
                                @click="openLinkSelectionModal()">Adicionar</v-btn>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="dataList.headers" :items="dataList.data" :server-items-length="dataList.count"
                        @update:items-per-page="pageSizeChanged" @update:page="pageChanged" :disable-sort="true"
                        :loading="dataList.loading" no-data-text="Nenhum link foi encontrado"
                        loading-text="Carregando dados..." class="app-table hover-table" :options.sync="dataList.options"
                        :footer-props="dataList.footerOptions">
                        <template v-slot:[`item.user`]="{ item }">
                            <v-avatar class="mr-4" :size="35">
                                <img src="https://randomuser.me/api/portraits/men/50.jpg" />
                            </v-avatar>
                            <span class="list-item-title">{{ item.user.name }}</span><br />
                        </template>

                        <template v-slot:[`item.date`]="{ item }">
                            <span class="list-item-title">{{ $moment(item.date).format('DD.MM.YYYY') }}</span><br />
                            <span class="list-item-subtitle">{{ $moment(item.date).format('HH:MM:ss') }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon v-clipboard:copy="item.url" v-clipboard:success="onCopy" size="large" class="me-2">
                                mdi-content-copy
                            </v-icon>
                            <v-icon @click="viewItem(item)" size="large" class="me-2">
                                mdi-pencil-outline
                            </v-icon>
                            <v-icon @click="remove(item)" size="large">
                                mdi-delete-outline
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>

        <link-selection ref="linkModal" :type="type" :association="association"></link-selection>
    </v-row>
</template>
  
<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import LinkSelection from "@/components/link/LinkSelection.vue"

Vue.use(VueLodash)

export default {
    name: "LinkList",

    props: {
        association: null,
        type: null
    },

    components: {
        'link-selection': LinkSelection
    },

    data: () => ({
        dataset: {
            operation: 'association',
            filter: {}
        },
        dataList: {
            headers: [
                { text: "Nome", value: "name" },
                { text: "Link", value: "url" },
                { text: "Inserido por", value: "user" },
                { text: "Data/Hora", value: "date" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
    },

    methods: {
        clientPageText() {
            return `${this.dataList.paging.page} de ${this.dataList.paging.pages}`;
        },

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.list();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.list(true);
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        async load() {

            // let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            // search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/links', { params: { start: start, limit: limit, type: this.type, association: this.association, operation: this.dataset.operation } });

                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                console.log('erorr ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async remove(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja excluir o link?',
                title: 'Excluir link',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                await axios.delete(`/links/${item.id}`)

                this.$dialog.notify.info("Link excluído com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        onCopy() {
            this.$dialog.notify.info("Link copiado!", { position: "top-right", timeout: 2000 });
        },

        openLinkSelectionModal() {
            this.$refs.linkModal.open().then((file) => {
            });
        },
    },
};
</script>