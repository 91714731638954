<template>
    <svg width="31" :style="{ transform: `rotate(${rotation}deg)`, width: width + 'px', height: height + 'px'  }" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="06-onibus-ok 1" clip-path="url(#clip0_849_222)">
            <g id="Camada_x0020_1">
                <path id="Vector"
                    d="M1.24097 3.38098L0.0427694 11.0414C-0.128415 11.8545 -0.00011022 11.8117 1.24097 12.8388L20.1996 29.187C21.2267 30.0001 22.211 30.1712 23.7089 29.7433L29.6576 27.7319C30.5563 27.4323 30.4707 27.0044 30.2995 25.7633L29.2296 17.3324C29.0584 16.1341 29.1441 15.963 28.0742 15.107L11.3837 0.813217C10.4422 -0.0854992 9.32946 -0.213887 8.34515 0.171277L1.92575 2.0971C1.32661 2.39667 1.32657 3.03861 1.24097 3.38098Z"
                    :fill="color" />
                <path id="Vector_2"
                    d="M19.1724 20.0286L1.54049 4.92163L1.02686 8.47372L17.7601 22.8532C18.2737 23.2384 18.7873 23.752 19.3008 24.4795L20.0711 25.635C21.0126 26.9616 21.8686 27.2612 23.238 26.9188L28.8015 25.207C29.1867 25.1214 29.4863 24.9502 29.7859 24.5651L28.9727 18.4452C28.8443 18.6164 28.6731 18.702 28.4591 18.7876L21.6973 20.8418C20.713 21.0558 19.8572 20.585 19.1724 20.0286Z"
                    fill="white" />
                <path id="Vector_3"
                    d="M29.4861 25.5066L29.7429 25.421C29.8285 25.3782 29.914 25.5066 29.914 25.635L30.0425 26.8333C30.0425 26.9617 29.9569 27.0045 29.8713 27.0473L29.6145 27.1328C29.5289 27.1756 29.4434 27.0472 29.4434 26.9189L29.3149 25.7205C29.3149 25.5922 29.4005 25.5494 29.4861 25.5066Z"
                    fill="white" />
                <path id="Vector_4"
                    d="M22.2539 27.7318L22.7247 27.6034C22.8103 27.5606 22.8958 27.689 22.8958 27.8173L23.0242 29.0157C23.0242 29.144 22.9386 29.1868 22.853 29.2296L22.3823 29.3152C22.2967 29.3152 22.211 29.2296 22.211 29.1012L22.0827 27.9458C22.0399 27.8174 22.1683 27.7746 22.2539 27.7318Z"
                    fill="white" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_849_222">
                <rect width="30.428" height="30" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
  
<script>
export default {
  name: 'BusIcon',
  props: {
      rotation: {
        type: Number,
        default: 0
      },
      color: {
        type: String,
        default: '#222D6C'
      },
      width: {
        type: Number,
        default: 30
      },
      height: {
        type: Number,
        default: 30
      }
    },
}
</script>