<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn :disabled="!$check('models/', ['POST'])" id="btnSave" type="button" elevation="0"
                            color="secondary" dark @click="add" :loading="dataForm.addLoading"
                            v-if="page.isNew">Salvar</v-btn>
                        <v-btn :disabled="!$check('models/1', ['PATCH'])" id="btnSave" type="button" elevation="0"
                            color="secondary" dark @click="update" :loading="dataForm.updateLoading"
                            v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined to="/models">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="!page.isNew">
            <v-col :cols="12">
                <div class="title-container">
                    <div class="button-group">
                        <v-btn :color="dataset.isViewTab ? 'mediumGray' : 'secondary'" outlined
                            @click="dataset.isViewTab = false">
                            Detalhes

                            <v-icon right>mdi-format-list-text</v-icon>

                        </v-btn>
                        <v-btn outlined @click="dataset.isViewTab = true"
                            :color="!dataset.isViewTab ? 'mediumGray' : 'secondary'">
                            Equipamentos
                            <v-icon right>mdi-archive-marker-outline</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <equipment-list v-if="dataset.isViewTab" :model="dataset.data.id"></equipment-list>
        <v-form v-else ref="form" v-model="dataForm.validForm" class="mt-7">
            <v-row>
                <v-col cols="12" sm="6">
                    <v-row>
                        <v-col cols="12">
                            <v-card :height="300" class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field id="name" label="Nome" v-model="dataset.data.name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field id="partNumber" label="Part Number"
                                                v-model="dataset.data.partNumber" required :rules="[$rules.required]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select id="type" :items="dataset.modelTypes" label="Tipo"
                                                v-model="dataset.data.type" item-value="id" item-text="name" outlined
                                                hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field id="integration" :disabled="true" label="Código integração"
                                                v-model="dataset.data.integration" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select id="type" :disabled="true" :items="dataset.originTypes"
                                                label="Origem cadastro" v-model="dataset.data.origin" item-value="id"
                                                item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-row>
                        <v-col cols="12">
                            <v-card color="theme-card" :height="300">
                                <v-card-title class="card-group-title">Dados Adicionais</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea id="description" label="Informações Adicionais"
                                                v-model="dataset.data.description" outlined hide-details="auto" rows="8"
                                                auto-grow></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <file-list v-if="!dataset.isViewTab"></file-list>
    </v-container>
</template>
  
<script>
import modelTypes from "@/common/data/model-types.data"
import originTypes from "@/common/data/origin-types.data"
import string from "@/common/util/string"
import FileList from "@/components/file/FileList.vue"
import EquipmentList from "@/views/model/equipment/EquipmentList.vue"

import axios from "axios"

export default {
    name: "ModelDetails",

    components: { 'file-list': FileList, 'equipment-list': EquipmentList },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            modelTypes: modelTypes,
            originTypes: originTypes,
            data: {
                origin: 1
            },
            isViewTab: false
        },
        clientId: undefined,
        dataForm: {
            files: {},
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            created: null,
        },
    }),

    created() {
        this.setPage();
        this.listBreadCrumbItems();
        this.load();
    },

    methods: {
        goBack() {
            this.$router.replace("/models");
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? "Novo Modelo" : "Detalhes";

        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Modelos",
                disabled: false,
                to: "/models",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? "Incluir modelo" : "Detalhes do modelo",
                disabled: true,
                exact: true,
            });
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/models/${this.$route.params.id}`);

                this.dataset.data = result.data.data;
            } catch (error) {
                console.log('error ', error)
            }
        },

        async add() {
            this.$refs.form.validate();

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o modelo?',
                title: 'Incluir modelo',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/models', payload);

                this.$dialog.notify.info("Modelo salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            this.$refs.form.validate();

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o modelo?',
                title: 'Atualizar modelo',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/models/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Modelo salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        buildPayload() {
            const data = {
                name: this.dataset.data.name,
                partNumber: this.dataset.data.partNumber.toString(),
                type: this.dataset.data.type,
                integration: this.dataset.data.integration,
                origin: this.dataset.data.origin,
                description: this.dataset.data.description,
                client: this.dataset.data.client,
                unit: this.dataset.data.unit,
            }

            return data;
        },

    },
};
</script>
  
<style lang="scss">

.theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
    color: #222D6C !important;
    font-weight: 500 !important;
}
</style>