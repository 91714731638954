<template>
    <svg width="24" :style="{ transform: `rotate(${rotation}deg)`, width: width + 'px', height: height + 'px'  }" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="05-vans-ok 1" clip-path="url(#clip0_849_215)">
            <g id="Camada_x0020_1">
                <path id="Vector"
                    d="M17.9514 25.774C16.0975 26.2713 15.4193 25.7739 14.8314 25.2765L0.72363 13.2488C-0.180718 12.5705 0.000107672 12.0731 0.000107672 10.9879V2.89391C0.000107672 2.44173 0.000107707 2.17052 0.904456 1.85399L6.19487 0.226125C7.23487 -0.180832 8.45574 0.0904504 9.134 0.768711L19.8958 9.99313C20.3479 10.4001 20.574 10.9427 21.0262 12.0279L23.7392 19.941C23.8749 20.3027 23.8749 20.7096 23.8749 21.207L23.9201 23.0156C23.9653 23.6487 23.5584 24.0105 22.8802 24.1913L17.9514 25.774Z"
                    :fill="color" />
                <path id="Vector_2"
                    d="M9.90254 13.4747L0.452148 5.42603V8.27474L10.2191 16.64C10.6713 17.0921 10.9426 17.0469 10.8521 16.3686L10.6261 14.9217C10.5356 14.3339 10.3547 13.9269 9.90254 13.4747Z"
                    fill="white" />
                <path id="Vector_3"
                    d="M23.1965 19.353L21.3878 14.0173C21.1617 14.5599 20.8903 14.5599 20.5286 14.6956L15.3286 16.2329C14.1529 16.5495 13.4747 16.2782 12.8869 16.0069L11.7564 15.0574C11.4399 14.7861 11.3043 14.9217 11.3947 15.4191L11.5756 16.9112C11.6661 17.4086 11.6208 17.6347 12.1634 18.2677L14.2434 20.5286C14.9669 21.2973 15.9616 21.8852 17.0469 21.7043L22.5182 19.986C22.88 19.8956 23.106 19.7147 23.1965 19.353Z"
                    fill="white" />
                <path id="Vector_4"
                    d="M17.1826 23.4227L17.4087 23.3774C17.5444 23.3774 17.6348 23.4679 17.6348 23.6035V25.0957C17.6348 25.2314 17.5444 25.3218 17.4087 25.3218L17.1826 25.3671C17.047 25.3671 16.9565 25.2766 16.9565 25.141V23.6488C16.9565 23.5584 17.047 23.4679 17.1826 23.4227Z"
                    fill="white" />
                <path id="Vector_5"
                    d="M23.2419 21.5687L23.4228 21.5235C23.5132 21.4783 23.6036 21.614 23.6036 21.7496V23.1965C23.6036 23.3322 23.5132 23.3774 23.4228 23.4226L23.2419 23.4678C23.1514 23.513 23.061 23.3773 23.061 23.2417V21.7948C23.061 21.7043 23.1514 21.6139 23.2419 21.5687Z"
                    fill="white" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_849_215">
                <rect width="23.9652" height="26" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
  
  
<script>
export default {
  name: 'VanIcon',
  props: {
    rotation: {
      type: Number,
      default: 0
    },
    color: {
        type: String,
        default: '#222D6C'
      },
    width: {
      type: Number,
      default: 30
    },
    height: {
      type: Number,
      default: 30
    }
  }
}
</script>