<template>
    <v-card outlined @click="detailEquipment(equipment)">
        <v-card-text>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="1" class="mr-4">
                    <v-icon :size="35">
                        mdi-broadcast
                    </v-icon>
                </v-col>
                <v-col>
                    <span>{{ equipment.serial }}</span>
                    <br />
                    <span>{{ equipment.model.name }}</span>
                </v-col>
                <v-col cols="12" sm="1" class="mr-4 ml-4">
                    <v-btn elevation="0" icon @click="$emit('remove-equipment')">
                        <v-icon :size="30">
                            mdi-delete-forever-outline
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-chip-group>
                        <v-chip v-for="(item, index) in equipment.peripherals" :key="index" class="custom-chip" color="gray"
                            label>
                            {{ item.model.type | enum(dataset.modelTypes, "id", "name") }} - {{
                                item.serial }}
                        </v-chip>
                    </v-chip-group>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import modelTypes from "@/common/data/model-types.data"

export default {
    name: "EquipmentAssociated",

    props: {
        equipment: {
            type: Object
        },
    },

    data: () => ({

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },

        dataset: {
            modelTypes: modelTypes
        }
    }),

    methods: {
        detailEquipment(equipment) {
            this.$router.push(`/equipments/${equipment.id}`);
        },
    }
};
</script>
  
<style lang="scss" scoped>
span {
    color: var(--v-mediumGray-base);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.page-title {
    font-size: 24px;
    text-transform: capitalize;
}
</style>