import Vue, { PluginObject } from "vue"; 
import * as jwt from "jwt-decode";


class PermissionsPlugin implements PluginObject<any>{

    install = (_Vue: typeof Vue, options?: any) => {
        
        _Vue.prototype.$check = function(path: string, methods: string[]){
            
            let token = JSON.parse(localStorage.getItem('token') || '{}');
            var data = jwt(token.access);

            return data.permissions.some(perm => {
                let regex = new RegExp(perm.path);
                return regex.test(path) && methods.some(method => perm.methods.includes(method.toLowerCase()))
            });
        }  
    };
    [key: string]: any;    
}

const plugin = new PermissionsPlugin;
Vue.use(plugin);
