<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Veículos</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Veículos',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn :disabled="!$check('vehicles/', ['POST'])" id="btnNew" type="button" color="secondary"
                            elevation="0" to="/vehicles/novo">Incluir</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-text-field id="prefix" label="Prefixo" v-model="dataList.filter.prefix" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field id="plate" label="Placa" v-model="dataList.filter.plate" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-select id="client" :items="dataset.clients" label="Cliente"
                                        v-model="dataList.filter.client" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field id="model" label="Modelo" v-model="dataList.filter.model" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select id="status" :items="dataset.status" label="Status"
                                        v-model="dataList.filter.status" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2"
                                        large>Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Veículos</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum veículo foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">

                            <template v-slot:[`item.plate`]="{ item }">
                                <span class="list-item-title">{{ item.plate }}</span><br />
                                <span class="list-item-subtitle">{{item.prefix}}</span>
                            </template>

                            <template v-slot:[`item.model`]="{ item }">
                                <span class="list-item-title">{{ item.model }}</span><br />
                                <span class="list-item-subtitle">{{ item.brand | enum(dataset.vehicleBrands, "id", "name")
                                }}</span>
                            </template>

                            <template v-slot:[`item.equipments`]="{ item }">
                                <span>{{ item.equipments ? item.equipments.length.toString().padStart(2, '0') : 0 }}</span>
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip label :text-color="getTextColor(item.status)" :color="getStatusColor(item.status)">
                                    {{ item.status | enum(dataset.status, "id", "name") }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon @click="viewItem(item)" size="large" class="me-2">
                                    mdi-qrcode
                                </v-icon>
                                <v-icon :disabled="!$check('vehicles/1', ['PATCH'])" @click="viewItem(item)" size="large"
                                    class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                                <v-icon :disabled="!$check('vehicles/1', ['DELETE'])" @click="remove(item)" size="large">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"
import string from "@/common/util/string"
import status from "@/common/data/status.data";
import vehicleBrands from "@/common/data/vehicle-brands.data";


Vue.use(VueLodash)

export default {
    name: "VehicleList",

    data: () => ({
        dataset: {
            status: [{ id: "", name: "Todos:" }, ...status],
            vehicleBrands: vehicleBrands,
            clients: [],
            unlockDialog: false
        },
        dataList: {
            filter: {
                plate: "",
                client: "",
                model: "",
                status: 0
            },
            headers: [
                { text: "Placa/Prefixo", value: "plate" },
                { text: "Cliente", value: "client.name" },
                { text: "Modelo/Marca", value: "model" },
                { text: "Ano", value: "year" },
                { text: "Equipamentos", value: "equipments" },
                { text: "Status", value: "status" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
        this.loadClients();
    },

    methods: {
        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.load();
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            if (search) {
                if (search.plate) search.plate = string.sanitizePlate(search.plate.toUpperCase())
            }

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/vehicles', { params: { start: start || 0, limit: limit, search: search } });

                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async loadClients() {
            try {
                const result = await axios.get('/clients', { params: { limit: 1000000, start: 0 } });

                this.dataset.clients = [{ id: "", name: "Todos" }, ...result.data.data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async remove(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja excluir o veículo?',
                title: 'Excluir veículo',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                await axios.delete(`/vehicles/${item.id}`)

                this.$dialog.notify.info("Veículo excluído com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`vehicles/${item.id}`);
        },

        getStatusColor(status) {
            return status === 1 ? '#222D6C12' : '#FB3A0412';
        },

        getTextColor(status) {
            return status === 1 ? '#222D6C' : '#FB3A04';
        },
    },
};
</script>