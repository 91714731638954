<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col cols="12">
                    <v-card class="filter-card">
                        <v-card-title class="filter-card-title">Filtro</v-card-title>
                        <v-card-text>
                            <v-form @submit.prevent="load()">
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-menu ref="dateRangeMenu" v-model="dateRangeMenu"
                                            :close-on-content-click="false" transition="scale-transition" offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-bind="attrs" v-on="on"
                                                    label="Data Início e Fim"
                                                    :value="dateRangeText" readonly outlined
                                                    append-icon="mdi-calendar">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="selectedDate" @change="updateDate"
                                                :max="dateRangeStep === 'end' ? null : endDate"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-menu ref="timeRangeMenu" v-model="timeRangeMenu"
                                            :close-on-content-click="false" transition="scale-transition" offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-bind="attrs" v-on="on"
                                                    label="Hora Início e Fim" :value="timeRangeText"
                                                    readonly outlined append-icon="mdi-clock">
                                                </v-text-field>
                                            </template>
                                            <v-time-picker v-model="selectedTime" :format="'24hr'"
                                                @change="updateTime"></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select id="type" :items="dataset.types" label="Tipo" v-model="dataList.filter.type"
                                            item-value="id" item-text="name" outlined hide-details="auto">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2" large>
                                            Filtrar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="table-card">
                        <v-card-title class="table-card-title">
                            <div class="title-container">
                                <div class="title-block">
                                    <span id="title" class="table-card-title">Eventos</span>
                                </div>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="dataList.headers" :items="dataList.data"
                                :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                                @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                                no-data-text="Nenhuma evento foi encontrado" loading-text="Carregando dados..."
                                class="app-table hover-table" :options.sync="dataList.options"
                                :footer-props="dataList.footerOptions">

                                <template v-slot:[`item.date`]="{ item }">
                                    <span>{{ formatDateForTable(item.date) }}</span>
                                </template>

                                
                                <template v-slot:[`item.hour`]="{ item }">
                                    <span>{{ formatHourForTable(item.date) }}</span>
                                </template>

                                <template v-slot:[`item.type`]="{ item }">
                                    <span>{{ getTypeName(item.type) }}</span>
                                </template>

                                <template v-slot:[`item.gateway`]="{ item }">
                                    <span>{{ item.metadata.gateway }}</span>
                                </template>

                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon @click="showModal(item)" size="large" >
                                        mdi-playlist-plus
                                    </v-icon>

                                </template>

                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <modal-metadata :equipment="dataset.equipmentSelected"></modal-metadata>
    </v-row>
</template>


<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import eventTypes from '@/common/data/event-type.data'
import axios from 'axios';

import moment from 'moment';
import { eventHub } from '@/main';
import ModalMetadata from './components/ModalMetadata.vue';

Vue.use(VueLodash)

export default {
    name: "EventList",
    components: {
        'modal-metadata': ModalMetadata
    },
    props: {
        model: null,
        equipment: {
            type: Object,
            default: () => ({})
        }
    },

    data: () => ({
        dataset: {
            status: [],
            types: [{ id: "", name: "Todos:" }, ...eventTypes],
            endTime: '',
            endDate: '',
            startTime: '',
            startDate: '',
            equipmentSelected: {},
        },
        endTime: '',
        endDate: '',
        startTime: '',
        startDate: '',
        selectedTime: '',
        selectedDate: '',
        dateRangeMenu: false,
        timeRangeMenu: false,
        timeRangeStep: 'start',
        dateRangeStep: 'start',
        dataList: {
            filter: {
                type: 0,
                client: "",
                status: 0,
                startDate: '',
                endDate: '',
                timeRange: '',
                dateRange: ''
            },
            headers: [
                { text: "Data", value: "date" },
                { text: "Horário", value: "hour" },
                { text: "Tipo", value: "type" },
                { text: "Gateway", value: "gateway" },
                { text: "Ações", value: "actions", sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
    },

    computed: {
        dateRangeText() {
            return `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
        },
        timeRangeText() {
            return `${this.formatTime(this.startTime)} - ${this.formatTime(this.endTime)}`;
        },
    },
    methods: {

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.load();
        },
        
        async load() {
            // Extract non-empty filter parameters
            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v));
            search = !_.isEmpty(search) ? search : null;

            // Set loading state
            this.dataList.loading = true;

            // Calculate pagination parameters
            const limit = this.dataList.options.itemsPerPage;
            const start = (this.dataList.options.page - 1) * limit;

                        // Format date and time for search
            if (search) {
                if (search.startDate && search.startTime) {
                    search.startDateTime = this.formatDateTime(search.startDate, search.startTime);
                    delete search.startDate;
                    delete search.startTime;
                }
                if (search.endDate && search.endTime) {
                    search.endDateTime = this.formatDateTime(search.endDate, search.endTime);
                    delete search.endDate;
                    delete search.endTime;
                }
            }

            try {
                const result = await axios.get(`/equipments/${this.equipment.id}/events`, {
                    params: { start: start, limit: limit, search: search }
                });

                this.dataList.data = result.data.data[0];
                this.dataList.count = result.data.data[1];
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {

                this.dataList.loading = false;
            }
        },
        formatTime(time) {
            if (!time) return '';
            return moment(time, 'HH:mm').format('HH:mm');
        },
        formatDate(date) {
            if (!date) return '';
            return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
        },
        formatDateTime(date, time) {
            return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
        },
        updateTime() {
            if (this.timeRangeStep === 'start') {
                this.startTime = this.selectedTime;
                this.dataset.startTime = this.selectedTime;
                this.dataList.filter.startTime = this.selectedTime
                this.timeRangeStep = 'end';
                this.selectedTime = '';
            } else {
                this.endTime = this.selectedTime;
                this.dataset.endTime = this.selectedTime;
                this.dataList.filter.endTime = this.selectedTime
                this.timeRangeMenu = false;
                this.timeRangeStep = 'start';
            }
        },
        updateDate() {
            if (this.dateRangeStep === 'start') {
                this.startDate = this.selectedDate;
                this.dataset.startDate = this.selectedDate;
                this.dataList.filter.startDate = this.selectedDate
                this.dateRangeStep = 'end';
                this.selectedDate = '';
            } else {
                this.endDate = this.selectedDate;
                this.dataset.endDate = this.selectedDate;
                this.dataList.filter.endDate = this.selectedDate;
                this.dateRangeMenu = false;
                this.dateRangeStep = 'start';
            }   
        },

        formatDateForTable(date) {
            return moment(date).format('DD.MM.YYYY');
        },

        formatHourForTable(date) {
            return moment(date).format('HH:mm:ss');
        },

        getTypeName(type) {    
            return this.dataset.types.find(t => t.id === type).name;
        },

        showModal(equipment) {
            this.dataset.equipmentSelected = equipment;
            eventHub.$emit("EQUIPMENT-MODAL", true);
        },
    },
};
</script>