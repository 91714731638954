<template> 
  <svg :style="{ transform: getTransform, width: width + 'px', height: height + 'px' }" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="03-carro-ok 1" clip-path="url(#clip0_849_201)">
      <g id="Camada_x0020_1">
        <path id="Vector"
          d="M13.5 17.729C12.0361 18.1627 10.6265 18.1627 9.86746 17.1868L0.487912 8.18677C-0.0542567 7.75304 0.0542829 7.3193 0.0542829 6.56027L0.16269 3.14471C0.216907 2.60254 0.433774 2.43981 1.24703 2.11451L8.13254 0.054244C8.9458 2.71648e-05 9.7048 -0.0541897 10.6807 0.596413L9.81332 0.921767L12.3072 0.216921L15.5602 2.92776C17.1867 4.28319 17.6747 5.58436 18.1627 6.83135C18.6506 8.13256 19.1928 9.05424 20.0603 9.81328C20.7651 10.3554 20.7651 10.5181 20.7651 12.1446L20.7108 14.8555C20.6566 15.4519 20.006 15.8314 19.247 16.0482L13.5 17.729Z"
          :fill="color" />
        <path id="Vector_2"
          d="M12.3614 14.4216L12.6866 14.3674C12.8493 14.3674 13.012 14.53 13.012 14.6927L12.9577 16.6445C12.9577 16.8072 12.7952 16.9157 12.6325 16.9699L12.3072 17.024C12.1445 17.024 11.9819 16.8614 11.9819 16.6988L12.0361 14.7469C12.0361 14.5843 12.1988 14.4758 12.3614 14.4216Z"
          fill="white" />
        <path id="Vector_3"
          d="M19.8434 12.1989L20.0602 12.0905C20.1687 12.0363 20.277 12.2531 20.277 12.4157L20.2229 14.3676C20.2229 14.5303 20.1145 14.6386 19.9518 14.6928L19.7349 14.8014C19.6264 14.8556 19.5181 14.6387 19.5181 14.476L19.5723 12.5242C19.6266 12.3615 19.735 12.2531 19.8434 12.1989Z"
          fill="white" />
        <path id="Vector_4"
          d="M7.59022 9.2712C8.07817 10.2471 8.13233 10.7893 7.80703 10.5182L2.2228 5.69297C1.95171 5.42188 1.95163 5.09653 2.00585 4.82545V4.06646C2.00585 3.57851 2.11429 3.41586 2.54802 3.79538L5.90944 6.6689C6.72269 7.48215 7.10226 8.13265 7.59022 9.2712Z"
          fill="white" />
        <path id="Vector_5"
          d="M17.7291 7.69874C17.9459 8.18669 17.8375 8.34929 17.5122 8.45772L10.193 10.6264C9.43393 10.8975 9.21704 10.8433 8.94596 10.1385C8.02427 7.96983 7.86162 7.26498 6.23511 5.90956L3.19892 3.25299C3.03627 3.03612 2.76521 2.9819 2.54834 2.9819L11.6025 0.325195L15.2352 3.4698C15.8858 4.06619 16.3195 4.66257 16.699 5.36739L17.7291 7.69874Z"
          fill="white" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_849_201">
        <rect width="20.6566" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
  
<script>
  import Vue from 'vue';
  export default {
    name: 'CarIcon',
    props: {
      rotation: {
        type: Number,
        default: 0
      },
      color: {
        type: String,
        default: '#222D6C'
      },
      width: {
        type: Number,
        default: 30
      },
      height: {
        type: Number,
        default: 30
      }
    },
    computed: {
      getTransform() {
        const baseRotation = this.rotation;
        const flip = false;
        return flip ? `rotate(${baseRotation}deg) scaleX(-1)` : `rotate(${baseRotation}deg)`;
      }
    }
  }
</script>