import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { pt } from 'vuetify/lib/locale'
import { en } from 'vuetify/lib/locale'

import TruckIcon from '@/components/icons/TruckIcon.vue'
import CarIcon from '@/components/icons/CarIcon.vue'
import PickupIcon from '@/components/icons/PickupIcon.vue'
import VanIcon from '@/components/icons/VanIcon.vue'
import HatchIcon from '@/components/icons/HatchIcon.vue'
import BusIcon from '@/components/icons/BusIcon.vue'
import MotorcycleIcon from '@/components/icons/MotorcycleIcon.vue'

Vue.use(Vuetify)

export default new Vuetify({
  // lang: {
  //   current: 'pt'
  // },
  lang: {
    locales: {
      pt,
      en
    },
    current: 'pt'
  },
  icons: {
    iconfont: 'mdi',

    values: {
      'truck-icon': {
        component: TruckIcon
      },
      'car-icon': {
        component: CarIcon
      },
      'pickup-icon': {
        component: PickupIcon
      },
      'van-icon': {
        component: VanIcon
      },
      'hatch-icon': {
        component: HatchIcon
      },
      'bus-icon': {
        component: BusIcon
      },
      'motorcycle-icon': {
        component: MotorcycleIcon
      }
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#222D6C',
        secondary: '#01BCEF',
        gray: '#F7F7F7',
        lightGray: '#EFEFEF',
        black: '#000000',
        mediumGray: '#959595',
        darkGray: '#9E9E9E',
        important: '#FB3A04',
        lightImportant: '#FEF2F2',
        yellow: '#FFB703',
        white: '#FFFFFF',
        accent: '#AAD3C7',
        error: '#FF5252'
      }
    }
  }
})
