<template>
  <div>
    <div class="header-mobile">
      <div class="logo-container">
        <img v-if="!inMap" class="logo" src="@/assets/logo-pilgrim.svg"/>

        <div class="icon-box" v-else>
          <img class="icon" src="@/assets/icon-pilgrim.svg"/>
        </div>
      </div>
      <div class="avatar-box">
        <v-menu offset-y v-if="!inMap">
          <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on">
                  <!-- <img :src="'https://ui-avatars.com/api/?background=%2301bcef&name=' + user.name" /> -->
                  <img :src="'https://ui-avatars.com/api/?name=' + user.name + '&size=108&font-size=0.5&color=222D6C&background=efefef&rounded=true'" class="avatar-border" />
              </v-avatar>
            </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

          <template v-else>
            <v-avatar @click="handleAvatarClick">
                <!-- <img :src="'https://ui-avatars.com/api/?background=%2301bcef&name=' + user.name" /> -->
                <img :src="'https://ui-avatars.com/api/?name=' + user.name + '&size=108&font-size=0.5&color=222D6C&background=efefef&rounded=true'" class="avatar-border" />
            </v-avatar>
          </template>
      </div>
    </div>


    <div class="counter-box" v-if="inMap">
      <div class="counter">

        <v-icon v-if="counter == -1" color="#222D6C" class="icon-pulse">
          mdi-cloud-arrow-down-outline
      </v-icon>

      <v-progress-circular v-else
          rotate="-90"
          size="40"
          width="2"
          :value="progressPercent"
          color="primary"
      >
          {{ counter }}
      </v-progress-circular>

      </div>
    </div>

  </div>

  </template>
  
  <script>
  export default {
    name: 'HeaderMobile',
    props: {
      inMap: {
        type: Boolean,
        default: false
      },
      handleSheet: {
        type: Function,
        required: false
      },
      counter: {
          type: Number,
          required: false
        },
      waitTime: {
        type: Number,
        required: false
      }
    },
    data() {
      return {
        user: {
          name: '',
          email: ''
        }
      };
    },
    created() {
      this.user = this.$root.user;
    },
    computed: {
        progressPercent() {
            return (this.counter / (this.waitTime / 1000)) * 100;
        }
    },
    methods: {
      logout() {
        localStorage.clear();
        this.$router.push("/login");
        setTimeout(() => {
          window.location.reload();
        }, 50);
      },
      handleAvatarClick() {
        if (this.inMap) {
          this.handleSheet('settings');
        }
      }
    }
  }
  </script>
  
  <style scoped>

  .header-mobile {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    color: white;
    padding: 70px 40px;
  }
  
  .logo-container {
    display: flex;
    align-items: center;

  }
  
  .logo {
    height: 50px;
  }

  .icon-box{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
  }

  .avatar-box{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
  }


  .icon{
    height: 70px;
  }
  
  .counter-box{
    width: 100%;
    display: flex;
    padding: 0 40px;
    top: -60px;
    position: relative; 
    justify-content: flex-end;
  }
  .counter{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
  }
  
  </style>