<template>
    <v-dialog v-model="dataset.opened" max-width="600">
        <div class="modal">
            <v-card>
                <v-card-title class="headline title-modal">
                    Histórico
                    <v-spacer></v-spacer>
                    <v-icon @click="closeModal" class="close-icon" color="#222D6C">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="box-info-text">
                            <span class="info-text">Estamos exibindo as atualizações logo abaixo. As mudanças realizadas estão destacadas.</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div class="json-metadata" v-html="highlightedDataNew"></div>
                        </v-col>
                    </v-row>
        
                </v-card-text>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
import Vue from "vue";
import { eventHub } from '@/main';
import { diffLines } from 'diff';

export default {
    name: "ModalHistory",
    props: {
        history: Object,
    },
    data: () => ({
        dataset: {
            opened: false,
        },
    }),
    created() {
        eventHub.$on("HISTORY-DETAIL", (value) => {
            this.dataset.opened = value;
        });
    },
    computed: {
        highlightedDataNew() {
            return this.getHighlightedDiff(this.history.dataNew, this.history.dataOld);
        },
    },
    methods: {
        closeModal() {
            this.dataset.opened = false;
            eventHub.$emit("HISTORY-DETAIL", false);
        },
        filterFields(data) {
            const { id, date, deletedAt, ...filteredData } = data;
            return filteredData;
        },
        getHighlightedDiff(dataNew, dataOld) {
            if (!dataNew || !dataOld) {
                return '<pre></pre>';
            }

            // Filtra os campos indesejados
            const filteredDataNew = this.filterFields(dataNew);
            const filteredDataOld = this.filterFields(dataOld);

            const diff = diffLines(JSON.stringify(filteredDataOld, null, 2), JSON.stringify(filteredDataNew, null, 2));
            let diffHTML = '<pre>';

            diff.forEach(part => {
                const color = part.added ? 'highlight' : part.removed ? 'removed' : '';
                diffHTML += `<span class="${color}">${part.value}</span>`;
            });

            diffHTML += '</pre>';
            return diffHTML;
        }
    },
};
</script>

<style lang="scss">
.v-overlay--active>div {
   opacity: 0.9 !important;
   background-color: #222D6C !important;
}
.modal{
    .theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
        color: #222D6C !important;
        font-weight: 500 !important;
    }

    .close-icon {
        cursor: pointer;
    }

    .title-modal{
        color: #222D6C !important;
        font-weight: 700 !important;
        font-size: 32px !important;
        text-transform: none !important;
    }

    .v-application .headline {
        font-family: Montserrat !important;
    }

    .info-text {
        color: #959595;
        font-size: 15px !important;
        font-weight: 400 !important;
        font-family: Montserrat !important;
    }

    .container-buttons{
        width: 100%;
        display: flex;
        justify-content: right;
    }

    .json-metadata {
        width: 100%;
        height: auto;
        padding: 20px;
        color: #222D6C;
        font-size: 14px !important;
        font-weight: 600 !important;
        background-color: #F5F5F5;
        font-family: Montserrat !important;
    }


    .highlight {
        background-color: yellow;
    }

    .removed {
       background-color: #FF5252;

    }

}


body{
    
    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--v-primary-base);
        border-radius: 10px;
    }
}
</style>