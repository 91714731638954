<template>
    <v-dialog v-model="dialog.show" :max-width="500" @keydown.esc="cancel">
        <v-form ref="form" v-model="dataForm.validForm">
            <v-card>
                <v-card-title>
                    <div class="title-container">
                        <div class="title-block">
                            <span id="title" class="page-title">Adicionar Link</span>
                        </div>
                        <div class="button-group">
                            <v-btn id="btnNew" icon type="button" color="mediumGray" small elevation="0"
                                @click="dialog.show = false">

                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field id="name" label="Nome" v-model="dataset.data.name" hide-details="auto" outlined
                                required :rules="[$rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field id="url" label="URL" v-model="dataset.data.url" hide-details="auto" outlined
                                required :rules="[$rules.required, $rules.url]"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <div class="button-group">
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
                        <v-btn color="secondary" @click.native="add" :loading="dataForm.addLoading">Adicionar</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>
  
  
<script>

import _ from 'lodash';
import axios from 'axios';

export default {
    name: "FileSelection",

    props: {
        association: null,
        type: null
    },

    data: () => ({

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },
        dataset: {
            data: {},
        },
        dataForm: {
            validForm: true,
            addLoading: false,
        },
    }),

    methods: {

        open() {
            this.dialog.show = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        async add() {
            this.$refs.form.validate();

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o link?',
                title: 'Incluir link',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/links', payload);

                this.$dialog.notify.info("Link salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.resolve();

                this.dialog.show = false;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        buildPayload() {
            const data = {
                name: this.dataset.data.name,
                url: this.dataset.data.url,
                association: {
                    type: this.type,
                    association: this.association
                }
            }

            return data;
        },

        cancel() {
            this.reject();
            this.dialog.show = false;
        },

    },
};
</script>
  
<style lang="scss" scoped>
.device-block {
    display: flex;
}

.device-card-label {
    font-size: 14px;
    margin-left: 12px;

    .line1 {
        font-weight: 500;
        font-size: 16px;
    }
}

.page-title {
    font-size: 24px;
    text-transform: capitalize;
}
</style>