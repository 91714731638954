<template>
    <v-container class="py-7">
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field id="name" label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field id="registration" label="CNPJ"
                                        v-model="dataList.filter.registrationNumber" hide-details outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field id="city" label="Cidade" v-model="dataList.filter.addressCity"
                                        hide-details outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select id="status" :items="dataset.clientStatus" label="Status"
                                        v-model="dataList.filter.status" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="secondary" dark elevation="2"
                                        large>Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title>
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title">Unidades</span>
                            </div>
                            <div class="button-group">
                                <v-btn :disabled="isClient()" id="btnNew" type="button" color="secondary" elevation="0"
                                    @click="viewItem(null)">Incluir unidade</v-btn>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhuma unidade foi encontrada" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">
                            <template v-slot:[`item.name`]="{ item }">
                                <span class="list-item-title">{{ item.name }}</span><br />
                                <span class="list-item-subtitle">{{ item.type | enum(dataset.clientTypes, "id", "name")
                                }}</span>
                            </template>
                            <template v-slot:[`item.contactPhone`]="{ item }">
                                <span>{{ item.contactPhone | VMask($masks.phone) }}</span>
                            </template>

                            <template v-slot:[`item.registrationNumber`]="{ item }">
                                <span>{{ item.registrationNumber | VMask($masks.cnpj) }}</span>
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip label text-color="primary">
                                    {{ item.status | enum(dataset.clientStatus, "id", "name") }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.vehicles`]="{ item }">
                                <span>{{ item.vehicles ? item.vehicles : 0 }}</span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon @click="viewItem(item)" size="large" class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                                <template v-if="!isClient()">
                                    <v-icon @click="remove(item)" size="large">
                                    mdi-delete-outline
                                    </v-icon>
                                </template>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>

// import clientStatus from "@/common/data/client-status.data";
import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"
import user from "@/common/util/identify"
import clientTypes from "@/common/data/client-types.data";
import clientStatus from "@/common/data/client-status.data";

Vue.use(VueLodash)

export default {
    name: "UnitList",

    props: {
        client: null
    },

    data: () => ({
        dataset: {
            clientTypes: [{ id: "", name: "Todos: " }, ...clientTypes],
            clientStatus: [{ id: "", name: "Todos:" }, ...clientStatus],
        },
        dataList: {
            filter: {
                name: "",
                registrationNumber: "",
                city: "",
                status: ""
            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "CNPJ", value: "registrationNumber" },
                { text: "Fone", value: "contactPhone" },
                { text: "Cidade", value: "addressCity" },
                { text: "Veículos", value: "vehicles" },
                { text: "Status", value: "status" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
    },

    methods: {
        clientPageText() {
            return `${this.dataList.paging.page} de ${this.dataList.paging.pages}`;
        },

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.list();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.list(true);
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),
        reload: function () {
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/units', { params: { start: start, limit: limit, search: search, client: this.client } });

                this.dataList.data = result.data.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        isClient() {
            const { client } = user.identify();
            return !!client;
        },

        async remove(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja excluir a unidade?',
                title: 'Excluir unidade',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                const result = await axios.delete(`/units/${item.id}`)

                this.$dialog.notify.info("Unidade excluída com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`${this.client}/units/${!item ? 'novo' : item.id}`);
        },
    },
};
</script>