<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="secondary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn :disabled="isClient()" id="btnSave" type="button" elevation="0" color="secondary" @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            @click="goBack">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="name" label="Nome fantasia" v-model="dataset.data.name"
                                                required :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" label="Razão Social" v-model="dataset.data.tradeName" required
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="accountNumber" label="Código ERP"
                                                v-model="dataset.data.accountNumber" outlined hide-details="auto" disabled>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="CNPJ" label="CNPJ" v-model="dataset.data.registrationNumber"
                                                v-mask="$masks.cnpj" required :rules="[$rules.required, $rules.cnpj]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            <v-text-field :disabled="isClient()" id="clientIE" label="Inscrição estadual" v-model="dataset.data.ie"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            <v-text-field :disabled="isClient()" id="clientIM" label="Inscrição municipal"
                                                v-model="dataset.data.im" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="8">
                                            <v-textarea :disabled="isClient()" id="description" label="Dados adicionais"
                                                v-model="dataset.data.description" outlined hide-details="auto" rows="1"
                                                auto-grow></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select :disabled="isClient()" id="type" :items="dataset.clientTypes" label="Tipo"
                                                v-model="dataset.data.type" item-value="id" item-text="name" outlined
                                                hide-details="auto" v-on:change="changeType">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>Endereço</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="4" sm="3">
                                            <v-text-field :disabled="isClient()" id="addressZipCode" label="CEP" maxlength="9"
                                                v-mask="$masks.zipCode" v-model="dataset.data.addressZipCode" outlined
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field :disabled="isClient()" id="addressStreet" label="Endereço" maxlength="120"
                                                v-model="dataset.data.addressStreet" outlined
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col :cols="4">
                                            <v-text-field :disabled="isClient()" id="addressNumber" label="Número"
                                                v-model="dataset.data.addressNumber" maxlength="10" outlined
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col :cols="8">
                                            <v-text-field :disabled="isClient()" id="addressComplement" label="Complemento"
                                                v-model="dataset.data.addressComplement" maxlength="30" outlined
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="addressDistrict" label="Bairro" maxlength="40"
                                                v-model="dataset.data.addressDistrict" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="addressCity" label="Cidade" maxlength="60"
                                                v-model="dataset.data.addressCity" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select :disabled="isClient()" id="addressState" :items="dataset.states" label="Estado"
                                                v-model="dataset.data.addressState" item-value="id" item-text="name"
                                                outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>{{ dataset.data.type === 3 ? 'Contato' : 'Responsável' }}</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="contactName" label="Nome" v-model="dataset.data.contactName"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="contactPhone" label="Telefone" v-mask="$masks.phone"
                                                v-model="dataset.data.contactPhone" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field :disabled="isClient()" id="contactEmail" type="email" label="E-mail"
                                                v-model="dataset.data.contactEmail" :rules="[$rules.email]" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
  
<script>
import unitTypes from "@/common/data/unit-types.data"
import states from "@/common/data/states.data"
import string from "@/common/util/string"
import user from "@/common/util/identify"

import axios from "axios"

export default {
    name: "UnitDetails",

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            clientTypes: unitTypes,
            states: states,
            data: {},
            isViewUnits: false,
        },
        clientId: undefined,
        dataForm: {
            files: {},
            validForm: true,
            addLoading: false,
            created: null,
        },
    }),

    created() {
        this.setPage();
        this.listBreadCrumbItems();
        this.load();
    },

    methods: {
        goBack() {
            this.$router.replace(`/clients/${this.clientId}`);
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? "Nova unidade" : "Detalhes da unidade";

            this.clientId = this.$route.params.clientId
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Clientes",
                disabled: false,
                to: "/clients",
                exact: false,
            })

            this.dataset.breadcrumbItems.push({
                text: "Detalhes do cliente",
                disabled: false,
                to: `/clients/${this.clientId}`,
                exact: false,
            })

            this.dataset.breadcrumbItems.push({
                text: "Unidades",
                disabled: false,
                to: `/clients/${this.clientId}`,
                exact: false,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? "Incluir unidade" : "Detalhes da unidade",
                disabled: true,
                exact: true,
            });
        },

        onLogoSelected(file) {
            this.dataForm.files.logo = file;
        },

        onBackgroundSelected(file) {
            this.dataForm.files.background = file;
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/units/${this.$route.params.id}`);

                this.dataset.data = result.data.data;

                this.page.title = this.dataset.data.name
            } catch (error) {
                console.log('error ', error)
            }
        },

        async add() {
            if(!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir a unidade?',
                title: 'Incluir unidade',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buidPayload();

            try {
                const response = await axios.post('/units', payload);

                this.$dialog.notify.info("Unidade salva com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if(!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar a filial?',
                title: 'Atualizar filial',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buidPayload();

            try {
                const response = await axios.patch(`/units/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Unidade salva com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        buidPayload() {
            const data = {
                name: this.dataset.data.name,
                tradeName: this.dataset.data.tradeName,
                ie: this.dataset.data.ie,
                im: this.dataset.data.im,
                type: this.dataset.data.type,
                client: this.clientId,
                registrationNumber: string.sanitizeCpfCnpj(this.dataset.data.registrationNumber),
                description: this.dataset.data.description,
                contactName: this.dataset.data.contactName,
                contactPhone: string.sanitizePhone(this.dataset.data.contactPhone),
                contactEmail: this.dataset.data.contactEmail,
                addressStreet: this.dataset.data.addressStreet,
                addressNumber: this.dataset.data.addressNumber,
                addressZipCode: this.dataset.data.addressZipCode,
                addressDistrict: this.dataset.data.addressDistrict,
                addressCity: this.dataset.data.addressCity,
                addressState: this.dataset.data.addressState,
                addressComplement: this.dataset.data.addressComplement,
                status: this.dataset.data.status
            }

            return data;
        },

        changeType(event) {
            this.selectedType = event.target.value;
        },

        isClient() {
            const { client } = user.identify();
            return !!client;
        }
    },
};
</script>
  
<style lang="scss"></style>