<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn disabled v-if="!this.page.isNew && !isClient()" id="btnSave" type="button" elevation="0" color="primary">Chamar Técnico</v-btn>
                        
                        <v-btn :disabled="!$check('vehicles/', ['POST'])" id="btnSave" type="button" elevation="0" color="secondary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn :disabled="!$check('vehicles/1', ['PATCH'])" id="btnSave" type="button" elevation="0" color="secondary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>

                        <v-btn v-if="!page.isNew && equipmentModel == 5 || equipmentModel == 4" id="btnBack" type="button" elevation="0"
                        :color="ColorUtils.setColorButton(dataset.equipment.model.type, dataset.equipment.status)" @click="handleModal(dataset.data)" >{{setInfoButton(dataset.equipment.model.type, dataset.equipment.status)}}</v-btn>
                            
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined @click="goBack">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="button-group">
                        <v-btn :color="!dataset.isViewFileTab ? 'secondary' : 'mediumGray'" outlined
                            @click="dataset.isViewFileTab = false">
                            Detalhes

                            <v-icon right>mdi-text-long</v-icon>

                        </v-btn>
                        <v-btn v-if="!page.isNew" outlined @click="dataset.isViewFileTab = true"
                            :color="dataset.isViewFileTab ? 'secondary' : 'mediumGray'">
                            Anexos
                            <v-icon right>mdi-paperclip</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-form v-if="!dataset.isViewFileTab" ref="form" v-model="dataForm.validForm" class="mt-7">
            <v-row>
                <v-col cols="12" sm="8">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Informações</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="prefix" label="Prefixo" v-model="dataset.data.prefix" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="plate" label="Placa" v-model="dataset.data.plate" required
                                                v-mask="$masks.plate" :rules="[$rules.required]" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select id="brand" :items="dataset.vehicleBrands" label="Marca" required
                                                :rules="[$rules.required]" v-model="dataset.data.brand" item-value="id"
                                                item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="model" label="Modelo" v-model="dataset.data.model" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field id="year" label="Ano" v-model="dataset.data.year" required
                                                v-mask="$masks.year" :rules="[$rules.required]" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select id="type" :items="dataset.vehicleTypes" label="Tipo" required
                                                :rules="[$rules.required]" v-model="dataset.data.type" item-value="id"
                                                item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-select id="icon" :items="dataset.vehicleIcons" label="Ícone" required
                                                :rules="[$rules.required]" v-model="dataset.data.icon" item-value="id"
                                                item-text="name" outlined hide-details="auto">

                                                <template #selection="{ item }">
                                                    <v-icon color="primary">$vuetify.icons.{{ item.icon }}</v-icon>
                                                    <span class="ml-4">{{ item.name }}</span>
                                                </template>

                                                <template #item="{ item }">
                                                    <v-icon color="primary">$vuetify.icons.{{ item.icon }}</v-icon>
                                                    <span class="ml-4">{{ item.name }}</span>
                                                </template>

                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select id="status" :items="dataset.status" label="Status" required
                                                :rules="[$rules.required]" v-model="dataset.data.status" item-value="id"
                                                item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea id="description" label="Dados Adicionais"
                                                v-model="dataset.data.description" outlined hide-details="auto" rows="1"
                                                auto-grow></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Cliente</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-select id="client" :items="dataset.clients" label="Cliente" required
                                                :rules="[$rules.required]" v-on:change="changeClient"
                                                v-model="dataset.data.client" item-value="id" item-text="name" outlined
                                                hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select id="unit" :items="dataset.units" label="Unidade" required
                                                :rules="[$rules.required]" v-model="dataset.data.unit" item-value="id"
                                                item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title class="card-group-title">
                                    <div class="title-container">
                                        <span class="table-card-title">Equipamentos</span>
                                        <v-btn type="button" color="secondary" small elevation="0"
                                            @click="openEquipmentSelectionModal()">Associar</v-btn>
                                    </div>
                                </v-card-title>
                                <v-card-text class="d-flex flex-row">
                                    <v-row no-gutters v-if="dataset.data.equipments.length">
                                        <template v-for="(equipment, index) in  dataset.data.equipments ">
                                            <v-col :key="index" cols="12" sm="6">
                                                <equipment-associated :equipment="equipment"
                                                    @remove-equipment="removeEquipment(index)"></equipment-associated>

                                            </v-col>
                                        </template>
                                    </v-row>
                                    <v-row no-gutters v-else>
                                        <v-col cols="12" sm="6">
                                            <span>Nenhum equipamento associado</span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card color="theme-card">
                                <v-card-title class="card-group-title">Histórico</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <event-table 
                                                :history="history" 
                                                @update-history-selected="updateHistorySelected"
                                                @show-history-modal="showHistroyModal"
                                            ></event-table>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="card-form-outlined">
                        <v-card-title class="card-group-title">
                            <div class="title-container">
                                <div class="title-block">
                                    <span class="table-card-title">Grupos</span>
                                </div>
                                <div class="button-group">
                                    <v-btn disabled type="button" color="gray" small elevation="0">Associar</v-btn>
                                </div>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-card outlined>
                                        <v-card-text>
                                            <v-row>
                                                <v-col>
                                                    <span>Grupos Associados</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-chip-group>
                                                        <!-- <v-chip class="custom-chip" color="gray" label close
                                                            close-icon="mdi-close">test 1</v-chip> -->
                                                    </v-chip-group>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>

        <equipment-selection ref="equipmentModal" :type="1" :client="clientId"></equipment-selection>

        <file-list class="py-7" v-if="dataset.isViewFileTab"></file-list>

        <link-list v-if="dataset.isViewFileTab" :association="dataset.vehicleId" :type="1"></link-list>

        <!-- Modal para bloqueio -->
        <modal-block :vehicle="dataset.data" :equipment="dataset.equipment"></modal-block>
        <!-- Modal para dormir -->
        <modal-sleep :vehicle="dataset.data" :equipment="dataset.equipment"></modal-sleep>
        <!-- Modal para acordar -->
        <modal-wakeup :vehicle="dataset.data" :equipment="dataset.equipment"></modal-wakeup>
        <!-- Modal para desbloqueio -->
        <modal-unblock :vehicle="dataset.data" :closeWindow="closeInfoWindow" :equipment="dataset.equipment"></modal-unblock>

        <modal-history :history="dataset.historySelected" />

    </v-container>
</template>
  
<script>

import { eventHub } from '@/main';
import vehicleBrands from "@/common/data/vehicle-brands.data"
import vehicleIcons from "@/common/data/vehicle-icons.data"
import vehicleTypes from "@/common/data/vehicle-types.data"
import status from "@/common/data/status.data"
import string from "@/common/util/string"

import user from "@/common/util/identify"
import EventTable from "@/components/event/EventTable"
import EquipmentSelection from "@/components/equipment/EquipmentSelection"
import EquipmentAssociated from "@/components/equipment/EquipmentAssociated"
import FileList from "@/components/file/FileList"
import LinkList from "@/components/link/LinkList"

import ModalBlock from "@/views/vehicle/components/ModalBlock.vue"
import ModalSleep from "@/views/vehicle/components/ModalSleep.vue"
import ModalWakeup from "@/views/vehicle/components/ModalWakeup.vue"
import ModalUnblock from "@/views/vehicle/components/ModalUnblock.vue"
import ModalHistroy from "@/components/event/components/ModalHistory.vue"

import axios from "axios"

import ColorUtils from "@/common/util/ColorUtils";

export default {
    name: "VehicleDetails",

    components: {
        'modal-history': ModalHistroy,
        'link-list': LinkList, 
        'file-list': FileList, 
        'event-table': EventTable,
        'modal-block': ModalBlock,
        'modal-sleep': ModalSleep,
        'modal-wakeup': ModalWakeup,
        'modal-unblock': ModalUnblock,
        'equipment-selection': EquipmentSelection, 
        'equipment-associated': EquipmentAssociated 
    },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        ColorUtils,
        dataset: {
            historySelected: {},
            breadcrumbItems: [],
            vehicleTypes: vehicleTypes,
            vehicleBrands: vehicleBrands,
            vehicleIcons: vehicleIcons,
            status: status,
            models: [],
            clients: [],
            units: [],
            isViewFileTab: false,
            vehicleId: null,
            equipment: null,
            data: {
                status: 0,
                origin: 1,
                equipments: []
            },
        },
        history: [],
        equipmentModel: null,
        equipmentStatus: null,
        clientId: undefined,
        dataForm: {
            files: {},
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            created: null,
        },
    }),

    created() {
        this.setPage();
        this.listBreadCrumbItems();
        this.loadData();

        if(!this.page.isNew) {
            this.listHistory();
        }
    },

    methods: {
        goBack() {
            // this.$router.replace("/vehicles");
            window.history.back();
        },

        updateHistorySelected(history){
            this.dataset.historySelected = history;
        },

        showHistroyModal() {
            eventHub.$emit("HISTORY-DETAIL", true);
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? "Novo Veículo" : "Detalhes";

            if (!this.page.isNew) this.dataset.vehicleId = this.$route.params.id
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Veículos",
                disabled: false,
                to: "/vehicles",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? "Incluir veículo" : "Detalhes do Veículo",
                disabled: true,
                exact: true,
            });
        },

       async changeClient(client) {
            this.dataset.data.unit = null;
            this.dataset.units = [];
           

            if (client) {
                this.clientId = client;
                await this.loadUnits(client);
            } 
        },

        async listHistory() {
            try {
                const result = await axios.get(`/history`, { params: { start: 0, limit: 1000000, entity: this.$route.params.id, entityType: 1 } });
                this.history = result.data.data;
            } catch (error) {
                console.log('error ', error)
            }
        },

        async loadData() {

            await this.load();
            await this.loadClients();
            if (!this.page.isNew) {
                this.clientId = this.dataset.data.client;
                await this.loadUnits(this.dataset.data.client);
            }
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/vehicles/${this.$route.params.id}`);

                this.dataset.data = result.data.data;
                const equipment = this.dataset.data.equipments.filter((e) => e.model.type === 5 || e.model.type === 4);
                if(equipment.length) {
                    this.dataset.equipment = equipment[0];
                    this.equipmentModel = this.dataset.equipment.model.type;
                    this.equipmentStatus = this.dataset.equipment.status;
                }
            } catch (error) {
                console.log('error ', error)
            }
        },

        async loadUnits(client) {
            try {
                const result = await axios.get('/units', { params: { limit: 1000000, client } });

                this.dataset.units = result.data.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async loadClients() {
            try {
                const result = await axios.get('/clients', { params: { start: 0, limit: 1000000 } });

                this.dataset.clients = result.data.data;

                if (this.dataset.data.client != null) this.loadUnits(this.dataset.data.client);

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }
        },

        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o veículo?',
                title: 'Incluir veículo',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/vehicles', payload);

                this.$dialog.notify.info("Veículo salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o veículo?',
                title: 'Atualizar veículo',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/vehicles/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Veículo salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        buildPayload() {
            const data = {
                plate: string.sanitizePlate(this.dataset.data.plate.toUpperCase()),
                prefix: this.dataset.data.prefix,
                brand: this.dataset.data.brand,
                model: this.dataset.data.model,
                year: this.dataset.data.year,
                type: this.dataset.data.type,
                icon: this.dataset.data.icon,
                status: this.dataset.data.status,
                description: this.dataset.data.description,
                client: this.dataset.data.client,
                unit: this.dataset.data.unit,
                equipments: this.dataset.data.equipments.map((x) => x.id)
            }

            return data;
        },

        openEquipmentSelectionModal() {
            if (!this.clientId){
                this.$dialog.notify.info('Selecione o cliente', { position: 'top-right', timeout: 5000 });
                return;
            }
            this.$refs.equipmentModal.open().then((equipments) => {
                this.dataset.data.equipments = this.dataset.data.equipments.concat(equipments)
            });
        },

        removeEquipment(index) {
            this.dataset.data.equipments.splice(index, 1);
        },

        isClient() {
            const { client } = user.identify();
            return !!client;
        },

        handleModal(item) {
            if(this.equipmentStatus === 3 && this.equipmentModel === 4) {
                eventHub.$emit("VEHICLE-DETAIL-UNBLOCK-CHANGE", true);
            }

            if((this.equipmentStatus === 1 || this.equipmentStatus == 2 ) && this.equipmentModel === 5) {
                eventHub.$emit("VEHICLE-DETAIL-SLEEP-CHANGE", true);
            }


            if( this.equipmentStatus === 4 && this.equipmentModel === 5) {
                eventHub.$emit("VEHICLE-DETAIL-WAKEUP-CHANGE", true);
            }

            if(( this.equipmentStatus === 1 || this.equipmentStatus == 2 ) && this.equipmentModel === 4) {
                eventHub.$emit("VEHICLE-DETAIL-BLOCK-CHANGE", true);
            }

        },

        closeInfoWindow() {},

        setColorButton(type, status) {

            if(type === 5){
                switch (status) {
                    case 1:
                        return 'error';
                    case 2:
                        return 'error';
                    case 3:
                        return 'error';
                    case 5:
                        return 'primary';
                    case 6:
                        return 'error';
                    default:
                        return 'primary';
                }
            }

            if(type === 4) {
                switch (status) {
                    case 1:
                        return 'primary';
                    case 2:
                        return 'primary';
                    case 3:
                        return 'error';
                    case 7:
                        return 'primary';
                    case 8:
                        return 'error';
                    default:
                        return 'primary';
                }
            }

        },

        setInfoButton(type, status) {
            if(type === 5) {
                switch (status) {
                case 1:
                    return 'Dormir';
                case 2:
                    return 'Dormir';
                case 3:
                    return 'Dormir';
                case 5:
                    return 'Acordando...';
                case 6:
                    return 'Aguardando...';
                default:
                    return 'Acordar';
                }
            }

            if(type === 4) {
                    switch (status) {
                    case 1:
                        return 'Bloquear';
                    case 2:
                        return 'Bloquear';
                    case 3:
                        return 'Desbloquear';
                    case 8:
                        return 'Desbloqueando';
                    case 7:
                        return 'Bloqueando';
                    default:
                        return 'Bloquear';
                }
            }
        },
    },
};
</script>
  
<style lang="scss">
.theme--light.v-input input, .theme--light.v-input textarea, .v-select__selection--comma  {
    color: #222D6C !important;
    font-weight: 500 !important;
}
</style>